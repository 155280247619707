// open Fable.Core
// open BasicTypes
// open JSBoilerplate

import { makeObservable, observable } from "mobx";

// type AlertLevel = Info = 1 | Warning1 = 2 | Warning2 = 3
export enum AlertLevel { Info = 1, Warning1 = 2, Warning2 = 3};

// type AlertMessage = {
type AlertMessage = {
//     text:string
    text:string;
//     timestamp:int
    timestamp:number;
//     forceAcknowlege:bool
    forceAcknowlege:boolean;
//     level: AlertLevel
    level: AlertLevel;
// }
}

// let Alert = {
export const Alert = {
//     text = ""
    text:"",
//     timestamp = 0
    timestamp:0,
//     forceAcknowlege = false
    forceAcknowlege:false,
//     level = AlertLevel.Warning1
    level:AlertLevel.Warning1,
// }
}

// type IAlertMessages =
//     abstract add:AlertMessage -> unit
//     abstract currentMessages:AlertMessage []

// type AlertMessages0() =
export class AlertMessages {
//     let keepDuration:int = 4000 // TODO make configurable on new instance?
    keepDuration:number = 4000; // TODO make configurable on new instance?
//     (* @observable *)
//     let mutable currentMessages:AlertMessage [] = [||]
    @observable.ref currentMessages:AlertMessage [] = [];

    constructor() {
        makeObservable(this);
    }

//     let clearExpiredMessages() =
    clearExpiredMessages() {
//         let expiredTime = (int (JS.Date.now()) - keepDuration)
        const expiredTime = Date.now() - this.keepDuration;
//         currentMessages <- Array.filter (fun (m) -> m.timestamp > expiredTime) currentMessages
        this.currentMessages = this.currentMessages.filter((m) => m.timestamp > expiredTime);
    }

//     let add(message0:AlertMessage) =
    add(message0:AlertMessage) {
//         let mutable message = {message0 with timestamp = !< JS.Date.now()}
        let message = {...message0, timestamp:Date.now()};
//         if message.level = AlertLevel.Warning2 then
        if (message.level === AlertLevel.Warning2) {
//             message <- {message with forceAcknowlege = true}
            message = {...message, forceAcknowlege:true};
        }

//         currentMessages <- Array.append [|message|] currentMessages
        this.currentMessages = [...this.currentMessages, message];
//         JS.setTimeout clearExpiredMessages (keepDuration + 200)
        setTimeout(() => this.clearExpiredMessages(),this.keepDuration + 200);
    }
}

//     do autoBindInterface()

// let AlertMessages():IAlertMessages = !< AlertMessages0()
