
export async function getWaveformWithCache(audioURL) {
    const waveformCacheURL = audioURL + "/waveform";

    const cache = await caches.open("blort2-cache");
    let resp = await cache.match(waveformCacheURL);
    if (resp) {
      const buffer = await resp.arrayBuffer();
      return new Uint16Array(buffer);
    }
    resp = await fetch(audioURL);
    if (!resp.ok) {
      console.log("unable to load audio from url: " + audioURL);
      return;
    }
    const buffer = await resp.arrayBuffer();
    window.OfflineAudioContext =
      window.OfflineAudioContext || window.webkitOfflineAudioContext;
    const audioContext = new window.OfflineAudioContext(1, 16000, 16000);
    const audioBuffer = await audioContext.decodeAudioData(buffer);
    const waveformData = createWaveformData(audioBuffer, audioContext);
    const uint16WaveformData = Uint16Array.from(waveformData);
    const blob = new Blob([uint16WaveformData.buffer]);
    const synthResp = new Response(blob);
    await cache.put(waveformCacheURL, synthResp);
    return uint16WaveformData;
  }

  function createWaveformData(audioBuffer, audioContext) {
    const rawData = audioBuffer.getChannelData(0);
    const blockSize = audioContext.sampleRate / 100.0; // 10 ms
    let samples = Math.floor(rawData.length / blockSize);
    samples = Math.min(samples);
    const waveformData = [];
    for (let i = 0; i < samples; i++) {
      let blockStart = blockSize * i;
      let sum = 0;
      for (let j = 0; j < blockSize; j++) {
        sum = sum + Math.abs(rawData[blockStart + j]);
      }
      waveformData.push(Math.round((sum / blockSize) * 10 * 65535)); // TODO instead calculate a scaling factor
    }
    return waveformData;
  }
