import {NavigationTrackGadget} from "./navigation-gadget";

export class WordsTrackGadget extends NavigationTrackGadget {

  constructor() {
    super();
    this.words = [];
    this.font = '25px Arial';
    this.fontColor = '#000000';
  }

  setWordsIntervals(words, intervals) {
    this.words = words;
    this.handleIntervals = intervals;
    this.requestFullRedraw();
  }

  drawHandles(ctx) {
    ctx.save();
    ctx.font = this.font;
    ctx.textBaseline = 'middle';
    super.drawHandles(ctx);
    ctx.restore();
    this.drawGadgetSliceBounds(ctx)
  }

  drawRolloverHighlight(ctx) {
    ctx.save();
    ctx.font = this.font;
    ctx.textBaseline = 'middle';
    super.drawRolloverHighlight(ctx);
    ctx.restore();
  }

  drawHandle(ctx, index) {
    this.drawSimpleHandle(ctx, index);
    const saveFillStyle = ctx.fillStyle;
    ctx.fillStyle = this.fontColor;
    this.drawTextHandle(ctx, index, this.words[index], 5);
    this.drawHandleEdges(ctx, index, this.height);
    ctx.fillStyle = saveFillStyle;
  }
}
