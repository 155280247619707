import { makeObservable, observable, reaction } from "mobx";

const BOTH = "BOTH";
const TOP = "TOP";
const BOTTOM = "BOTTOM";
const DOWN = "DOWN";
const UP = "UP";
const CENTER = "CENTER";

export class TrackGadget {

  static BOTH = BOTH;
  static TOP = TOP;
  static BOTTOM = BOTTOM;
  static DOWN = DOWN;
  static UP = UP;
  static CENTER = CENTER;

  constructor() {
    this.notifyForegroundShouldRedraw = 0;
    this.notifyBackgroundShouldRedraw = 0;
    this.originY = 0;
    this.height = 0;
    this.midpointY = 0;
    this.bottomY = 0;
    this.doesRollover = false;
    this.detectPixelForRollover = false;

    this.timePixelScale = 1;
    this.canvasPixelWidth = 0;
    this.canvasStartTime = 0;
    this.canvasTimeExtent = 0;
    this.canvasEndTime = 0;

    this.disposers = [];

    makeObservable(this, {
      notifyForegroundShouldRedraw: observable,
      notifyBackgroundShouldRedraw: observable,
    });
  }

  yInside(y) {
    return y > this.originY && y < this.bottomY;
  }

  setLayout(originY, height) {
    this.originY = originY;
    this.height = height;
    this.midpointY = this.originY + this.height / 2;
    this.bottomY = this.originY + height;
  }

  setCanvasMapping(
    canvasStartTime,
    canvasTimeExtent,
    canvasPixelWidth,
    timePixelScale
  ) {
    this.canvasStartTime = canvasStartTime;
    this.canvasTimeExtent = canvasTimeExtent;
    this.canvasEndTime = canvasStartTime + canvasTimeExtent;
    this.canvasPixelWidth = canvasPixelWidth;
    this.timePixelScale = timePixelScale;
    this.requestFullRedraw();
  }

  drawBackgroundLayer(ctx) {
    throw new Error("non implemented abstract");
  }

  drawForegroundLayer(ctx) {
    throw new Error("non implemented abstract");
  }

  draw(ctx) {
    this.drawBackgroundLayer(ctx);
    this.drawForegroundLayer(ctx);
  }

  onBackgroundShouldRedraw(f) {
    this.disposers.push(reaction(() => this.notifyBackgroundShouldRedraw, f));
  }

  onForegroundShouldRedraw(f) {
    this.disposers.push(reaction(() => this.notifyForegroundShouldRedraw, f));
  }

  requestBackgroundRedraw() {
    this.notifyBackgroundShouldRedraw++;
  }

  requestForegroundRedraw() {
    this.notifyForegroundShouldRedraw++;
  }

  requestFullRedraw() {
    this.requestForegroundRedraw();
    this.requestBackgroundRedraw();
  }

  handleMouseClickAtTime(x, y, time, event) {}

  timeExtentToPixelCount(extent) {
    return extent * this.timePixelScale;
  }

  timeToCanvasPixelX(time) {
    return (time - this.canvasStartTime) * this.timePixelScale;
  }

  timeIsOnCanvas(time) {
    return time > this.canvasStartTime && time < this.canvasEndTime;
  }

  timeIntervalIsOnCanvas(starts, end) {
    return !(end < this.canvasStartTime || starts > this.canvasEndTime);
  }

  drawTimerangeEdges(ctx, timeInterval, pixelHeight) {
    ctx.beginPath();
    const x1 = this.timeToCanvasPixelX(timeInterval.starts);
    const x2 = this.timeToCanvasPixelX(timeInterval.ends);
    ctx.moveTo(x1, this.bottomY);
    ctx.lineTo(x1, this.bottomY - pixelHeight);
    ctx.moveTo(x2, this.bottomY);
    ctx.lineTo(x2, this.bottomY - pixelHeight);
    ctx.stroke();
  }

  drawTimerangeRect(ctx, timeInterval, pixelHeight) {
    ctx.beginPath();
    ctx.rect(
      this.timeToCanvasPixelX(timeInterval.starts),
      this.bottomY - pixelHeight,
      this.timeExtentToPixelCount(timeInterval.ends - timeInterval.starts),
      pixelHeight
    );
    ctx.fill();
  }

  // TODO factor out with above
  drawTimerangeRectOutline(ctx, timeInterval, pixelHeight) {
    ctx.beginPath();
    ctx.rect(
      this.timeToCanvasPixelX(timeInterval.starts),
      this.bottomY - pixelHeight,
      this.timeExtentToPixelCount(timeInterval.ends - timeInterval.starts),
      pixelHeight
    );
    ctx.stroke();
  }

  drawTimerangeDividingLine(ctx, timeInterval, ratio) {
    const y = this.originY + this.height * ratio;
    ctx.beginPath();
    ctx.moveTo(this.timeToCanvasPixelX(timeInterval.starts), y);
    ctx.lineTo(this.timeToCanvasPixelX(timeInterval.ends), y);
    ctx.stroke();
  }

  drawTextAtTime(ctx, time, text, leadingSpace, width = -1) {
    const x = this.timeToCanvasPixelX(time) + leadingSpace;
    if (width >= 0) {
      ctx.fillText(text, x, this.midpointY, width - leadingSpace);
    } else {
      ctx.fillText(text, x, this.midpointY);
    }
  }

  drawTextInTimeInterval(ctx, interval, text, alignment = CENTER) {
    const width = this.timeExtentToPixelCount(interval.ends - interval.starts);
    let x;
    ctx.save();
    if (alignment === CENTER) {
      ctx.textAlign = "center";
      x = this.timeToCanvasPixelX((interval.starts + interval.ends) / 2);
    } else {
      x = this.timeToCanvasPixelX(interval.starts) + 5; //TODO calc spacer value from DPI?
    }
    ctx.fillText(text, x, this.midpointY, width);
    ctx.restore();
  }

  drawLineAtTime(ctx, time, pixelHeight, direction = "UP") {
    const pixelX = this.timeToCanvasPixelX(time);
    if (direction === "UP") {
      ctx.moveTo(pixelX, this.bottomY);
      ctx.lineTo(pixelX, this.bottomY - pixelHeight);
    } else {
      ctx.moveTo(pixelX, this.originY);
      ctx.lineTo(pixelX, this.originY + pixelHeight);
    }
    ctx.stroke();
  }

  drawTriangleAtTime(ctx, time, pixelWidth, direction = DOWN) {
    const halfWidth = pixelWidth >> 1;
    const pixelCenter = this.timeToCanvasPixelX(time);
    if (direction === DOWN) {
      ctx.beginPath();
      ctx.moveTo(pixelCenter - halfWidth, this.originY);
      ctx.lineTo(pixelCenter, this.bottomY);
      ctx.lineTo(pixelCenter + halfWidth, this.originY);
      ctx.closePath();
      ctx.fill();
    } else {
      // TODO
    }
  }

  drawGadgetSliceBounds(ctx, pick = BOTH) {
    if (pick === BOTH || pick === TOP) {
      ctx.moveTo(0, this.originY);
      ctx.lineTo(this.canvasPixelWidth, this.originY);
    }
    if (pick === BOTH || pick === BOTTOM) {
      ctx.moveTo(0, this.bottomY);
      ctx.lineTo(this.canvasPixelWidth, this.bottomY);
    }
    ctx.stroke();
  }

  dispose() {
    for (const disposer of this.disposers) {
      disposer();
    }
    this.disposers = [];
  }
}
