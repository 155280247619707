// open Fable.Core
// open Fable.Core.JsInterop
// open AudioTransport
// open BasicTypes
// open ElementList
// open Sorted
// open TimelineNavigator
// open JSBoilerplate

import { makeObservable, observable } from "mobx";
import { ElementId, Element } from "../basic-types";
import { ElementList, EmptyElementList } from "../elements/element-list";
import { Navigation, NavigationPoint } from "../navigation/timeline-navigator";
import { Sorted } from "../sorted/sorted";
import { AudioTransport, TransportState } from "./audio-transport";

// type PlayerState = {
export class PlayerState {
//     mutable navigationPoint: NavigationPoint
    navigationPoint: NavigationPoint;
    constructor() {
        this.navigationPoint = null;
        makeObservable(this, {
            navigationPoint: observable.ref,
        });
    }
// }
}

// type IStructuredPlayer =
//     abstract setElements: IElementList -> unit
//     abstract getAudioPosition: unit -> int
//     abstract play: unit -> unit
//     abstract playForward: unit -> unit
//     abstract pause: bool option -> unit
//     abstract seek: int * ?keepPauseAfter:bool -> unit
//     abstract rewind: unit -> unit
//     abstract togglePlay: unit -> unit
//     abstract seekElement: ElementId -> unit
//     abstract playElement: ElementId -> unit
//     abstract playPeek: unit -> unit
//     abstract playPeekBack: unit -> unit
//     abstract nudge: int -> unit
//     abstract next: unit -> unit
//     abstract prev: unit -> unit
//     abstract nextClosest: unit -> unit
//     abstract prevClosest: unit -> unit
//     abstract playSection: unit -> unit
//     abstract seekToNavigationPoint: NavigationPoint -> unit
//     abstract setNavigationPoint: NavigationPoint -> unit
//     abstract navigate: string * int -> unit
//     abstract adjustPlaybackRateAction: bool -> unit
//     abstract setKerningPoints: ISorted -> unit
//     abstract setKerningDuration: int -> unit
//     abstract setKerning: ISorted * int -> unit
//     abstract kerningEnable: bool -> unit


// // TODO change adjustPlaybackRateAction param to enum?

// [<Emit("!!$0")>]
// let inline truthy v: bool = jsNative

// type StructuredPlayer0(playerState0, audioTransport0, transportState0, navigation0) =
export class StructuredPlayer {
//     let playerState: PlayerState = playerState0
    playerState: PlayerState;
//     let audioTransport: IAudioTransport = audioTransport0
    audioTransport: AudioTransport;
//     let transportState: TransportState = transportState0
    transportState: TransportState;
//     let nav: INavigation = navigation0
    nav: Navigation;
//     let mutable elements: IElementList = EmptyElementList
    elements: ElementList;
//     let mutable peekDuration = 1500
    peekDuration = 1500;
//     let playbackRateValues = [|0.7; 0.8; 0.9; 1.0|]
    playbackRateValues:number [];
//     let mutable playbackRateSelect = 3
    playbackRateSelect:number;


    constructor(playerState0, audioTransport0, transportState0, navigation0) {
//     let playerState: PlayerState = playerState0
        this.playerState = playerState0;
//     let audioTransport: IAudioTransport = audioTransport0
        this.audioTransport = audioTransport0;
//     let transportState: TransportState = transportState0
        this.transportState = transportState0;
//     let nav: INavigation = navigation0
        this.nav = navigation0;
//     let mutable elements: IElementList = EmptyElementList
        this.elements = EmptyElementList;
//     let mutable peekDuration = 1500
        this.peekDuration = 1500;
//     let playbackRateValues = [|0.7; 0.8; 0.9; 1.0|]
        this.playbackRateValues = [0.7, 0.8, 0.9, 1.0];
//     let mutable playbackRateSelect = 3
        this.playbackRateSelect = 3;
    }

//     let setElements(elements0) = elements <- elements0
    setElements(elements0) {
        this.elements = elements0;
    }

//     let getAudioPosition() = audioTransport.audioPosition
    getAudioPosition() {
        return this.audioTransport.audioPosition;
    }

//     let play() = audioTransport.play()
    play() {
        this.audioTransport.play();
    }

//     let playForward() = play()
    playForward() {
        this.play();
    }

//     let pause(keepPauseAfter) = audioTransport.pause keepPauseAfter
    pause(keepPauseAfter=false) {
        this.audioTransport.pause(keepPauseAfter);
    }

//     let seek(position, keepPauseAfter: bool) =
    seek(position:number, keepPauseAfter = false) {
//         audioTransport.seek(position)
        this.audioTransport.seek(position);
//         if not transportState.isPlaying && not keepPauseAfter then
        if (!this.transportState.isPlaying && !keepPauseAfter) {
//             transportState.audioRestartPosition <- position
            this.transportState.audioRestartPosition = position;
        }
    }

//     let rewind () =
    rewind () {
//         if transportState.isPlaying then
        if (this.transportState.isPlaying) {
//             pause(false)
            this.pause();
//         else
        } else {
//             audioTransport.rewind()
            this.audioTransport.rewind();
//             if not transportState.isPlaying then
            if (!this.transportState.isPlaying) {
//                 play()
                this.play();
            }
        }
    }

//     let pauseThenPlayAt(delay, position, keepPauseAfter) =
    pauseThenPlayAt(delay:number, position:number, keepPauseAfter = false) {
//         audioTransport.pause(keepPauseAfter)
        this.audioTransport.pause(keepPauseAfter);
//         seek(position, keepPauseAfter)
        this.seek(position, keepPauseAfter);
//         JS.setTimeout (fun () -> play()) delay
        setTimeout(() => this.play(), delay);
//         ()
    }

//     let togglePlay() =
    togglePlay() {
//         if transportState.isPlaying then
        if(this.transportState.isPlaying) {
//             pause(false)
            this.pause(false);
//         else
        } else {
//             playForward()
            this.playForward();
        }
    }

//     let seekElement elementId =
    seekElement(elementId:ElementId) {
//         seek(elements.time(elementId), false)
        this.seek(this.elements.time(elementId), false);
    }

//     let playElement(elementId) =
    playElement(elementId) {
//         seekElement(elementId)
        this.seekElement(elementId);
//         play()
        this.play();
    }

//     let playPeek() =
    playPeek() {
//         if transportState.isPlaying && transportState.audioRestartPosition <> 0 then
        if (this.transportState.isPlaying && this.transportState.audioRestartPosition !== 0) {
//             audioTransport.seek(transportState.audioRestartPosition)
            this.audioTransport.seek(this.transportState.audioRestartPosition);
//         else
        } else {
//             audioTransport.setPauseAfter(getAudioPosition() + peekDuration)
            this.audioTransport.setPauseAfter(this.getAudioPosition() + this.peekDuration);
//             transportState.audioRestartPosition <- getAudioPosition()
            this.transportState.audioRestartPosition = this.getAudioPosition();
//             play()
            this.play();
        }
    }

//     let playPeekBack() =
    playPeekBack() {
//         if transportState.isPlaying && transportState.audioRestartPosition <> 0 then
        if (this.transportState.isPlaying && this.transportState.audioRestartPosition !== 0) {
//             let playFrom = transportState.audioRestartPosition - peekDuration
            const playFrom = this.transportState.audioRestartPosition - this.peekDuration;
//             pauseThenPlayAt(100, playFrom, true)
            this.pauseThenPlayAt(100, playFrom, true);
        }
//         elif transportState.audioRestartPosition <> 0 then
        else if (this.transportState.audioRestartPosition !== 0) {
//             audioTransport.setPauseAfter transportState.audioRestartPosition
            this.audioTransport.setPauseAfter(this.transportState.audioRestartPosition);
//             seek(transportState.audioRestartPosition - peekDuration, true)
            this.seek(this.transportState.audioRestartPosition - this.peekDuration, true);
//             play()
            this.play();
        }
    }

//     let nudge(ms) =
    nudge(ms:number) {
//         if not transportState.isPlaying then
        if (!this.transportState.isPlaying) {

//             seek(transportState.audioPosition + ms, false)
            this.seek(this.transportState.audioPosition + ms, false);
        }
//         elif transportState.audioRestartPosition <> 0 then
        else if (this.transportState.audioRestartPosition !== 0) {
//             let nudgeTo = transportState.audioRestartPosition + ms
            const nudgeTo = this.transportState.audioRestartPosition + ms;
//             transportState.audioRestartPosition <- nudgeTo
            this.transportState.audioRestartPosition = nudgeTo;
//             pauseThenPlayAt(100, nudgeTo, false)
            this.pauseThenPlayAt(100, nudgeTo, false);
//             audioTransport.setPauseAfter(nudgeTo + peekDuration)
            this.audioTransport.setPauseAfter(nudgeTo + this.peekDuration);
        }
    }

    // TODO expose move method on navigators and eliminate duplicate code?
//     let next() =
    next() {
//         let currentPoint = playerState.navigationPoint
        const currentPoint = this.playerState.navigationPoint;
//         let nextPoint = nav.next(currentPoint)
        const nextPoint = this.nav.next(currentPoint);
//         if nextPoint <> currentPoint then
        if (nextPoint !== currentPoint) {
//             seek(nextPoint.position, false)
            this.seek(nextPoint.position);
//             playerState.navigationPoint <- nextPoint
            this.playerState.navigationPoint = nextPoint;
        }
    }

//     let prev() =
    prev() {
//         let currentPoint = playerState.navigationPoint
        const currentPoint = this.playerState.navigationPoint;
//         let nextPoint = nav.prev(currentPoint)
        const nextPoint = this.nav.prev(currentPoint);
//         if nextPoint <> currentPoint then
        if (nextPoint !== currentPoint) {
//             seek(nextPoint.position, false)
            this.seek(nextPoint.position);
//             playerState.navigationPoint <- nextPoint
            this.playerState.navigationPoint = nextPoint;
        }
    }

//     let nextClosest() =
    nextClosest() {
//         let point = playerState.navigationPoint
        const point = this.playerState.navigationPoint;
//         if !!point then
        if (point) {
//             let nextPoint = nav.nextClosest(point, getAudioPosition())
            const nextPoint = this.nav.nextClosest(point, this.getAudioPosition());
//             if !!nextPoint then
            if (nextPoint) {
//                 playerState.navigationPoint <- nextPoint
                this.playerState.navigationPoint = nextPoint;
//                 if transportState.isPlaying then
                if (this.transportState.isPlaying) {
//                     pauseThenPlayAt(600, nextPoint.position, false)
                    this.pauseThenPlayAt(600, nextPoint.position);
//                 else
                } else {
//                     seek(nextPoint.position, false)
                    this.seek(nextPoint.position);
                }
            }
        }
    }

//     let prevClosest() =
    prevClosest() {
//         let point = playerState.navigationPoint
        const point = this.playerState.navigationPoint;
//         if !!point then
        if (point) {
//             let tolerance = if transportState.isPlaying then 300 else 10
            const tolerance = (this.transportState.isPlaying) ? 300:10;
//             let nextPoint = nav.prevClosest(point, getAudioPosition () - tolerance)
            const nextPoint = this.nav.prevClosest(point, this.getAudioPosition () - tolerance);
            // TODO factor out this code also in nextClosest??
//             if !!nextPoint then
            if (nextPoint) {
//                 playerState.navigationPoint <- nextPoint
                this.playerState.navigationPoint = nextPoint;
//                 if transportState.isPlaying then
                if (this.transportState.isPlaying) {
//                     pauseThenPlayAt(600, nextPoint.position, false)
                    this.pauseThenPlayAt(600, nextPoint.position);
//                 else
                } else {
//                     seek(nextPoint.position, false)
                    this.seek(nextPoint.position);
                }
            }
        }
    }

//     let playSection() =
    playSection()  {
//         let point = playerState.navigationPoint
        const point = this.playerState.navigationPoint;
//         if !!point then
        if (point) {
//             let nextPoint = nav.nextClosest(point, getAudioPosition() + 50)
            const nextPoint = this.nav.nextClosest(point, this.getAudioPosition() + 50);
//             if !!nextPoint then
            if (nextPoint) {
//                 audioTransport.setPauseAfter(nextPoint.position)
                this.audioTransport.setPauseAfter(nextPoint.position);
//                 audioTransport.clearAudioRestartPosition()
                this.audioTransport.clearAudioRestartPosition();
//                 play()
                this.play();
            }
        }
    }

//     let seekToNavigationPoint(point) =
    seekToNavigationPoint(point:NavigationPoint) {
//         if !!point then
        if (point) {
//             seek(point.position, false)
            this.seek(point.position);
        }
    }

//     let setNavigationPoint(point)=
    setNavigationPoint(point:NavigationPoint) {
//         playerState.navigationPoint <- point
        this.playerState.navigationPoint = point;
//         seekToNavigationPoint point
        this.seekToNavigationPoint(point);
    }

//     let navigate(key, index) =
    navigate(key:string, index:number) {
//         let navigator = nav.getNavigatorForKey(key)
        const navigator = this.nav.getNavigatorForKey(key);
//         let point = navigator.navigationPoint(index)
        const point = navigator.navigationPoint(index);
//         setNavigationPoint(point)
        this.setNavigationPoint(point);
    }

//     let adjustPlaybackRateAction(direction) =
    adjustPlaybackRateAction(direction:boolean) {
//         let select = if direction then playbackRateSelect + 1 else playbackRateSelect - 1
        const select = (direction) ?this.playbackRateSelect + 1 :this.playbackRateSelect - 1;
//         if select >= 0 && select < playbackRateValues.Length then
        if (select >= 0 && select < this.playbackRateValues.length) {
//             playbackRateSelect <- select
            this.playbackRateSelect = select;
//             audioTransport.setPlaybackRate(playbackRateValues.[playbackRateSelect])
            this.audioTransport.setPlaybackRate(this.playbackRateValues[this.playbackRateSelect]);
        }
    }

//     let setKerningPoints(points) = audioTransport.setKerningPoints(points)
    setKerningPoints(points:Sorted) {
        this.audioTransport.setKerningPoints(points);
    }

//     let setKerningDuration(duration) = audioTransport.setKerningDuration(duration)
    setKerningDuration(duration:number) {
        this.audioTransport.setKerningDuration(duration);
    }

//     let setKerning(points, duration) = audioTransport.setKerning( points, duration)
    setKerning(points:Sorted, duration:number) {
        this.audioTransport.setKerning( points, duration);
    }

//     let kerningEnable(enable) = audioTransport.kerningEnable(enable)
    kerningEnable(enable:boolean) {
        this.audioTransport.kerningEnable(enable);
    }

}

//     do autoBindInterface()

// let StructuredPlayer(playerState, audioTransport, transportState, navigation):IStructuredPlayer =
//     !< StructuredPlayer0(playerState, audioTransport, transportState, navigation)
