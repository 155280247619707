import {MultiAudioSource} from "./masala-lib/player/multi-audio-source.js";

const audioSourceKeySortOrder = ["transcribeAudioUrl", "audioUrl"];
const DEFAULT_SOURCE_KEY = "transcribeAudioUrl";
export const audioSourceKeyToUIText = {
    transcribeAudioUrl: "Chaat Audio",
    audioUrl: "Final Audio"
};

export function createAudioSource() {
    return new MultiAudioSource(audioSourceKeySortOrder, DEFAULT_SOURCE_KEY);
}
