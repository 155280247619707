// open Fable.Core
// open Fable.Core.JsInterop
// open BasicTypes
// open ElementList
// open ContentFuncs
// open MutationActions
// open AlertMessages
// open System
// open JSBoilerplate
// open SweetAlert

import { Alert, AlertMessages } from "../../../alert-messages";
import { Cue, ElementId, isNil, notNil } from "../../../basic-types";
import { getContentStringFromWordIdMaxChars } from "../../../content-funcs";
import { ElementList } from "../../../elements/element-list";
import { swalPromptYN } from "../../../sweetalert-yn";
import { MutationActions } from "../../db/mutation-actions";


// type ICueActions =
//     // TODO put InputCue in these interfaces?
//     abstract addCue: unit -> unit
//     abstract addShiftCue: unit -> unit
//     abstract addShiftEndCue: unit -> unit
//     abstract removeCueAtCurrent: unit -> unit

// [<AbstractClass>]
// type CueActions0() as s0 =
export abstract class CueActions {

//     let self = s0

//     let mutable lastCue = {wordId=Null; timestamp=0; input=false; navStop=false}
    lastCue = {wordId:null, timestamp:0, input:false, navStop:false};

//     let audioPositionWordId = 0 // TODO compute from audio pos and words entity list?
    audioPositionWordId = 0; // TODO compute from audio pos and words entity list?

//     let addCue():unit =
    async addCue() {
        // TODO properly deal with promise
//         let insertWordId = self.cueInsertWordId
        const insertWordId = this.cueInsertWordId;
//         if isNil(insertWordId) then
        if (isNil(insertWordId)) {
//             self.alertMessages.add({Alert with text = "Error:Trying to add cue without setting cue insertion position"})
            this.alertMessages.add({...Alert, text:"Error:Trying to add cue without setting cue insertion position"});
//             Imperative.RETURN_NONE
            return;
        }

//         // let existing:ChaatCue = !< Array.tryFind (fun (cue) -> cue.wordId = insertWordId ) self.existingCues
//         // let existingTimestamp = if !!existing then existing.timestamp else Null
//         let audioPos = self.currentAudioPosition
        const audioPos = this.currentAudioPosition;

//         let mutable problematic = false
        let problematic = false;
//         if insertWordId = lastCue.wordId && Math.Abs(audioPos - lastCue.timestamp) > 1000 then
        if (insertWordId === this.lastCue.wordId && Math.abs(audioPos - this.lastCue.timestamp) > 1000) {
//             problematic <- true
            problematic = true;
        }
//         if Math.Abs(audioPos - self.words.time(insertWordId)) > 1500 then
        if (Math.abs(audioPos - this.words.time(insertWordId)) > 1500) {
//             problematic <- true
            problematic = true;
        }

//         let p = promise {
//             let! goahead =
//                 if problematic then
//                     swalPromptYN(!< {|
//                         title = "Mistake?"
//                         html =
//                             "Current audio position seems far from cue words<br> Cue at: " +
//                             "<span style='color:red;'>" +
//                             getContentStringFromWordIdMaxChars(insertWordId, self.words, 40) +
//                             "</span>"
//                         icon = "warning"
//                         showCancelButton = true
//                         confirmButtonColor = "#3085d6"
//                         cancelButtonColor = "#d33"
//                         confirmButtonText = "Add cue"
//                     |})
//                 else
//                     // TODO investigate if can leave out this conditional and let! = (same as await) inside if branch above
//                     promise {return true}
        let goahead = true;

        if (problematic) {
//                     swalPromptYN(!< {|
            goahead = await swalPromptYN( {
//                         title = "Mistake?"
                title:"Mistake?",
//                         html =
                html:
//                             "Current audio position seems far from cue words<br> Cue at: " +
                    "Current audio position seems far from cue words<br> Cue at: " +
//                             "<span style='color:red;'>" +
                    "<span style='color:red;'>" +
//                             getContentStringFromWordIdMaxChars(insertWordId, self.words, 40) +
                    getContentStringFromWordIdMaxChars(insertWordId, this.words, 40) +
//                             "</span>"
                    "</span>",
//                         icon = "warning"
                icon:"warning",
//                         showCancelButton = true
                showCancelButton:true,
//                         confirmButtonColor = "#3085d6"
                confirmButtonColor:"#3085d6",
//                         cancelButtonColor = "#d33"
                cancelButtonColor:"#d33",
//                         confirmButtonText = "Add cue"
                confirmButtonText:"Add cue",
//                     |})
            });
        }


//             if (not problematic) || goahead then
        if (goahead) {
//                 lastCue <- {wordId=insertWordId; timestamp=audioPos; input=true; navStop=false}
            this.lastCue = {wordId:insertWordId, timestamp:audioPos, input:true, navStop:false};
//                 self.mutationActions.addUpdateCue(lastCue)
            this.mutationActions.addUpdateCue(this.lastCue);
        }
//             return ()
//         }
//         ()
    }



//     let currentTimeWordId(): ElementId =
    currentTimeWordId(): ElementId {
//         let currentWord = self.words.getElementContainingTime(self.currentAudioPosition)
        const currentWord = this.words.getElementContainingTime(this.currentAudioPosition);
//         if !!currentWord then currentWord.id else Null
        return currentWord?.id ?? null;
    }

//     let addShiftCue() =
    addShiftCue() {
//         self.cueInsertWordId <- currentTimeWordId()
        this.cueInsertWordId = this.currentTimeWordId();
//         addCue()
        this.addCue();
    }

//     let addShiftEndCue() =
    addShiftEndCue() {
//         let currentWordId = currentTimeWordId()
        const currentWordId = this.currentTimeWordId();
//         if notNil(currentWordId) then
        if (notNil(currentWordId)) {
//             self.cueInsertWordId <- self.words.nextId(currentWordId)
            this.cueInsertWordId = this.words.nextId(currentWordId);
//             addCue()
            this.addCue();
        }
    }

//     let removeCueAtCurrent() =
    removeCueAtCurrent() {
//         let insertWordId = self.cueInsertWordId
        const insertWordId = this.cueInsertWordId;
//         if isNil(insertWordId) then
        if (isNil(insertWordId)) {
//             self.alertMessages.add({Alert with text = "Error:Trying to remove cue without setting cue insertion position"})
            this.alertMessages.add({...Alert, text:"Error:Trying to remove cue without setting cue insertion position"});
//             Imperative.RETURN_NONE
            return;
        }
//         let existing:Cue = !< Array.tryFind (fun (cue) -> cue.wordId = insertWordId ) self.existingCues
        const existing:Cue = this.existingCues.find((cue) => cue.wordId = insertWordId);
//         if !!existing then
        if (existing) {
//             lastCue <- existing
            this.lastCue = existing;
//             self.mutationActions.removeCue(existing.wordId, true)
            this.mutationActions.removeCue(existing.wordId, true);
        }
    }


//     do autoBindInterface()

//     abstract mutationActions:IMutationActions with get
    abstract get mutationActions():MutationActions;
//     abstract alertMessages: IAlertMessages with get
    abstract get alertMessages(): AlertMessages;
//     abstract cueInsertWordId: ElementId with get, set
    abstract get cueInsertWordId(): ElementId;
    abstract set cueInsertWordId(value:ElementId);
//     abstract currentAudioPosition: int with get
    abstract get currentAudioPosition(): number;
//     abstract words: IElementList with get
    abstract get words(): ElementList;
//     abstract existingCues: Cue [] with get // TODO change to map from wordId???
    abstract get existingCues(): Cue []; // TODO change to map from wordId???

}
