import ASCIIFolder from "fold-to-ascii";
import { Element, ElementId, IdRange, IndexRange, NO_INDEX, SpanAnchors, SpanExclusiveAnchors } from "./basic-types";
import { EKinds } from "./elements/element-kinds";
import { ElementList } from "./elements/element-list";
// open Fable.Core
// open Fable.Core.JsInterop
// open Fable.Core.DynamicExtensions
// open BasicTypes
// open ElementList
// open ElementTypes

// // TODO a good many of these functions work on the editorial models using wordId anchors but could have corresponding impls using word indexes

// [<ImportMember("./content-funcs.js")>]
// let getTranscriptWordsFromString(str0:string): string [] = jsNative

// [<ImportMember("./content-funcs.js")>]
// let strongNormalizeWordArray(words: string []): string [] = jsNative

// [<ImportMember("./content-funcs.js")>]
// let strongNormalizeWord(word: string): string = jsNative


// let wordIndexRangeToWordStrings(indexRange:IndexRange, words:IElementList) =
export function wordIndexRangeToWordStrings(indexRange:IndexRange, words:ElementList) {
//     let wordElements = words.elements
    // TODO strong typing for this instead?
    const wordElements = words.elements;
//     [| for i in indexRange.starts..indexRange.ends -> wordElements.[i]?text|]
    const result = [];
    // TODO create better way to iterate or map number range?
    for (let i = indexRange.starts; i <= indexRange.ends; i++) {
        result.push(wordElements[i]["text"]);
    }
    return result;
}

// let wordIdRangeToWordStrings(idRange:IdRange, words:IElementList): string [] =
export function wordIdRangeToWordStrings(idRange:IdRange, words:ElementList): string [] {
//     let wordsSeq = words.idRangeAsElementSeq(idRange)
    // TODO stronger typing?
    const wordsRange = words.idRangeAsElements(idRange);
//     [| for word in wordsSeq -> word?text |]
    return wordsRange.map((word) => word["text"]);
}

// let wordStringsToWordElements(words: string [], startIndex:int, indexToId:obj):IElement [] =
export function wordStringsToWordElements(words: string [], startIndex:number, indexToId:any):Element [] {
//     let wordElements = [||]
    const wordElements = [];
//     for index, word in Array.indexed words do
    for (const [index, word] of words.entries()) {
//         wordElements.append({|kind=WORD; id=indexToId.[!< (startIndex + index)]; text=word|})
        wordElements.push({kind:EKinds.WORD, id:indexToId[startIndex + index], text:word});
    }
//     wordElements
    return wordElements;
}

// let getContentStringFromWordIdMaxChars(wordId:ElementId, words:IElementList, maxChars:int) =
export function getContentStringFromWordIdMaxChars(wordId:ElementId, words:ElementList, maxChars:number) {
//     let index = words.getIndex(wordId)
    const index = words.getIndex(wordId);
//     if index = -1 then
    if (index === NO_INDEX) {
//         Imperative.RETURN_VALUE ""
        return "";
    }

//     let resultWords = [||]
    const resultWords = [];
//     let mutable charCount = 0
    let charCount = 0;
//     let mutable i = index
    let i = index;
//     let wordElements = words.elements
    const wordElements = words.elements;
//     while charCount < maxChars && i < wordElements.Length do
    while (charCount < maxChars && i < wordElements.length) {
//         let word = wordElements.[i]
        const word = wordElements[i];
//         let text:string = word?text
        // TODO strong typing?
        const text:string = word["text"];
//         resultWords.append(text)
        resultWords.push(text);
//         charCount <- charCount + text.Length
        charCount += text.length;
//         i <- i + 1
        i++;
    }

//     String.concat " " resultWords
    return resultWords.join(" ");
}

// let getSentenceWordIdRange(sentence:IElement, words:IElementList) =
export function getSentenceWordIdRange(sentence:Element, words:ElementList) {
    // TODO make generic handle both inclusive and exclusive with sniff of anchor properties??
//     let startWordId = sentence.anchors?startWordId
    const anchors = <SpanExclusiveAnchors>sentence.anchors;
    const startWordId = anchors.startWordId;
//     let endWordId = words.prevId(sentence.anchors?endWordIdExclusive)
    const endWordId = words.prevId(anchors.endWordIdExclusive);
//     {starts=startWordId; ends=endWordId}
    return {starts:startWordId, ends:endWordId};
}

// let getSentenceWordStrings(sentence:IElement, words:IElementList): string [] =
export function getSentenceWordStrings(sentence:Element, words:ElementList): string [] {
    const wordRange = getSentenceWordIdRange(sentence, words);
//     wordIdRangeToWordStrings(wordRange, words)
    return wordIdRangeToWordStrings(wordRange, words);
}

// let getSentencesWordStrings(sentences:IElementList): string [] =
export function getSentencesWordStrings(sentences:ElementList): string [] {
//     let result = [||]
    const result = [];
//     for sentence in sentences.elements do
    for( const sentence of sentences.elements) {
//         result.extend(getSentenceWordStrings(sentence, sentences.words))
        result.push(...getSentenceWordStrings(sentence, sentences.words));
    }
//     result
    return result;
}

// let getElementEditableContentString(element:IElement, words:IElementList): string =
export function getElementEditableContentString(element:Element, words:ElementList): string {
    // TODO use for word groups also to get transcript content string?
//     if element.kind = SENTENCE then
    if (element.kind === EKinds.SENTENCE) {
//         getSentenceWordStrings(element, words) |> String.concat " "
        return getSentenceWordStrings(element, words).join(" ");
//     elif element.kind = WORD_GROUP then
    }
    else if (element.kind === EKinds.WORD_GROUP) {
//         failwith "word groups do not have editable content"
        throw new Error("word groups do not have editable content");
//     else
    } else {
//         !< element.content
        return element.content;
    }
}

// let getWordGroupTranscriptText(group:IElement, words:IElementList): string =
export function getWordGroupTranscriptText(group:Element, words0:ElementList): string {
    // TODO need to back off for deletes on endWordId same as in content roots logic
//     let wordRange = {starts=group.anchors?startWordId; ends=group.anchors?endWordId}
    const anchors = <SpanAnchors>group.anchors;
    const wordRange = {starts:anchors.startWordId, ends:anchors.endWordId};
//     let words = wordIdRangeToWordStrings(wordRange, words)
    const words = wordIdRangeToWordStrings(wordRange, words0);
//     String.concat " " words
    return words.join(" ");
}

// let wordGroupKindSymbols:obj = !< {|
const wordGroupKindSymbols = {
//     VOCAB = ""
    VOCAB: "",
//     TRICKY = "~"
    TRICKY:"~",
//     SIC = "$"
    SIC:"$",
// |}
}

// let getWordGroupJWScriptRepresentation(group:IElement, words:IElementList) =
export function getWordGroupJWScriptRepresentation(group:Element, words:ElementList) {
//     let mutable result = "<"
    let result = "<";
//     let note:string = if !!group.content?note then !< group.content?note else ""
    const note:string = group.content["note"] ?? "";
//     let delimiter = if note.Contains('=') then "|" else "="
    const delimiter = note.includes('=') ? "|" : "=";
//     result <- result + !< wordGroupKindSymbols.[group.subKind]
    result += wordGroupKindSymbols[group.subKind];
//     result <- result + getWordGroupTranscriptText(group, words)
    result += getWordGroupTranscriptText(group, words);
//     if !!note then
    if (note) {
//         result <- result + delimiter + note
        result += delimiter + note;
    }
//     result <- result + ">"
    result += ">";
//     result
    return result;
}

// let getElementVersionDescriptiveContent(element:IElement, words:IElementList):string =
export function getElementVersionDescriptiveContent(element:Element, words:ElementList):string {
//     let kind = element.kind
    const kind = element.kind;
//     if kind = WORD_GROUP then
    if (kind === EKinds.WORD_GROUP) {
//         getWordGroupJWScriptRepresentation(element, words)
        return getWordGroupJWScriptRepresentation(element, words);
//     elif kind = SENTENCE then
    }
    else if (kind === EKinds.SENTENCE) {
//         let sentenceWords:obj [] = element?words
        const sentenceWords:any [] = element["words"];
//         String.concat " " [| for word in sentenceWords -> word?text|]
        return sentenceWords.map((word) => word.text).join(" ");
//     else
    } else {
//         !< element.content
        return element.content;
    }
}


const punctuationRegex = /[!"#$%&'()*+,\-./:;<=>?@[\]^_`{|}~¡¿—–]/g;
const standaloneEmDashRegex = /\s+--\s+/g;
const joinedEmDashRegex = /---/g;
// TODO make complete equivalence of punctuation here with punctuation in jw_script_processor.py
const standalonePunctuationRegex = /\s+([!"'()+,\-./:;<=>?[\]^_`{|}~¡¿—–]+)\s+/g;
const trailingStandalonePunctuationRegex = /\s+([!"'()+,\-./:;<=>?[\]^_`{|}~¡¿—–]+)\s+$/g;
const trailingPunctuationRegex = /([!"'()+,\-./:;<=>?[\]^_`{|}~¡¿—–]+)\s*$/g;
const leadingPunctuationRegex = /^([!"'()+,\-./:;<=>?[\]^_`{|}~¡¿—–]+)\s*/g;

const whitespaceRegex = /\s+/g;
const startingWhitespaceRegex = /^\s+/g;
const trailingWhitespaceRegex = /\s+$/g;


export function joinStandaloneEmDashes(str:string) {
  if (!str) {
    return str;
  }
  // TODO with replacing with space hyphenated words may count as two words, what is correct?
  return str.replace(standaloneEmDashRegex, '--- ');
}

export function restoreEmDashes(str:string) {
  if (!str) {
    return str;
  }
  return str.replace(joinedEmDashRegex, ' --');
}

export function joinStandalonePunctuation(str:string) {
  if (!str) {
    return str;
  }
  str = str.replace(standalonePunctuationRegex, '$1 ');
  // TODO not sure will handle linefeed correctly, look JS regex doc and test
  return str.replace(trailingStandalonePunctuationRegex, '$1');
  // TODO need to handle standalone quotes " ' differently open should join with following and closing with preceeding
  // TODO or should just have alarm and message when trying save edit with standalone quotes to simplify implementation?
}

export function normalizeWhiteSpace(str:string) {
  if (!str) {
    return str;
  }
  str = str.replace(whitespaceRegex,' ');
  if (str === ' ') {
    str = '';
  }
  str = str.replace(startingWhitespaceRegex,'');
  return str.replace(trailingWhitespaceRegex,'');
}

export function normalizePunctuation(str:string) {
  str = joinStandaloneEmDashes(str);
  return joinStandalonePunctuation(str);
}

export function normalizeTranscriptText(str:string) {
  str = normalizePunctuation(str);
  return normalizeWhiteSpace(str);
}

export function validateTranscriptText(str:string) {
  // TODO check for standalone quotes or other things not dealt with by automatic normalization
}

function getWords(str:string) {
  return str.split(' ');
}

export function getTranscriptWordsFromString(str:string) {
  if (!str) {
    return [];
  }
  str = normalizeTranscriptText(str);
  if (!str) {
    return [];
  }
  const words = getWords(str);
  return words.map(word => restoreEmDashes(word));
}

export function stripTrailingPunctuation(str:string) {
  if (!str) {
    return str;
  }
  return str.replace(trailingPunctuationRegex,'');
}

export function stripLeadingPunctuation(str:string) {
  if (!str) {
    return str;
  }
  return str.replace(leadingPunctuationRegex,'');
}

export function trimPunctuation(str:string) {
  str = stripTrailingPunctuation(str);
  return stripLeadingPunctuation(str);
}

export function strongNormalizeWord(word:string) {
  word = word.replace(punctuationRegex, '');
  word = ASCIIFolder.foldReplacing(word);
  return word.toLowerCase();
}

export function strongNormalizeWordArray(words:string []) {
  return words.map((word) => strongNormalizeWord(word));
}
