import React from "react";
import Root from "./containers/root";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { DefaultRoute } from './screens/default-route';

export const Routes = () => (
  <Router>
      <Route path="/" exact component={DefaultRoute} />
      <Route path="/:episodeKey" component={Root} />
  </Router>
);
