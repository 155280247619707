
export function jsArraySlice(a, slice) {
  if (!slice)  {
    return a.slice(); // TODO maybe just return original array, not problem for timestamper because are immutable in practice
  }
  if (typeof slice[1] === 'number') {
    return a.slice(slice[0],slice[1] + 1);
  }
  return a.slice(slice[0]);
}
