
import {searchSorted} from './search-sorted';

// TODO move to separate module for Interval stuff
export type Interval = {
    starts: number,
    ends: number,
}

// TODO move NO_INDEX to some generic constants module?
export const NO_INDEX = -1;

export function size(interval:Interval) {
    return (interval.ends - interval.starts) + 1;
}

export function midPoint(interval:Interval) {
    return (interval.starts + interval.ends) >> 1;
}

export function isPoint(interval:Interval) {
    return interval.ends === NO_INDEX;
}

export function intervalsToStartEndPoints(intervals: Interval[]) {
    const startPoints = intervals.map( interval => interval.starts)
    const endPoints = intervals.map( interval => interval.ends)
    return { startPoints, endPoints};
}

export class Sorted {
    startPoints: number[];
    endPoints: number[];
    domainStart: number;
    domainEnd: number;
    _midPoints: number[] = null;

    constructor(startPoints:number[]= [], endPoints:number[] =[], domainStart=0, domainEnd=0) {
        this.startPoints = startPoints;
        this.endPoints = endPoints;
        this.domainStart = domainStart;
        this.domainEnd = domainEnd;
    }

    checkValidIndex(idx:number) {
        return idx >= 0 && idx < this.startPoints.length;
    }

    getValidIdx(idx:number) {
        if (this.checkValidIndex(idx)) {
            return idx;
        } else {
            return NO_INDEX;
        }
    }

    getValidInterval(startIdx:number, endIdx:number): Interval | null {
        if (startIdx < 0 || endIdx < 0) {
            return null;
        }
        else if (startIdx > endIdx) {
            return null;
        } else {
            return { starts:startIdx, ends:endIdx};
        }
    }

    get length() {
        return this.startPoints.length;
    }

    intervalAt(idx:number): Interval {
        if (this.endPoints) {
            return {starts:this.startPoints[idx], ends:this.endPoints[idx]};
        } else {
            return { starts:this.startPoints[idx], ends:NO_INDEX}
        }
    }

    pointAt(idx:number) {
        // TODO force to a point style interval?
        return this.intervalAt(idx);
    }

    asIntervals() {
        // seq { for i in 0 .. (startPoints.Length - 1) -> intervalAt(i) }
        return this.startPoints.map( (_point, index) => this.intervalAt(index) )
    }

    get midPoints() {
        if (this._midPoints) {
            return this._midPoints;
        } else {
            const intervals = this.asIntervals();
            this._midPoints = intervals.map(midPoint);
            return this._midPoints;
        }
    }

//     let fromIntervals0(intervals: Interval []) =
//         let points = intervalsToStartEndPoints(intervals)
//         !< Sorted0({startPoints=points.startPoints; endPoints=points.endPoints; domainStart=0; domainEnd=0})

//     let fromStartPoints(sorted):ISorted =
    fromStartPoints() {
//         !< Sorted0({SortedOpts with startPoints = startPoints; domainStart = domainStart; domainEnd = domainEnd})
        return new Sorted(this.startPoints, null, this.domainStart, this.domainEnd);
    }

//     let fromEndPoints(sorted):ISorted =
    fromEndPoints() {
//         !< Sorted0({SortedOpts with startPoints = endPoints; domainStart = domainStart; domainEnd = domainEnd})
        return new Sorted(this.endPoints, null, this.domainStart, this.domainEnd)
    }

//     let fromMidPoints(sorted):ISorted =
    fromMidPoints() {
//         !< Sorted0({SortedOpts with startPoints = midPoints(); domainStart = domainStart; domainEnd = domainEnd})
        return new Sorted(this.midPoints, null, this.domainStart, this.domainEnd);
    }

    lastStartsBeforeOrAt(value:number) {
        const idx = searchSorted(this.startPoints, value);
        // idx will be the index of the first interval with start value after input value
        // change to preceding interval which must have start value less than or equal input value
        return this.getValidIdx(idx - 1);
    }

    lastBeforeOrAt(value) {
        return this.lastStartsBeforeOrAt(value);
    }

    doesStartBeforeOrAt(idx, value) {
        return this.startPoints[idx] <= value;
    }

    firstStartsAfterOrAt(value) {
        const idx = searchSorted(this.startPoints, value, false);
        return this.getValidIdx(idx);
    }

    firstStartsAfter(value) {
        const idx = searchSorted(this.startPoints, value);
        return this.getValidIdx(idx);
    }

    firstAfter(value) {
        return this.firstStartsAfter(value);
    }

    lastEndsBeforeOrAt(value) {
        const idx = searchSorted(this.endPoints, value);
        // idx will be the index of the first interval with end value after input value
        // change to preceding interval which must have end value less than or equal input value
        return this.getValidIdx(idx - 1);
    }

    firstEndsAfter(value) {
        const idx = searchSorted(this.endPoints, value);
        // idx will be the index of the first interval with end value after input value
        return this.getValidIdx(idx);
    }

    firstEndsAfterOrAt(value) {
        const idx = searchSorted(this.endPoints, value, false)
        return this.getValidIdx(idx);
    }

    startsAt(value) {
        const idx = this.lastStartsBeforeOrAt(value);
        if (idx !== NO_INDEX && this.startPoints[idx] === value) {
            return idx;
        } else {
            return NO_INDEX;
        }
    }

    endsAt(value) {
        const idx = this.lastEndsBeforeOrAt(value);
        if (idx !== NO_INDEX && this.endPoints[idx] === value) {
            return idx;
        } else {
            return NO_INDEX;
        }
    }

    containing(value) {
        const idx = this.lastStartsBeforeOrAt(value);
        if (!this.endPoints) {
            return idx;
        } else
        if ( idx >= 0 && value <= this.endPoints[idx]) {
            return idx;
        } else {
            return NO_INDEX;
        }
    }

    doesContain(idx, value) {
        if (idx < 0) {
            return false;
        } else {
            // TODO for points mode
            return this.startPoints[idx] <= value && this.endPoints[idx] >= value;
        }
    }

    rangeContained(startValue, endValue) {
        const startIdx = this.firstStartsAfterOrAt(startValue);
        const endIdx = this.lastEndsBeforeOrAt(endValue);
        return this.getValidInterval(startIdx, endIdx);
    }

    hasContained(startValue, endValue) {
        return !!this.rangeContained(startValue, endValue);
    }

    hasInterval(startValue, endValue) {
        const idx = this.startsAt(startValue);
        if (idx !== NO_INDEX) {
            return this.endPoints[idx] === endValue
        } else {
            return false;
        }
    }

    rangeIntersecting(startValue, endValue) {
        const startIdx = this.firstEndsAfterOrAt(startValue);
        const endIdx = this.lastStartsBeforeOrAt(endValue);
        return this.getValidInterval(startIdx, endIdx);
    }

    hasIntersecting(startValue, endValue) {
        return !!this.rangeIntersecting(startValue,endValue);
    }

    retrieveRange(startIdx, endIdx) {
        let result:Interval[] = [];
        for (let i = startIdx; i <= endIdx; i++) {
            result.push(this.intervalAt(i));
        }
        return result;
    }

    valueBounds(startIdx, endIdx) {
        return { starts: this.startPoints[startIdx], ends:this.endPoints[endIdx]};
    }

    translateStartPointsToValues(startIndexes: number[]) {
        return startIndexes.map( idx => this.startPoints[idx]);
    }

    translateEndPointsToValues(endIndexes: number[]) {
        // TODO think end point handling through
        // TODO really [|for idx in endIndexes -> endPoints.[idx - 1]|]??
        return endIndexes.map( idx => this.endPoints[idx]);
    }

    translate(startIndexes:number[], endIndexes:number[]) {
        return null; // TODO
        // SortedFactory.Make(translateStartPointsToValues(startIndexes),
        //                  translateEndPointsToValues(endIndexes))
    }

    // TODO rename
    mapRangesContained(intervals: Interval []) {
        let result:Interval [] = [];
        for (const interval of intervals) {
            let range = this.rangeContained(interval.starts, interval.ends);
            if (range) {
                // TODO is this is correct (inclusive) then don't need if statement at all
                result.push({ starts:range.starts, ends:range.ends});
                // TODO really think about exclusive/inclusive  { starts = result.starts; ends = result.ends + 1 }
            } else {
                result.push(null);
            }
        }
        return result;
    }

    fromGapIntervals(minSize:number) {
        const gapStarts = this.endPoints;
        const gapEnds = this.startPoints;
        const gapIntervals: Interval [] = [];
        const firstInterval = { starts: this.domainStart, ends:this.startPoints[0]};
        if (size(firstInterval) > minSize) {
            gapIntervals.push(firstInterval);
        }

        for (const [i, starts] of gapStarts.entries()) {
            const interval = {starts, ends:gapEnds[i + 1]}
            if (size(interval) > minSize) {
                gapIntervals.push(interval);
            }
        }
        // TODO
        return fromIntervals(gapIntervals);
    }

    fromExpandedIntervals(expandSize:number, direction:any) {
        const intervals = this.asIntervals();
        const resultIntervals:Interval[] = [];
        for (const interval of intervals) {
            if (size(interval) >= expandSize) {
                resultIntervals.push(interval);
            } else {

                // TODO use direction, create separate func which operates on intervals takes direction and size
                const ends = interval.ends;
                resultIntervals.push({starts: ends - expandSize, ends})
            }
        }
        return fromIntervals(intervals);
    }

    openTransitions() {
        return this.startPoints;
    }
}

// TODO make static on the class? but class has different name?
export function fromIntervals(intervals:Interval []) {
    const points = intervalsToStartEndPoints(intervals);
    return new Sorted(points.startPoints, points.endPoints, 0, 0);
}

export const EmptySorted = new Sorted();
