// open Fable.Core.JsInterop
// open Fable.Core.DynamicExtensions
// open Mobx
// open BasicTypes
// open ElementList
// open ElementTypes
// open Sorted
// open JSBoilerplate
// open ChaatContentRoots
// open Singletons
// open Tracking
// open ContentFuncs
// open ContentRoots

import { computed, makeObservable, observable, runInAction } from "mobx";
import { ChaatInputCue, ElementId, IdRange } from "./masala-lib/basic-types";
import { ElementList, EmptyElementList } from "./masala-lib/elements/element-list";
import { Tracking } from "./masala-lib/tracking/tracking";
import * as AppRoot from "./app-root";
import { EKinds } from "./masala-lib/elements/element-kinds";
import { EmptySorted, Interval, Sorted } from "./masala-lib/sorted/sorted";
import { wordIdRangeToWordStrings } from "./masala-lib/content-funcs";
import { getSentenceTimestampingSignature } from "./masala-lib/editorial/content-roots/content-roots";
import { ChaatContentRoots } from "./content-roots";

// let warningUiDescriptionLookup:obj = !< {|
export const warningUiDescriptionLookup = {
//     short = "seems like a short audio period for: "
    short:"seems like a short audio period for: ",
//     long = "seems like a long audio period for: "
    long:"seems like a long audio period for: ",
//     silences = "audio silences inside interpolation for: "
    silences:"audio silences inside interpolation for: ",
// |}
};

// type ChaatToolModel0() as s0 =
export class ChaatToolModel {

//     let self: IChaatToolModel = !< s0

//     (* @observable *)
//     let mutable episodeKey = ""
    @observable.ref episodeKey = "";

//     (* @observable *)
//     let mutable elements:IElementList = EmptyElementList
    @observable.ref elements:ElementList = EmptyElementList;

//     let mutable words:IElementList = EmptyElementList
    words:ElementList = EmptyElementList;

//     let mutable sentences:IElementList = EmptyElementList
    sentences:ElementList = EmptyElementList;

//     let disposers: (unit -> unit) [] = [||]
    disposers: (() => void) [] = [];

//     (* @observable *)
//     let editEnabled = false  // TODO??
    @observable.ref editEnabled = false;  // TODO??

//     (* @observable *)
//     let mutable currentCuePointWordId:ElementId = Null
    @observable.ref currentCuePointWordId:ElementId = null;

//     let mutable tracking:ITracking = Null
    tracking:Tracking = null;

//     (* @observable *)
//     let mutable wordTracker = Null
    @observable.ref wordTracker = null;

//     (* @observable *)
//     let mutable sentenceTracker = Null
    @observable.ref sentenceTracker = null;

//     (* @observable *)
//     let mutable warnings:IElementList = EmptyElementList
    @observable.ref warnings:ElementList = EmptyElementList;

//     // currently skip warn data has only start times end times and type string "silences" or "short"

//     (* @observable *)
//     let mutable warningSentences = EmptyElementList
    @observable.ref warningSentences = EmptyElementList;

//     (* @observable *)
//     let mutable notchTimeIntervals: ISorted = EmptySorted
    @observable.ref notchTimeIntervals: Sorted = EmptySorted;

//     (* @observable *)
//     let mutable segmentTimeIntervals: ISorted = EmptySorted // segmentElements and segmentIntervals also???
    @observable.ref segmentTimeIntervals: Sorted = EmptySorted; // segmentElements and segmentIntervals also???
                         // or the controller code initing the track area extracts intervals from elements?

//     (* @observable *)
//     let mutable segmentStopWords: IElementList = EmptyElementList
    @observable.ref segmentStopWords: ElementList = EmptyElementList;

//     (* @observable *)
//     let mutable transcriptWords: string [] = [||]
    @observable.ref transcriptWords: string [] = [];

//     (* @observable *)
//     let mutable transcriptWordTimeIntervals: ISorted = EmptySorted
    @observable.ref transcriptWordTimeIntervals: Sorted = EmptySorted;

//     (* @observable *)
//     let mutable cuesData: ChaatInputCue [] = [||]
    @observable.ref cuesData: ChaatInputCue [] = [];

//     (* @observable *)
//     let mutable cuedWords: IElementList = EmptyElementList
    @observable.ref cuedWords: ElementList = EmptyElementList;

//     (* @observable *)
//     let mutable cueDisplayTimeIntervals = EmptySorted
    @observable.ref cueDisplayTimeIntervals = EmptySorted;

//     (* @observable *)
//     let mutable interpolatedTimeIntervals = EmptySorted
    @observable.ref interpolatedTimeIntervals = EmptySorted;

//     (* @observable *)
//     let mutable majorWarnings:IElementList = EmptyElementList
    @observable.ref majorWarnings:ElementList = EmptyElementList;

//     (* @observable *)
//     let mutable minorWarnings:IElementList = EmptyElementList
    @observable.ref minorWarnings:ElementList = EmptyElementList;

//     (* @observable *)
//     let mutable warningTimeIntervals:ISorted = EmptySorted
    @observable.ref warningTimeIntervals:Sorted = EmptySorted;

//     (* @observable *)
//     let mutable warningData:string [] = [||]
    @observable.ref warningData:string [] = [];

//     (* @observable *)
//     let mutable interpolationData = Null // TODO or interpolations?
    @observable.ref interpolationData = null; // TODO or interpolations?

//     (* @observable *)
//     let mutable nonVoiceAudioRegions = [||]
    @observable.ref nonVoiceAudioRegions = [];

//     (* @observable *)
//     let mutable nonVoiceAudioRegionIntervals = EmptySorted
    @observable.ref nonVoiceAudioRegionIntervals = EmptySorted;

//     (* @observable *)
//     let mutable audioMarkers = [||]
    @observable.ref audioMarkers = [];

//     (* @observable *)
//     let mutable audioMarkerHitIntervals = EmptySorted
    @observable.ref audioMarkerHitIntervals = EmptySorted;

//     (* @observable *)
//     let mutable unsignedoffSentences:IElementList = EmptyElementList
    @observable.ref unsignedoffSentences:ElementList = EmptyElementList;

//     (* @observable *)
//     let mutable audioUrls:obj = obj()
    @observable.ref audioUrls:any = {};

//     (* @observable *)
//     let mutable stateVersion = 0
    @observable.ref stateVersion = 0;

//     let mutable audioRegionSelection: Interval = Null
    audioRegionSelection: Interval = null;

    constructor() {
        makeObservable(this);
    }

//     let init() =
    init() {
//         tracking <- AppRoot.tracking
        this.tracking = AppRoot.tracking;
//         wordTracker <- tracking.getTrackerWithKind(WORD)
        this.wordTracker = this.tracking.getTrackerWithKind(EKinds.WORD);
//         sentenceTracker <- tracking.getTrackerWithKind(SENTENCE)
        this.sentenceTracker = this.tracking.getTrackerWithKind(EKinds.SENTENCE);
//         disposers.append(AppRoot.appBus.subscribe("setCuePoint", (fun cuePoint -> currentCuePointWordId <- !< cuePoint)))
        this.disposers.push(AppRoot.appBus.subscribe("setCuePoint", (cuePoint) => this.currentCuePointWordId = cuePoint));
    }

//     let wordStrings():string [] =
    get wordStrings():string [] {
//         [|for word in words.elements -> word?text|]
        return this.words.elements.map( (word) => (<any>word).text);
    }

//     let wordTimeIntervals(): ISorted =
    get wordTimeIntervals(): Sorted {
//         words.timeIntervals
        return this.words.timeIntervals;
    }

//     (* @computed *)
//     let currentWordId(): ElementId =
    @computed
    get currentWordId(): ElementId {
//         wordTracker.observableIsUnder()
        return this.wordTracker.observableIsUnder();
    }

//     (* @computed *)
//     let currentSentenceId(): ElementId =
    @computed
    get currentSentenceId(): ElementId {
//         sentenceTracker.observableIsUnder()
        return this.sentenceTracker.observableIsUnder();
    }

//     let getWarningLevel(warningIndex) =
    getWarningLevel(warningIndex) {
//         if warningData.[warningIndex] = "silences" then 1 else 0
        return (this.warningData[warningIndex] === "silences") ?1:0;
    }

//     let getWarningUiText(warningIndex) =
    getWarningUiText(warningIndex) {
//         let warningRange:IdRange = warnings.elements.[warningIndex]?range
        const warningRange:IdRange = this.warnings.elements[warningIndex]["range"];
//         let warningWords = wordIdRangeToWordStrings(warningRange, elements.words)
        const warningWords = wordIdRangeToWordStrings(warningRange, this.elements.words);
//         !< warningUiDescriptionLookup.[warningData.[warningIndex]] + String.concat " " warningWords
        return warningUiDescriptionLookup[this.warningData[warningIndex]] + warningWords.join(" ");
    }

//     let setAudioRegionSelection(selection:Interval) = audioRegionSelection <- selection
    setAudioRegionSelection(selection:Interval) {
        this.audioRegionSelection = selection;
    }

//     let addCue() =
    addCue() {
//         AppRoot.cueActions.addCue()
        AppRoot.cueActions.addCue();
    }

//     let addShiftCue() =
    addShiftCue() {
//         AppRoot.cueActions.addShiftCue()
        AppRoot.cueActions.addShiftCue();
    }

//     let addShiftEndCue() =
    addShiftEndCue() {
        AppRoot.cueActions.addShiftEndCue();
    }

//     let removeCueAtCurrent() =
    removeCueAtCurrent() {
        AppRoot.cueActions.removeCueAtCurrent();
    }

//     let createAudioRegion() =
    createAudioRegion() {
//         AppRoot.audioRegionActions.addUpdateFromAudioSelection()
        AppRoot.audioRegionActions.addUpdateFromAudioSelection();
    }

//     let removeAudioRegion() =
    removeAudioRegion() {
//         AppRoot.audioRegionActions.removeFromAudioSelection()
        AppRoot.audioRegionActions.removeFromAudioSelection();
    }

//     let createAudioMarker() =
    createAudioMarker() {
        AppRoot.audioMarkerActions.addUpdateFromAudioPosition();
    }

//     let removeAudioMarker() =
    removeAudioMarker() {
//         AppRoot.audioMarkerActions.removeFromAudioPosition()
        AppRoot.audioMarkerActions.removeFromAudioPosition();
    }

//     let setCurrentSentenceSignoff(signoff:bool) =
    setCurrentSentenceSignoff(signoff:boolean) {
//         let sentenceId = currentSentenceId() // TODO this is calling function not getter
        const sentenceId = this.currentSentenceId; // TODO this is calling function not getter
//         if !!sentenceId then
        if (sentenceId) {
//             let sentence = sentences.getElement(sentenceId)
            const sentence = this.sentences.getElement(sentenceId);
//             let key = getSentenceTimestampingSignature(sentence, words)
            const key = getSentenceTimestampingSignature(sentence, this.words);
//             AppRoot.mutationActions.setChaatSignoff(key, signoff)
            AppRoot.mutationActions.setChaatSignoff(key, signoff);
        }
    }

//     let deselect() =
    deselect() {
//         AppRoot.appBus.emit("deselect", Null)
        AppRoot.appBus.emit("deselect", null);
    }

//     let updateFromContentRoots(roots:IChaatContentRoots) =
    updateFromContentRoots(roots:ChaatContentRoots) {
//         runInAction( fun () ->
        runInAction( () => {
//             episodeKey <- roots.episodeKey
            this.episodeKey = roots.episodeKey;
//             elements <- roots.content
            this.elements = roots.content;
//             words <- roots.words
            this.words = roots.words;
//             sentences <- elements.getKindSubList(SENTENCE)
            this.sentences = this.elements.getKindSubList(EKinds.SENTENCE);
//             nonVoiceAudioRegions <- roots.nonVoiceAudioRegions
            this.nonVoiceAudioRegions = roots.nonVoiceAudioRegions;
//             nonVoiceAudioRegionIntervals <- roots.nonVoiceAudioRegionIntervals
            this.nonVoiceAudioRegionIntervals = roots.nonVoiceAudioRegionIntervals;
//             audioMarkers <- roots.audioMarkers
            this.audioMarkers = roots.audioMarkers;
//             audioMarkerHitIntervals <- roots.audioMarkerHitIntervals
            this.audioMarkerHitIntervals = roots.audioMarkerHitIntervals;
//             segmentTimeIntervals <- roots.segmentTimeIntervals
            this.segmentTimeIntervals = roots.segmentTimeIntervals;
//             segmentStopWords <- roots.segmentStopWords
            this.segmentStopWords = roots.segmentStopWords;
//             warnings <- roots.warnings
            this.warnings = roots.warnings;
//             majorWarnings <- roots.majorWarnings
            this.majorWarnings = roots.majorWarnings;
//             minorWarnings <- roots.minorWarnings
            this.minorWarnings = roots.minorWarnings;
//             warningSentences <- roots.warningSentences
            this.warningSentences = roots.warningSentences;
//             warningTimeIntervals <- roots.warningTimeIntervals
            this.warningTimeIntervals = roots.warningTimeIntervals;
//             warningData <- roots.warningData
            this.warningData = roots.warningData;
//             interpolatedTimeIntervals <- roots.interpolatedTimeIntervals
            this.interpolatedTimeIntervals = roots.interpolatedTimeIntervals;
//             cuesData <- roots.chaatInputCues
            this.cuesData = roots.chaatInputCues;
//             cuedWords <- roots.cuedWords
            this.cuedWords = roots.cuedWords;
//             cueDisplayTimeIntervals <- roots.cueDisplayTimeIntervals
            this.cueDisplayTimeIntervals = roots.cueDisplayTimeIntervals;
//             transcriptWords <- roots.transcriptWords
            this.transcriptWords = roots.transcriptWords;
//             transcriptWordTimeIntervals <- roots.transcriptWordTimeIntervals
            this.transcriptWordTimeIntervals = roots.transcriptWordTimeIntervals;
//             notchTimeIntervals <- roots.notchTimeIntervals
            this.notchTimeIntervals = roots.notchTimeIntervals;
//             unsignedoffSentences <- roots.chaatUnsignedoffSentences
            this.unsignedoffSentences = roots.chaatUnsignedoffSentences;
//             audioUrls <- roots.audioUrls
            this.audioUrls = roots.audioUrls;
//             wordTracker <- tracking.getTrackerWithKind(WORD)
            this.wordTracker = this.tracking.getTrackerWithKind(EKinds.WORD);
//             sentenceTracker <- tracking.getTrackerWithKind(SENTENCE)
            this.sentenceTracker = this.tracking.getTrackerWithKind(EKinds.SENTENCE);
            // TODO something more context sensitive to set the nav point here?
//             AppRoot.playerState.navigationPoint <- AppRoot.navigation.getNavigatorForKey("SEGMENT").navigationPoint(0)
            AppRoot.playerState.navigationPoint = AppRoot.navigation.getNavigatorForKey("SEGMENT").navigationPoint(0);
//             stateVersion <- stateVersion + 1
            this.stateVersion++;
//         )
        });
    }

//     do autoBindInterface()
}

// let ChaatToolModel():IChaatToolModel = !< ChaatToolModel0()
