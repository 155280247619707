// open Fable.Core
// open JsInterop
// open BasicTypes
// open MutationActions
// open Sorted
// open JSBoilerplate

import { AudioRegionDTO, NO_INDEX } from "../../../basic-types";
import { Interval, Sorted } from "../../../sorted/sorted";
import { MutationActions } from "../../db/mutation-actions";

// type IAudioRegionActions =
//     abstract addUpdate: data:obj -> unit
//     abstract remove: ElementId -> unit
//     // TODO From -> Using, With???
//     abstract addUpdateFromAudioSelection: unit -> unit
//     abstract removeFromAudioSelection: unit -> unit
//     abstract openEditDialog: unit -> unit


// [<AbstractClass>]
// type AudioRegionActions0() as s0 =
export abstract class AudioRegionActions {

//     let self = s0

//     let getTargetRegion() =
    getTargetRegion() {
//         let selection = self.audioSelectionRegion
        const selection = this.audioSelectionRegion;
//         let regionIntervals = self.currentAudioRegionIntervals
        const regionIntervals = this.currentAudioRegionIntervals;
//         if !!selection then
        if (selection) {
//             let intersecting = regionIntervals.rangeIntersecting(selection.starts, selection.ends)
            const intersecting = regionIntervals.rangeIntersecting(selection.starts, selection.ends);
//             if !!intersecting then
            if (intersecting) {
//                 let intervals = regionIntervals.retrieveRange(intersecting.starts, intersecting.ends)
                const intervals = regionIntervals.retrieveRange(intersecting.starts, intersecting.ends);
//                 if intervals.Length = 1 then
                if (intervals.length === 1) {
//                     // TODO extend the intersecting instead
//                     ()
                }
            }
//             {id=Null; startTime=selection.starts; endTime=selection.ends}
            return {id:null, startTime:selection.starts, endTime:selection.ends};
//         else
        } else {
//             let targetRegionIndex = regionIntervals.containing(self.currentAudioPosition)
            const targetRegionIndex = regionIntervals.containing(this.currentAudioPosition);
//             if targetRegionIndex <> NO_INDEX then
            if (targetRegionIndex !== NO_INDEX) {
//                 !< self.currentAudioRegions.[targetRegionIndex]
                return this.currentAudioRegions[targetRegionIndex];
//             else
            } else {
//                 Null
                return null;
            }
        }
    }

//     let addUpdate(data:AudioRegionDTO) =
    addUpdate(data:AudioRegionDTO) {
//         self.mutationActions.addUpdateChaatAudioRegion(data)
        this.mutationActions.addUpdateChaatAudioRegion(data);
    }

//     let remove(id) =
    remove(id) {
//         self.mutationActions.removeChaatAudioRegion(id)
        this.mutationActions.removeChaatAudioRegion(id);
    }

//     let addUpdateFromAudioSelection() =
    addUpdateFromAudioSelection() {
//         let targetRegion = getTargetRegion()
        const targetRegion = this.getTargetRegion();
//         if !!targetRegion then
        if (targetRegion) {
//             addUpdate(targetRegion)
            this.addUpdate(targetRegion);
        }
    }

//     let removeFromAudioSelection() =
    removeFromAudioSelection() {
//         let targetRegion = getTargetRegion()
        const targetRegion = this.getTargetRegion();
//         if !!targetRegion then
        if (targetRegion) {
//             remove(targetRegion.id)
            this.remove(targetRegion.id);
        }
    }

//     do autoBindInterface()

//     abstract mutationActions:IMutationActions with get
    abstract get mutationActions():MutationActions;
//     abstract currentAudioPosition: int with get
    abstract get currentAudioPosition(): number;
//     abstract audioSelectionRegion: Interval with get
    abstract get audioSelectionRegion(): Interval;
//     abstract currentAudioRegions: obj [] with get // TODO typing
    abstract get currentAudioRegions(): any []; // TODO typing
//     abstract currentAudioRegionIntervals: ISorted with get
    abstract get currentAudioRegionIntervals(): Sorted;

}
