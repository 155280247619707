// open Fable.Core.JsInterop
// open Mobx
// open BasicTypes
// open ElementList
// open Sorted
// open TrackingEngine
// open Signal
// open System.Collections.Generic
// open JSBoilerplate

import { reaction, runInAction } from "mobx";
import { ElementId, isNull, NO_INDEX } from "../basic-types";
import { ElementList, EmptyElementList } from "../elements/element-list";
import { EmptySorted, Sorted } from "../sorted/sorted";
import { Signal } from "./signal";
import { clearChangeRecords, currentIsUnder, isBefore, isUnder, isVisited, recordChangesForNewPosition, refreshIntervals, TrackingState } from "./tracking-engine";

// type SignalArr = {
class SignalArr {
//     mutable signals: ISignal []
    signals: Signal [] = null;
// }
}

// type ITracker =
//     abstract dispose: unit -> unit
//     abstract setElements: IElementList -> unit
//     abstract subscribeIsUnder: (ElementId -> unit) -> (unit -> unit)
//     abstract subscribeIsBefore: (ElementId -> unit) -> (unit -> unit)
//     abstract subscribeIsVisited: (ElementId -> unit) -> (unit -> unit)
//     abstract isUnderSignal: ElementId -> ISignal
//     abstract isBeforeSignal: ElementId -> ISignal
//     abstract isVisitedSignal: ElementId -> ISignal
//     abstract changedSignal: ElementId -> ISignal
//     abstract anyIsChangedSignal: unit -> ISignal
//     abstract currentIsUnder: unit -> ElementId
//     abstract isUnder: ElementId -> bool
//     abstract isBefore: ElementId -> bool
//     abstract isVisited: ElementId -> bool
//     abstract elementInterval: ElementId -> Interval
//     abstract observableIsUnder: unit -> ElementId

// type Tracker0(triggerFunction0:unit -> obj, positionFunction0:unit -> int) =
export class Tracker {
    triggerFunction: () => any;
    positionFunction: () => number;
    disposers: (()=> void) [];
    elements: ElementList;
    sorted: Sorted;
    trackingState:TrackingState;
    isUnderListeners: Set<((el:ElementId) => void)>;
    isBeforeListeners: Set<((el:ElementId) => void)>;
    isVisitedListeners: Set<((el:ElementId) => void)>;
    isUnderSignals:SignalArr;
    isBeforeSignals:SignalArr;
    isVisitedSignals:SignalArr;
    changeSignals:SignalArr;
    anyIsChanged0: Signal;
    constructor(triggerFunction0:(() => any), positionFunction0:(()=>number)) {
//     let triggerFunction = triggerFunction0
        this.triggerFunction = triggerFunction0;
//     let positionFunction = positionFunction0
        this.positionFunction = positionFunction0;
//     let disposers:(unit -> unit) [] = [||]
        this.disposers = [];
//     let mutable elements:IElementList = EmptyElementList
        this.elements = EmptyElementList;
//     let mutable sorted: ISorted = EmptySorted
        this.sorted = EmptySorted;
//     let trackingState: TrackingState = TrackingStateFactory.Make()
        this.trackingState = new TrackingState();
//     let mutable isUnderListeners = Null
        this.isUnderListeners = null;
//     let mutable isBeforeListeners = Null
        this.isBeforeListeners = null;
//     let mutable isVisitedListeners = Null
        this.isVisitedListeners = null;
//     let isUnderSignals = { signals = Null}
        this.isUnderSignals = new SignalArr();
//     let isBeforeSignals = { signals = Null}
        this.isBeforeSignals = new SignalArr();
//     let isVisitedSignals = { signals = Null}
        this.isVisitedSignals = new SignalArr();
//     let changeSignals = { signals = Null}
        this.changeSignals = new SignalArr();
//     let mutable anyIsChanged0: ISignal = Null
        this.anyIsChanged0 = null;
//     do
//         disposers.append(reaction(triggerFunction, (fun () -> processPositionChange()), {||}))
        this.disposers.push(reaction(this.triggerFunction, () => this.processPositionChange()));
    }

//     let dispose() =
    dispose() {
//         for dispose in disposers do
        for (const dispose of this.disposers) {
//             dispose()
            dispose();
        }
    }

//     let setElements(elements0) =
    setElements(elements0) {
//         if elements0 <> elements then
        if (elements0 !== this.elements) {
//             isUnderSignals.signals <- !< elements.remapContentDimensionedArray(!< isUnderSignals.signals, elements0)
            this.isUnderSignals.signals = this.elements.remapContentDimensionedArray( this.isUnderSignals.signals, elements0)
//             isBeforeSignals.signals <- !< elements.remapContentDimensionedArray(!< isBeforeSignals.signals, elements0)
            this.isBeforeSignals.signals =  this.elements.remapContentDimensionedArray(this.isBeforeSignals.signals, elements0);
//             isVisitedSignals.signals <- !< elements.remapContentDimensionedArray(!< isVisitedSignals.signals, elements0)
            this.isVisitedSignals.signals = this.elements.remapContentDimensionedArray(this.isVisitedSignals.signals, elements0);
//             elements <- elements0
            this.elements = elements0;
//             sorted <- elements.timeIntervals
            this.sorted = this.elements.timeIntervals;
//             refreshIntervals(trackingState, sorted)
            refreshIntervals(this.trackingState, this.sorted);
        }
    }

//     let indexToElementId(index):ElementId =
    indexToElementId(index):ElementId  {
//         if index <> NO_INDEX then
        if (index !== NO_INDEX) {
//             elements.getId(index)
            return this.elements.getId(index);
//         else
        } else {
//             Null
            return null;
        }
    }

//     let elementIdToIndex(elementId) =
    elementIdToIndex(elementId:ElementId) {
//         elements.getIndex(elementId)
        return this.elements.getIndex(elementId);
    }

//     let newListeners() = HashSet<(ElementId -> unit)>()
    newListeners() {
        return new Set<((el:ElementId) => void)>();
    }

//     let makeUnsubscribe (listeners: HashSet<(ElementId -> unit)>) f (): unit = listeners.Remove(f) |> ignore
    makeUnsubscribe(listeners: Set<(el:ElementId) => void>, f:(el:ElementId) => void) {
        return () => listeners.delete(f);
    }

//     let addListener(listeners: HashSet<(ElementId -> unit)>, f)  =
    addListener(listeners: Set<(el:ElementId) => void>, f:(el:ElementId) => void) {
//         listeners.Add(f)
        listeners.add(f);
//         makeUnsubscribe listeners f
        return this.makeUnsubscribe(listeners, f);
    }

//     let notifyListenersForRange(listeners: HashSet<(ElementId -> unit)>, starts, ends) =
    notifyListenersForRange(listeners: Set<(el:ElementId) => void>, starts:number, ends:number) {
//         if !!listeners && starts >= 0 then
        if (listeners && starts >= 0) {
//             for callback in listeners do
            for(const callback of listeners) {
//                 for i in starts .. ends do
                for(let i=starts; i <= ends; i++) {
//                     let id = indexToElementId i
                    const id = this.indexToElementId(i);
//                     callback(id)
                    callback(id);
                }
            }
        }
    }

//     let notifyListeners(listeners: HashSet<(ElementId -> unit)>, index) =
    notifyListeners(listeners: Set<(el:ElementId) => void>, index:number) {
//         if !!listeners && index <> NO_INDEX then
        if (listeners && index !== NO_INDEX) {
//             for callback in listeners do
            for(const callback of listeners) {
//                 let id = indexToElementId index
                const id = this.indexToElementId(index);
//                 callback(id)
                callback(id);
            }
        }
    }

//     let makeSignal() = Signal()
    makeSignal() {
        return new Signal();
    }

//     let createSignalsArray():ISignal [] =
    createSignalsArray():Signal [] {
//         Array.create (sorted.length) Null
        return new Array(this.sorted.length).fill(null);
    }

//     let getOrCreateSignals(signalsArr) =
    getOrCreateSignals(signalsArr:SignalArr) {
//         if isNull(signalsArr.signals) then
        if (isNull(signalsArr.signals)) {
//             signalsArr.signals <- createSignalsArray()
            signalsArr.signals = this.createSignalsArray();
        }
//         signalsArr.signals
        return signalsArr.signals;
    }

//     let getSignal(signalsArr, idx) =
    getSignal(signalsArr:SignalArr, idx:number) {
//         let signals = getOrCreateSignals(signalsArr)
        const signals = this.getOrCreateSignals(signalsArr);
//         let mutable signal = signals.[idx]
        let signal = signals[idx];
//         if isNull signal then
        if (isNull(signal)) {
//             signal <- makeSignal()
            signal = this.makeSignal();
//             signals.[idx] <- signal
            signals[idx] = signal;
        }
//         signal
        return signal;
    }

//     let needNotifyAnyChange () =
    needNotifyAnyChange() {
//         !!changeSignals.signals
        return !!this.changeSignals.signals;
    }

//     let notifySignal(signal: ISignal) =
    notifySignal(signal: Signal)  {
//         if !!signal then
        if (signal) {
//             signal.set(trackingState.vIdx)
            signal.set(this.trackingState.vIdx);
        }
    }

//     let notifySignalIndex(signalsArr, index) =
    notifySignalIndex(signalsArr:SignalArr, index:number) {
        const signals = signalsArr.signals;
//         if !!signals && index <> NO_INDEX then
        if (signals && index !== NO_INDEX) {
//             notifySignal(signals.[index])
            this.notifySignal(signals[index]);
        }
    }

//     let notifySignalRange(signalsArr, starts, ends) =
    notifySignalRange(signalsArr:SignalArr, starts:number, ends:number) {
        // TODO is length check ever needed?
//         let signals = signalsArr.signals
        const signals = signalsArr.signals;
//         if !!signals && starts >= 0 && ends < signals.Length then
        if (signals && starts >= 0 && ends < signals.length) {
//             for i in starts .. ends do
            for(let i=starts; i<=ends; i++)  {
//                 notifySignal(signals.[i])
                this.notifySignal(signals[i]);
            }
        }
    }

//     let subscribeIsUnder(f) =
    subscribeIsUnder(f:(el:ElementId) => void) {
//         if isNull(isUnderListeners) then isUnderListeners <- newListeners()
        if (!this.isUnderListeners) {
            this.isUnderListeners = this.newListeners();
        }
//         addListener(isUnderListeners, f)
        this.addListener(this.isUnderListeners, f);
    }

//     let subscribeIsBefore(f) =
    subscribeIsBefore(f:(el:ElementId) => void) {
//         if isNull(isBeforeListeners) then isBeforeListeners <- newListeners ()
        if (!this.isBeforeListeners) {
        // isBeforeListeners <- newListeners ()
            this.isBeforeListeners = this.newListeners();

        }
//         addListener(isBeforeListeners, f)
        this.addListener(this.isBeforeListeners, f);
    }

//     let subscribeIsVisited(f) =
    subscribeIsVisited(f:(el:ElementId) => void) {
//         if isNull(isVisitedListeners) then isVisitedListeners <- newListeners ()
        if (!this.isVisitedListeners) {
            this.isVisitedListeners = this.newListeners();
        }
//         addListener(isVisitedListeners, f)
        this.addListener(this.isVisitedListeners, f);
    }

//     let notifyChange() =
    notifyChange() {
//         if !!anyIsChanged0 then
        if (this.anyIsChanged0) {
//             anyIsChanged0.set(trackingState.vIdx)
            this.anyIsChanged0.set(this.trackingState.vIdx);
        }
    }

//     let notifyIsBefore(starts, ends) =
    notifyIsBefore(starts:number, ends:number) {
//         notifyListenersForRange(isBeforeListeners, starts, ends)
        this.notifyListenersForRange(this.isBeforeListeners, starts, ends);
//         notifySignalRange(isBeforeSignals, starts, ends)
        this.notifySignalRange(this.isBeforeSignals, starts, ends);
//         if needNotifyAnyChange() then
        if (this.needNotifyAnyChange()) {
//             notifySignalRange(changeSignals, starts, ends)
            this.notifySignalRange(this.changeSignals, starts, ends);
        }
    }

//     let notifyIsVisited(starts, ends) =
    notifyIsVisited(starts:number, ends:number) {
//         notifyListenersForRange(isVisitedListeners, starts, ends)
        this.notifyListenersForRange(this.isVisitedListeners, starts, ends);
//         notifySignalRange(isVisitedSignals, starts, ends)
        this.notifySignalRange(this.isVisitedSignals, starts, ends);
    }

//     let notifyIsUnder(index) =
    notifyIsUnder(index:number) {
//         notifyListeners(isUnderListeners, index)
        this.notifyListeners(this.isUnderListeners, index);
//         notifySignalIndex(isUnderSignals, index)
        this.notifySignalIndex(this.isUnderSignals, index);
    }

//     let notifyAllChanges () =
    notifyAllChanges() {
//         runInAction(
        runInAction( () => {
//                 notifyIsUnder(trackingState.isUnderOldChangeIndex)
            this.notifyIsUnder(this.trackingState.isUnderOldChangeIndex);
//                 notifyIsUnder(trackingState.isUnderNewChangeIndex)
            this.notifyIsUnder(this.trackingState.isUnderNewChangeIndex);
//                 notifyIsBefore(trackingState.isBeforeChangeRangeStart, trackingState.isBeforeChangeRangeEnd)
            this.notifyIsBefore(this.trackingState.isBeforeChangeRangeStart, this.trackingState.isBeforeChangeRangeEnd);
//                 notifyIsVisited(trackingState.isVisitedChangeRangeStart, trackingState.isVisitedChangeRangeEnd)
            this.notifyIsVisited(this.trackingState.isVisitedChangeRangeStart, this.trackingState.isVisitedChangeRangeEnd);
//                 notifyChange()
            this.notifyChange();
//         )
        });
    }

//     let processPositionChange () =
    processPositionChange() {
//         let position = positionFunction()
        const position = this.positionFunction();
//         recordChangesForNewPosition(trackingState, position)
        recordChangesForNewPosition(this.trackingState, position);
//         if trackingState.anyChangeRecord then
        if (this.trackingState.anyChangeRecord) {
//             notifyAllChanges()
            this.notifyAllChanges();
//             clearChangeRecords(trackingState)
            clearChangeRecords(this.trackingState);
        }
    }

//     let anyIsChangedSignal() =
    get anyIsChangedSignal() {
//         if isNull anyIsChanged0 then
        if (!this.anyIsChanged0) {
//             anyIsChanged0 <- makeSignal()
            this.anyIsChanged0 = this.makeSignal();
        }
//         anyIsChanged0
        return this.anyIsChanged0;
    }

//     let isUnderSignal(elementId) =
    isUnderSignal(elementId:ElementId) {
//         getSignal(isUnderSignals, elementIdToIndex(elementId))
        return this.getSignal(this.isUnderSignals, this.elementIdToIndex(elementId));
    }

//     let isBeforeSignal(elementId) =
    isBeforeSignal(elementId:ElementId) {
//         getSignal(isBeforeSignals, elementIdToIndex(elementId))
        return this.getSignal(this.isBeforeSignals, this.elementIdToIndex(elementId));
    }

//     let isVisitedSignal(elementId) =
    isVisitedSignal(elementId:ElementId) {
//         getSignal(isVisitedSignals, elementIdToIndex(elementId))
        return this.getSignal(this.isVisitedSignals, this.elementIdToIndex(elementId));
    }

//     let changedSignal(elementId)=
    changedSignal(elementId:ElementId) {
//         getSignal(changeSignals, elementIdToIndex(elementId))
        return this.getSignal(this.changeSignals, this.elementIdToIndex(elementId))
    }

//     let currentIsUnder() =
    currentIsUnder() {
//         indexToElementId(currentIsUnder(trackingState))
        return this.indexToElementId(currentIsUnder(this.trackingState));
    }

//     let isUnder(elementId) =
    isUnder(elementId:ElementId) {
//         isUnder(trackingState, elementIdToIndex(elementId))
      return isUnder(this.trackingState, this.elementIdToIndex(elementId));
    }

//     let isBefore(elementId) =
    isBefore(elementId:ElementId) {
//         isBefore(trackingState, elementIdToIndex(elementId))
        return isBefore(this.trackingState, this.elementIdToIndex(elementId));
    }

//     let isVisited(elementId) =
    isVisited(elementId:ElementId) {
//         isVisited(trackingState, elementIdToIndex(elementId))
        isVisited(this.trackingState, this.elementIdToIndex(elementId));
    }

//     let elementInterval(elementId) =
    elementInterval(elementId:ElementId) {
//         sorted.intervalAt(elementIdToIndex(elementId))
        return this.sorted.intervalAt(this.elementIdToIndex(elementId));
    }

//     let observableIsUnder() =
    observableIsUnder() {
        // TODO optimize when implement anyIsUnderChanged signal (instead of anyIsChanged)
//         anyIsChangedSignal().watch()
        this.anyIsChangedSignal.watch()
//         currentIsUnder()
        return this.currentIsUnder();
    }

}

//         autoBindInterface()
