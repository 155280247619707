// open Fable.Core
// open JsInterop
// open BasicTypes
// open MutationActions
// open Sorted
// open JSBoilerplate

import { AudioMarkerDTO, NO_INDEX } from "../../../basic-types";
import { Sorted } from "../../../sorted/sorted";
import { MutationActions } from "../../db/mutation-actions.js";

// type IAudioMarkerActions =
//     abstract addUpdate: data:obj -> unit
//     abstract remove: ElementId -> unit
//     // TODO From -> Using, With???
//     abstract addUpdateFromAudioPosition: unit -> unit
//     abstract removeFromAudioPosition: unit -> unit


// [<AbstractClass>]
// type AudioMarkerActions0() as s0 =
export abstract class AudioMarkerActions {

//     let self = s0

//     let getTargetMarker():AudioMarkerDTO =
    getTargetMarker():AudioMarkerDTO {
//         let audioPos = self.currentAudioPosition
        const audioPos = this.currentAudioPosition;
//         let markerHitIntervals = self.currentAudioMarkerHitIntervals
        const markerHitIntervals = this.currentAudioMarkerHitIntervals;
//         let targetMarkerIndex = markerHitIntervals.containing(audioPos)
        const targetMarkerIndex = markerHitIntervals.containing(audioPos);
//         if targetMarkerIndex <> NO_INDEX then
        if (targetMarkerIndex !== NO_INDEX) {
//             !< self.currentAudioMarkers.[targetMarkerIndex]
            return this.currentAudioMarkers[targetMarkerIndex];
//         else
        } else {
//             Null
            return null;
        }
    }

//     let addUpdate(data:AudioMarkerDTO) =
    addUpdate(data:AudioMarkerDTO) {
//         self.mutationActions.addUpdateChaatAudioMarker(data)
        this.mutationActions.addUpdateChaatAudioMarker(data);
    }

//     let remove(id) =
    remove(id) {
        this.mutationActions.removeChaatAudioMarker(id);
    }

//     let addUpdateFromAudioPosition() =
    addUpdateFromAudioPosition() {
//         let existingMarker = getTargetMarker()
        const existingMarker = this.getTargetMarker();
//         if not !!existingMarker then
        if (!existingMarker) {
//             addUpdate({id=Null; time=self.currentAudioPosition})
            this.addUpdate({id:null, time:this.currentAudioPosition});
//         else
        } else {
//             // TODO warn cannot create overlapping need to delete first
//             ()
        }
    }

//     let removeFromAudioPosition() =
    removeFromAudioPosition() {
//         let targetMarker = getTargetMarker()
        const targetMarker = this.getTargetMarker();
//         if !!targetMarker then // TODO alert when not in a marker hit region?
        if (targetMarker) {  // TODO alert when not in a marker hit region?
//             remove(targetMarker.id)
            this.remove(targetMarker.id);
//         else
        } else {
//             // TODO warn no audio marker under audio position
//             ()
        }
    }

//     do autoBindInterface()

//     abstract mutationActions:IMutationActions with get
    abstract get mutationActions():MutationActions;
//     abstract currentAudioPosition: int with get
    abstract get currentAudioPosition(): number;
//     abstract currentAudioMarkers: obj [] with get // TODO typing
    abstract get currentAudioMarkers(): any [] // TODO typing
//     abstract currentAudioMarkerHitIntervals: ISorted with get
    abstract get currentAudioMarkerHitIntervals(): Sorted;
}
