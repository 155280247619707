// open BasicTypes
// open AudioRegionActions
// open AppRoot

import { chaatToolModel, mutationActions, transportState } from "../app-root";
import { AudioRegionActions } from "../masala-lib/editorial/models/actions/audio-region-actions";

// type AudioRegionActions1() =
export class ChaatAudioRegionActions extends AudioRegionActions {
//     inherit AudioRegionActions0()

//     override __.mutationActions with get() = mutationActions
    get mutationActions() {
        return mutationActions;
    }
//     override __.currentAudioPosition with get() = transportState.audioPosition
    get currentAudioPosition() {
        return transportState.audioPosition;
    }
//     override __.audioSelectionRegion with get() = chaatToolModel.audioRegionSelection
    get audioSelectionRegion() {
        return chaatToolModel.audioRegionSelection;
    }
//     override __.currentAudioRegions with get() = chaatToolModel.nonVoiceAudioRegions
    get currentAudioRegions() {
        return chaatToolModel.nonVoiceAudioRegions;
    }
//     override __.currentAudioRegionIntervals with get() = chaatToolModel.nonVoiceAudioRegionIntervals
    get currentAudioRegionIntervals() {
        return chaatToolModel.nonVoiceAudioRegionIntervals;
    }
}

// let AudioRegionActions():IAudioRegionActions = !< AudioRegionActions1()
