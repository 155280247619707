import React from "react";
import Layout from "./layout";
import { appRoot } from "../app-root";
import '../App.css';

export default ({ match }) => {
  // this value comes from the URL, provided by react-router
  const {
    params: { episodeKey = null }
  } = match;
  appRoot.loadEpisode(episodeKey);
  return (
    <div>
      <Layout />
    </div>
  );
};
