// open BasicTypes
// open Precedence
// open ElementTypes
// open Fable.Core
// open DynamicExtensions

import { Element } from "../basic-types";
import { fastSortOnIntKey } from "../fast-sort-on-int-key";
import { EKinds } from "./element-kinds";
import { Precedence } from "./precedence";
// [<ImportMember("../fast-sort-on-int-key.js")>]
// let fastSortOnIntKey(a: obj[], p:obj -> int): unit = jsNative

let elementSecondarySortOrder = new Precedence([
    EKinds.PARAGRAPH_BREAK,
    EKinds.CHAPTER_COMPLETE,
    EKinds.CHAPTER_TITLE,
    EKinds.CHAPTER_SUMMARY,
    EKinds.CULTURAL_NOTE,
    EKinds.PASSAGE_HINT,
    EKinds.SPEAKER_LABEL,
    EKinds.SENTENCE,
    EKinds.WORD,
    EKinds.WORD_GROUP,
])

export const elementKindToSortPrecedence = elementSecondarySortOrder.keyToPrecedenceLevel

// let elementSortProjection(element:IElement) =
export function elementSortProjection(element:Element) {
//     let wordIndex:int = element.wordAddress
    const wordIndex:number = element.wordAddress;
//     let kindPrecedence:int = !< elementKindToSortPrecedence.[element.kind]
    const kindPrecedence:number = elementKindToSortPrecedence[element.kind];
//     wordIndex <<< 4 ||| kindPrecedence
    return wordIndex << 4 | kindPrecedence;
}

// let sortElements(elements) =
export function sortElements(elements:Element []) {
//     fastSortOnIntKey(!< elements, !< elementSortProjection)
  fastSortOnIntKey(elements, elementSortProjection);
}
