// open Fable.Core
// open Fable.Core.JsInterop
// open BasicTypes
// open Fable.React
// open Keyboardist
// open Mobx
// open AppRoot
// open ElementList
// open ElementTypes
// open StyleLayers
// open DomScroll
// open Browser.Types
// open JSBoilerplate

import React from "react";
import { appBus, appRoot, chaatToolModel, player, tracking, transportState } from "./app-root";
import Keyboardist from "keyboardist";
import { domIdToElementId, ElementId, getKindFromId } from "./masala-lib/basic-types";
import { simpleRenderStylesForElementId, StyleLayer, StyleLayersRenderer } from "./masala-lib/editorial/ui/style-painting/style-layers";
import { EKinds } from "./masala-lib/elements/element-kinds";
import { SimpleElementList } from "./masala-lib/elements/element-list";
import { autorun, reaction } from "mobx";
import { scrollIfNotVisible } from "./masala-lib/editorial/ui/dom-scroll";

// type IChaatTool =
//     abstract todo:obj // TODO

// let inline renderChaatToolView(chaat:IChaatTool):ReactElement = JsInterop.import "renderView" "./chaat-tool-view.js"
import {renderView as renderChaatToolView} from "./chaat-tool-view.js";
import { observer } from "mobx-react";

// type ChaatTool0(initialProps) as s0 =
@observer
export class ChaatTool extends React.Component<any> {

//     inherit Component<obj, obj>(initialProps)
//     let self:IChaatTool = !< s0

//     let model = chaatToolModel
    model = chaatToolModel;

//     let disposers:(unit -> unit) [] = [||]
    disposers:(() => void) [] = [];

//     let mutable unsubscribeWordIsUnderNotify:unit -> unit = Null
    unsubscribeWordIsUnderNotify:() => void = null;
//     let mutable unsubscribeSentenceIsUnderNotify:unit -> unit = Null
    unsubscribeSentenceIsUnderNotify:() => void = null;

//     let inputHandler = Keyboardist()
    inputHandler = Keyboardist();

    constructor(props) {
        super(props);
//         initializeInputHandlers()
        this.initializeInputHandlers();
//         disposers.append(reaction(!< (fun () -> styleLayers()), (fun () -> renderStyleLayers()), {||}))
        this.disposers.push(reaction(() => this.styleLayers(), () => this.renderStyleLayers()));
//         autorun(fun () -> setupCursorTracking() ) |> ignore
        autorun(() => this.setupCursorTracking() );
//         disposers.append(reaction(!< (fun () -> model.currentSentenceId), (fun () -> scrollIfNotVisible(model.currentSentenceId, "nearest","scriptId")), {||}))
        this.disposers.push(reaction(() => this.model.currentSentenceId, () => scrollIfNotVisible(this.model.currentSentenceId, "nearest","scriptId")));
    }

//     let initializeInputHandlers() =
    initializeInputHandlers() {
//         let handler = inputHandler
        const handler = this.inputHandler;
        // TODO actual forwards
//         handler.subscribe("KeyC", (fun () -> model.addCue()))
        handler.subscribe("KeyC", () => this.model.addCue());
//         handler.subscribe("Shift+KeyC", (fun () -> model.removeCueAtCurrent()))
        handler.subscribe("Shift+KeyC", () => this.model.removeCueAtCurrent());

//         handler.subscribe("KeyR", (fun () -> model.createAudioRegion()))
        handler.subscribe("KeyR", () => this.model.createAudioRegion());
//         handler.subscribe("Shift+KeyR", (fun () -> model.removeAudioRegion() ))
        handler.subscribe("Shift+KeyR", () => this.model.removeAudioRegion() );

//         handler.subscribe("KeyM", (fun () -> model.createAudioMarker()))
        handler.subscribe("KeyM", () => this.model.createAudioMarker());
//         handler.subscribe("Shift+KeyM", (fun () -> model.removeAudioMarker() ))
        handler.subscribe("Shift+KeyM", () => this.model.removeAudioMarker() );

//         handler.subscribe("KeyA", (fun () -> model.addShiftCue()))
        handler.subscribe("KeyA", () => this.model.addShiftCue());
//         handler.subscribe("KeyS", (fun () -> model.addShiftEndCue()))
        handler.subscribe("KeyS", () => this.model.addShiftEndCue());

//         handler.subscribe("KeyO", (fun () -> model.setCurrentSentenceSignoff(true)))
        handler.subscribe("KeyO", () => this.model.setCurrentSentenceSignoff(true));
//         handler.subscribe("Shift+KeyO", (fun () -> model.setCurrentSentenceSignoff(false)))
        handler.subscribe("Shift+KeyO", () => this.model.setCurrentSentenceSignoff(false));

        // TODO change param value to enum is now a bool but thought was int
//         handler.subscribe("Equal", (fun () -> appBus.emit("timeZoom", true)))
        handler.subscribe("Equal", () => appBus.emit("timeZoom", true));
//         handler.subscribe("Plus", (fun () -> appBus.emit("timeZoom", true)))
        handler.subscribe("Plus", () => appBus.emit("timeZoom", true));
//         handler.subscribe("NumpadAdd", (fun () -> appBus.emit("timeZoom", true)))
        handler.subscribe("NumpadAdd", () => appBus.emit("timeZoom", true));

//         handler.subscribe("Minus", (fun () -> appBus.emit("timeZoom", false)))
        handler.subscribe("Minus", () => appBus.emit("timeZoom", false));
//         handler.subscribe("NumpadSubtract", (fun () -> appBus.emit("timeZoom", false)))
        handler.subscribe("NumpadSubtract", () => appBus.emit("timeZoom", false));

//         handler.subscribe("Escape", (fun () -> model.deselect() ))
        handler.subscribe("Escape", () => this.model.deselect() );

//         handler.subscribe("Space", (fun () -> player.togglePlay()))
        handler.subscribe("Space", () => player.togglePlay());
//         handler.subscribe("Ctrl+Space", (fun () -> player.playSection()))
        handler.subscribe("Ctrl+Space", () => player.playSection());
//         handler.subscribe("KeyQ", (fun () -> player.playSection()))
        handler.subscribe("KeyQ", () => player.playSection());
//         handler.subscribe("Enter", (fun () -> player.rewind()))
        handler.subscribe("Enter", () => player.rewind());
//         handler.subscribe("Shift+Enter", (fun () -> player.playPeekBack()))
        handler.subscribe("Shift+Enter", () => player.playPeekBack());
//         handler.subscribe("KeyX", (fun () -> player.playPeek()))
        handler.subscribe("KeyX", () => player.playPeek());
//         handler.subscribe("Shift+KeyX", (fun () -> player.playPeekBack()))
        handler.subscribe("Shift+KeyX", () => player.playPeekBack());
//         handler.subscribe("Up", (fun () -> player.adjustPlaybackRateAction(true)))
        handler.subscribe("Up", () => player.adjustPlaybackRateAction(true)); // TODO
//         handler.subscribe("Down", (fun () -> player.adjustPlaybackRateAction(false)))
        handler.subscribe("Down", () => player.adjustPlaybackRateAction(false)); // TODO
//         handler.subscribe("Shift+Left", (fun () -> player.seek(transportState.audioPosition - 1500) )) // TODO
        handler.subscribe("Shift+Left", () => player.seek(transportState.audioPosition - 1500) ); // TODO

//         handler.subscribe("Shift+Right", (fun () -> player.seek(transportState.audioPosition + 1500) )) // TODO
        handler.subscribe("Shift+Right", () => player.seek(transportState.audioPosition + 1500) ); // TODO
//         handler.subscribe("Alt+Left", (fun () -> player.nudge(-20)))
        handler.subscribe("Alt+Left", () => player.nudge(-20));
//         handler.subscribe("Alt+Right", (fun () -> player.nudge(20)))
        handler.subscribe("Alt+Right", () => player.nudge(20));
//         handler.subscribe("Left", (fun () -> player.prevClosest()))
        handler.subscribe("Left", () => player.prevClosest());
//         handler.subscribe("Right", (fun () -> player.nextClosest()))
        handler.subscribe("Right", () => player.nextClosest());
//         handler.subscribe("Digit0", (fun () -> appRoot.runTimestamping()))
        handler.subscribe("Digit0", () => appRoot.runTimestamping());
//         ()
    }

//     let handleCursorChange(id:ElementId) =
    handleCursorChange(id:ElementId) {
//         let under = tracking.isUnder(id)
        const under = tracking.isUnder(id);
//         simpleRenderStylesForElementId(id, [|"cursor-is-under"|], not under)
        simpleRenderStylesForElementId(id, ["cursor-is-under"], !under);
    }

//     let handleWordClick(event:MouseEvent, id) =
    handleWordClick(event:MouseEvent, id) {
//         if  event.altKey || event.ctrlKey || event.shiftKey then
        if  (event.altKey || event.ctrlKey || event.shiftKey) {
//             appBus.emit("setCuePoint", id)
            appBus.emit("setCuePoint", id);
//         else
        } else {
//             player.seekElement(id)
            player.seekElement(id);
        }
    }

//     (* @bind *)
//     let handleLineClick(event:MouseEvent, domId) =
    handleLineClick(event:MouseEvent, domId) {
//         let id = domIdToElementId Null domId
        const id = domIdToElementId(null, domId);
//         let kind = getKindFromId(id)
        const kind = getKindFromId(id);
//         if kind = WORD then
        if (kind === EKinds.WORD) {
//             handleWordClick(event, id)
            this.handleWordClick(event, id);
//         else
        } else {
//             ()
        }
    }

//     let setupCursorTracking() =
    setupCursorTracking() {
//         if !!unsubscribeWordIsUnderNotify then
        if (this.unsubscribeWordIsUnderNotify) {
//             unsubscribeWordIsUnderNotify()
            this.unsubscribeWordIsUnderNotify();
        }

//         if !!unsubscribeSentenceIsUnderNotify then
        if (this.unsubscribeSentenceIsUnderNotify) {
//             unsubscribeSentenceIsUnderNotify()
            this.unsubscribeSentenceIsUnderNotify();
        }

//         unsubscribeWordIsUnderNotify <- model.wordTracker.subscribeIsUnder(handleCursorChange)
        this.unsubscribeWordIsUnderNotify = this.model.wordTracker.subscribeIsUnder((id:ElementId) => this.handleCursorChange(id));
//         unsubscribeSentenceIsUnderNotify <- model.sentenceTracker.subscribeIsUnder(handleCursorChange)
        this.unsubscribeSentenceIsUnderNotify = this.model.sentenceTracker.subscribeIsUnder((id:ElementId) => this.handleCursorChange(id));
    }

//     // let currentSentenceStyleLayer() =
//     // let currentSentenceStyleLayer() =
//     //    // TODO implement currentSentenceElement on model instead?
//     //    let currentSentenceElement = if !!model.currentSentenceId then model.elements.getElement(model.currentSentenceId) else Null
//     //    let els = if !!currentSentenceElement then [|currentSentenceElement|] else [||]
//     //    { styles=[|"current-sentence"|]; domScope=""; supportWordRanges=false; elements=SimpleElementList(els); stylesString=Null }

//     let minorWarningsStyleLayer() =
    minorWarningsStyleLayer() {
        // TODO remove these conditionals everywhere because model initialized to EmptyElementList
//         let elements = if !!model.minorWarnings then model.minorWarnings else SimpleElementList([||]) // TODO change to some const EMPTY_ELEMENT_LIST??
        const elements = (this.model.minorWarnings) ?? SimpleElementList([]); // TODO change to some const EMPTY_ELEMENT_LIST??
//         { styles=[|"minor-warning"|]; domScope=""; supportWordRanges=true; elements=elements; stylesString=Null }
        return { styles:["minor-warning"], domScope:"", supportWordRanges:true, elements:elements, stylesString:null };
    }

    // TODO computedFunc?
//     let majorWarningsStyleLayer() =
    majorWarningsStyleLayer() {
//         let elements = if !!model.majorWarnings then model.majorWarnings else SimpleElementList([||]) // TODO change to some const EMPTY_ELEMENT_LIST??
        const elements = (this.model.majorWarnings) ?? SimpleElementList([]); // TODO change to some const EMPTY_ELEMENT_LIST??
//         { styles=[|"major-warning"|]; domScope=""; supportWordRanges=true; elements=elements; stylesString=Null }
        return { styles:["major-warning"], domScope:"", supportWordRanges:true, elements:elements, stylesString:null };
    }

//     // TODO computedFunc?
    // TODO computed?
//     let segmentsStyleLayer() =
    segmentsStyleLayer() {
//         let elements = if !!model.segmentStopWords then model.segmentStopWords else SimpleElementList([||]) // TODO change to some const EMPTY_ELEMENT_LIST??
        const elements = (this.model.segmentStopWords) ?? SimpleElementList([]); // TODO change to some const EMPTY_ELEMENT_LIST??
//         { styles=[|"segment-stop-word"|]; domScope=""; supportWordRanges=false; elements=elements; stylesString=Null }
        return { styles:["segment-stop-word"], domScope:"", supportWordRanges:false, elements:elements, stylesString:null };
    }

//     let cueMarkersStyleLayer() =
cueMarkersStyleLayer() {
//         let elements = if !!model.cuedWords then model.cuedWords else SimpleElementList([||]) // TODO change to some const EMPTY_ELEMENT_LIST??
    const elements = this.model.cuedWords ?? SimpleElementList([]); // TODO change to some const EMPTY_ELEMENT_LIST??
//         { styles=[|"cue-marker"|]; domScope=""; supportWordRanges=false; elements=elements; stylesString=Null }
    return { styles:["cue-marker"], domScope:"", supportWordRanges:false, elements:elements, stylesString:null };
}

//     let cueInsertPointStyleLayer() =
    cueInsertPointStyleLayer() {
//         let cuePointWordId = model.currentCuePointWordId
        const cuePointWordId = this.model.currentCuePointWordId;
//         let currentCuePointElement = if !!cuePointWordId then model.words.getElement(cuePointWordId) else Null
        const currentCuePointElement = (cuePointWordId) ? this.model.words.getElement(cuePointWordId) :null;
//         let els = if !!currentCuePointElement then [|currentCuePointElement|] else [||]
        const els = (currentCuePointElement) ? [currentCuePointElement] : [];
//         { styles=[|"cue-insert-point"|]; domScope=""; supportWordRanges=false; elements=SimpleElementList(els); stylesString=Null }
        return { styles:["cue-insert-point"], domScope:"", supportWordRanges:false, elements:SimpleElementList(els), stylesString:null };
    }

//     let unsignedoffSentencesStyleLayer() =
    unsignedoffSentencesStyleLayer() {
//         { styles=[|"unsignedoff"|]; domScope=""; supportWordRanges=false; elements=model.unsignedoffSentences; stylesString=Null }
        return { styles:["unsignedoff"], domScope:"", supportWordRanges:false, elements:this.model.unsignedoffSentences, stylesString:null };
    }

//     let styleLayers() =
    styleLayers() {
//         let result:JS.Map<string, StyleLayer> = JS.Constructors.Map.Create()
        const result:Map<string, StyleLayer> = new Map();
        // TODO should the individual style layers be @computed or @computedFunc, reference ids changing every time any changes?
//         // result.set("currentSentence", currentSentenceStyleLayer())
//         result.set("minorWarnings", minorWarningsStyleLayer())
        result.set("minorWarnings", this.minorWarningsStyleLayer());
//         result.set("majorWarnings", majorWarningsStyleLayer())
        result.set("majorWarnings", this.majorWarningsStyleLayer());
//         result.set("segmentStopWords", segmentsStyleLayer())
        result.set("segmentStopWords", this.segmentsStyleLayer());
//         result.set("cueMarkers", cueMarkersStyleLayer())
        result.set("cueMarkers", this.cueMarkersStyleLayer());
//         result.set("cueInsertPoint", cueInsertPointStyleLayer())
        result.set("cueInsertPoint", this.cueInsertPointStyleLayer());
//         result.set("unsignedOffSentences", unsignedoffSentencesStyleLayer())
        result.set("unsignedOffSentences", this.unsignedoffSentencesStyleLayer());
        return result;
    }

//     let styleLayersRenderer = StyleLayersRenderer()
    styleLayersRenderer = new StyleLayersRenderer();

//     let renderStyleLayers() =
    renderStyleLayers() {
//         styleLayersRenderer.renderStyleLayers(model.episodeKey, styleLayers())
        this.styleLayersRenderer.renderStyleLayers(this.model.episodeKey, this.styleLayers());
    }

//     do
//         autoBindInterface()

//     override _.render() =
    render() {
//         renderChaatToolView(self)
        return renderChaatToolView(this);
    }
}

// export const ChaatTool = observer(ChaatToolImpl);

// let ChaatTool:ReactElement = emitJsStatement observer "return observer(ChaatTool0)"
