
export function searchSorted(sorted:number[], value:number, returnHighest=true) {
    let low = 0;
    let high = sorted.length;
    while (low < high) {
        const mid = (low + high) >> 1;
        const computed = sorted[mid];
        if ( (returnHighest) ? computed <= value : computed < value) {
            low = mid + 1;
        } else {
            high = mid;
        }
    }
    return high;
}
