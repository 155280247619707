import {makeObservable, observable} from 'mobx';
import {FirestoreDocSet} from "../../firestore-db/firestore-doc-set.js";
import {DbPaths} from "./db.js";
import {db} from '../../platform/firebase-init';
import {isEmpty, isNil} from 'lodash';
import {epochSecondsFloat} from '../../utils';

export class ChaatDocSet extends FirestoreDocSet {

  constructor(key, listenMode=true) {
    super(key, listenMode);
    // TODO think about putting docs in sub object and creating some generic functions for clear, copy, hasAll etc..
    this.episodeMetadataDoc = null;
    this.chaatMetadataDoc = null;
    this.verbatimDoc = null;
    this.structuralDoc = null;
    this.wordGroupsDoc = null;
    this.cuesDoc = null;
    this.timestampsDoc = null;
    this.audioAnalysisDoc = null;
    this.audioRegionsDoc = null;
    this.audioMarkersDoc = null;
    this.speechTranscriptDoc = null;
    this.audioProcessingJobDoc = null;
    this.transcriptionJobDoc = null;
    this.chaatSignoffsDoc = null;
    makeObservable(this, {
      episodeMetadataDoc: observable.ref,
      chaatMetadataDoc: observable.ref,
      verbatimDoc: observable.ref,
      structuralDoc: observable.ref,
      wordGroupsDoc: observable.ref,
      cuesDoc: observable.ref,
      timestampsDoc: observable.ref,
      audioAnalysisDoc: observable.ref,
      audioRegionsDoc: observable.ref,
      audioMarkersDoc: observable.ref,
      speechTranscriptDoc: observable.ref,
      audioProcessingJobDoc: observable.ref,
      transcriptionJobDoc: observable.ref,
      chaatSignoffsDoc: observable.ref,
    })
  }

  clearAllDocs() {
    this.episodeMetadataDoc = null;
    this.chaatMetadataDoc = null;
    this.verbatimDoc = null;
    this.structuralDoc = null;
    this.wordGroupsDoc = null;
    this.cuesDoc = null;
    this.timestampsDoc = null;
    this.audioAnalysisDoc = null;
    this.audioRegionsDoc = null;
    this.audioMarkersDoc = null;
    this.speechTranscriptDoc = null;
    this.audioProcessingJobDoc = null;
    this.transcriptionJobDoc = null;
    this.chaatSignoffsDoc = null;
  }

  get hasAllDocs() {
    const hasAll = (
      this.episodeMetadataDoc &&
      this.chaatMetadataDoc &&
      this.verbatimDoc &&
      this.structuralDoc &&
      this.wordGroupsDoc &&
      this.cuesDoc &&
      this.timestampsDoc &&
      this.audioAnalysisDoc &&
      this.audioRegionsDoc &&
      this.audioMarkersDoc &&
      this.speechTranscriptDoc &&
      this.audioProcessingJobDoc &&
      this.transcriptionJobDoc &&
      this.chaatSignoffsDoc
    );
    return hasAll;
  }

  // TODO naming updateStatus()??
  verifyDocSet() {
    if (this.hasAllDocs) {
      // TODO still needs work overall to detect intermediate conditions like audio updated but transcription job not started yet

      if (isEmpty(this.chaatMetadataDoc)) {
        this.status = "CHAAT_NOT_INITIALIZED";
        return;
      }
      if (isEmpty(this.transcriptionJobDoc) || isNil(this.transcriptionJobDoc.jobFinishTimestamp)) {
        this.status = "TRANSCRIPTION_NOT_FINISHED"; // TODO use constants for all of these values
        return;
      }
      if (this.transcriptionJobDoc.jobStartTimestamp > this.transcriptionJobDoc.jobFinishTimestamp) {
        this.status = "TRANSCRIPTION_NOT_FINISHED";
        return;
      }
      if (this.verbatimDoc.timestamp === this.timestampsDoc.scriptTimestamp &&
          this.cuesDoc.timestamp === this.timestampsDoc.cuesTimestamp &&
          this.audioRegionsDoc.timestamp === this.timestampsDoc.regionsTimestamp) {
        this.status = "COMPLETE";
      } else {
        const latestMutationTimestamp = Math.max(this.verbatimDoc.timestamp, this.cuesDoc.timestamp, this.audioRegionsDoc.timestamp);
        if (latestMutationTimestamp < (epochSecondsFloat() - 60)) {
          this.status = "VERY_OBSOLETE_TIMESTAMP_DATA";
          return;
        }
        this.status = "OBSOLETE_TIMESTAMP_DATA";
      }
    }
  }


  copyTo(dest) {
    dest.episodeMetadataDoc = this.episodeMetadataDoc;
    dest.chaatMetadataDoc = this.chaatMetadataDoc;
    dest.verbatimDoc = this.verbatimDoc;
    dest.structuralDoc = this.structuralDoc;
    dest.wordGroupsDoc = this.wordGroupsDoc;
    dest.cuesDoc = this.cuesDoc;
    dest.timestampsDoc = this.timestampsDoc;
    dest.audioAnalysisDoc = this.audioAnalysisDoc;
    dest.audioRegionsDoc = this.audioRegionsDoc;
    dest.audioMarkersDoc = this.audioMarkersDoc;
    dest.speechTranscriptDoc = this.speechTranscriptDoc;
    dest.audioProcessingJobDoc = this.audioProcessingJobDoc;
    dest.transcriptionJobDoc = this.transcriptionJobDoc;
    dest.chaatSignoffsDoc = this.chaatSignoffsDoc;
  }

  watchAllDocs(cb) {
    const disposers = [];
    disposers.push(this.watchDocument(() => this.episodeMetadataDoc, cb));
    disposers.push(this.watchDocument(() => this.chaatMetadataDoc, cb));
    disposers.push(this.watchDocument(() => this.verbatimDoc, cb));
    disposers.push(this.watchDocument(() => this.structuralDoc, cb));
    disposers.push(this.watchDocument(() => this.wordGroupsDoc, cb));
    disposers.push(this.watchDocument(() => this.cuesDoc, cb));
    disposers.push(this.watchDocument(() => this.timestampsDoc, cb));
    disposers.push(this.watchDocument(() => this.audioAnalysisDoc, cb));
    disposers.push(this.watchDocument(() => this.audioRegionsDoc, cb));
    disposers.push(this.watchDocument(() => this.audioMarkersDoc, cb));
    disposers.push(this.watchDocument(() => this.speechTranscriptDoc, cb));
    disposers.push(this.watchDocument(() => this.audioProcessingJobDoc, cb));
    disposers.push(this.watchDocument(() => this.transcriptionJobDoc, cb));
    disposers.push(this.watchDocument(() => this.chaatSignoffsDoc, cb));
    return () => { for (let d of disposers) d()}
  }

  listenFirestoreUpdates() {
    const disposers = [];
    const paths = new DbPaths(db, this.key);
    disposers.push(this.onSnapshot(paths.metadataDocRef, doc => this.episodeMetadataDoc = doc.data()));
    disposers.push(this.onSnapshot(paths.chaatMetadataDocRef, doc => this.chaatMetadataDoc = doc.data()));
    disposers.push(this.onSnapshot(paths.verbatimDocRef, doc => this.verbatimDoc = doc.data()));
    disposers.push(this.onSnapshot(paths.structuralDocRef, doc => this.structuralDoc = doc.data()));
    disposers.push(this.onSnapshot(paths.wordGroupsDocRef, doc => this.wordGroupsDoc = doc.data()));
    disposers.push(this.onSnapshot(paths.chaatCuesDocRef, doc => this.cuesDoc = doc.data()));
    disposers.push(this.onSnapshot(paths.chaatTimestampsDocRef, doc => this.timestampsDoc = doc.data()));
    disposers.push(this.onSnapshot(paths.chaatAudioAnalysisDocRef, doc => this.audioAnalysisDoc = doc.data()));
    disposers.push(this.onSnapshot(paths.chaatAudioRegionsDocRef, doc => this.audioRegionsDoc = doc.data()));
    disposers.push(this.onSnapshot(paths.chaatAudioMarkersDocRef, doc => this.audioMarkersDoc = doc.data()));
    disposers.push(this.onSnapshot(paths.chaatSpeechTranscriptsDocRef, doc => this.speechTranscriptDoc = doc.data()));
    disposers.push(this.onSnapshot(paths.chaatAudioProcessingJobDocRef, doc => this.audioProcessingJobDoc = doc.data()));
    disposers.push(this.onSnapshot(paths.chaatTranscriptionJobDocRef, doc => this.transcriptionJobDoc = doc.data()));
    disposers.push(this.onSnapshot(paths.chaatSignoffsDocRef, doc => this.chaatSignoffsDoc = doc.data()));
    return () => { for (let d of disposers) d()}
  }
}
