// open Fable.Core
// open JsInterop
// open ContentRoots
// open BasicTypes
// open TimestamperUtil
// open ContentFuncs
// open Spans
// open JSBoilerplate

import { computed, makeObservable } from "mobx";
import { strongNormalizeWordArray } from "../../../content-funcs";
import { ContentRootsBase } from "../../content-roots/content-roots";
import { AudioRegionsSpan, ContentSpan, CuesSpan, interleavedToStartsEnds, reconcileAudioIntervals, startsAndEndsToInterleaved, TranscriptSpan } from "./core/spans.fs.js";
import { unionIntervals } from "./core/util.fs.js";

// type ITimestamperRoots =
//     abstract contentSpan: ContentSpan
//     abstract transcriptSpan: TranscriptSpan
//     abstract cuesSpan: CuesSpan
//     abstract audioRegionsSpan: AudioRegionsSpan
//     abstract startTime: int
//     abstract endTime: int


// type TimestamperRoots0(contentRoots0:IContentRootsBase) as s0 =
export class TimestamperRoots {
//     let self = s0

//     let contentRoots = contentRoots0
    contentRoots:ContentRootsBase;
//     let startTime = 0
    startTime = 0;
//     let mutable endTime = 0
    endTime = 0;

    constructor(contentRoots0:ContentRootsBase) {
//     let contentRoots = contentRoots0
        this.contentRoots = contentRoots0;
//         let notchEnds = contentRoots.notchTimeIntervals.endPoints
        const notchEnds = this.contentRoots.notchTimeIntervals.endPoints;
//         endTime <- notchEnds.[notchEnds.lastIndex]
        this.endTime = notchEnds[notchEnds.length - 1];
        makeObservable(this);
    }

//     (* @computedKeepAlive *)
//     let contentSpan():ContentSpan =
    @computed( { keepAlive: true} )
    get contentSpan():ContentSpan {
//         let wordElements = contentRoots.words0.elements
        const wordElements = this.contentRoots.words0.elements;
//         let originalWords:string [] = [|for el in wordElements -> el?text|]
        const originalWords:string [] = wordElements.map((el) => el["text"]);
//         let normalizedWords = strongNormalizeWordArray(originalWords)
        const normalizedWords = strongNormalizeWordArray(originalWords);
//         let globalPositions = [|for i in 0..normalizedWords.lastIndex -> i|]
        const globalPositions = [...normalizedWords.keys()];

//         {
//             data={
//                 word=normalizedWords
//                 globalPosition=globalPositions
//                 originalWord=originalWords
//             }
//             startTime=startTime
//             endTime=endTime
//         }
        return new ContentSpan( {
//                 word=normalizedWords
            word:normalizedWords,
//                 globalPosition=globalPositions
            globalPosition:globalPositions,
//                 originalWord=originalWords
            originalWord:originalWords,
            },
        this.startTime,
        this.endTime,
        );
    }

//     (* @computedKeepAlive *)
//     let cuesSpan():CuesSpan =
    get cuesSpan():CuesSpan {
//         let words = contentRoots.words0
        const words = this.contentRoots.words0;
//         let cues = contentRoots.chaatInputCues
        const cues = this.contentRoots.chaatInputCues;
//         let cueTimestamps = [|for cue in cues -> cue.timestamp|]
        const cueTimestamps = cues.map((cue) => cue.timestamp);
//         let cuePositions = [|for cue in cues -> words.getIndex(cue.wordId)|]
        const cuePositions = cues.map((cue) => words.getIndex(cue.wordId));
//         {
//             data = {
//                 globalPosition=cuePositions
//                 timestamp=cueTimestamps
//             }
//             startTime=startTime
//             endTime=endTime
//         }
        return new CuesSpan( {
//                 globalPosition=cuePositions
                globalPosition:cuePositions,
//                 timestamp=cueTimestamps
                timestamp:cueTimestamps,
                },
            this.startTime,
            this.endTime,
       );
    }

//     (* @computedKeepAlive *)
//     let audioRegionsSpan():AudioRegionsSpan =
    @computed( { keepAlive: true} )
    get audioRegionsSpan():AudioRegionsSpan {
//         let regionIntervals = contentRoots.nonVoiceAudioRegionIntervals
        const regionIntervals = this.contentRoots.nonVoiceAudioRegionIntervals;
//         let regionStarts = regionIntervals.startPoints
        const regionStarts = regionIntervals.startPoints;
//         let regionEnds = regionIntervals.endPoints
        const regionEnds = regionIntervals.endPoints;
//         let silenceIntervals = contentRoots.silenceTimeIntervals
        const silenceIntervals = this.contentRoots.silenceTimeIntervals;
//         let silenceStarts = silenceIntervals.startPoints
        const silenceStarts = silenceIntervals.startPoints;
//         let silenceEnds = silenceIntervals.endPoints
        const silenceEnds = silenceIntervals.endPoints;
//         let mergedStarts, mergedEnds = unionIntervals(silenceStarts, silenceEnds, regionStarts, regionEnds)
        const [mergedStarts, mergedEnds] = unionIntervals(silenceStarts, silenceEnds, regionStarts, regionEnds);
//         let timestamps = startsAndEndsToInterleaved(mergedStarts, mergedEnds, false, 0)
        const timestamps = startsAndEndsToInterleaved(mergedStarts, mergedEnds, false, 0)
        // TODO can this be different from mergedStarts/Ends??
//         let noVoiceStarts = [|for i in 0..2..timestamps.lastIndex -> timestamps.[i]|]
        const noVoiceStarts:number [] = [];
        for (let i = 0; i < timestamps.length; i=i+2) {
            noVoiceStarts.push(timestamps[i]);
        }
//         let noVoiceEnds = [|for i in 1..2..timestamps.lastIndex -> timestamps.[i]|]
        const noVoiceEnds:number [] = [];
        for (let i = 1; i < timestamps.length; i=i+2) {
            noVoiceEnds.push(timestamps[i]);
        }

//         {
//             data = {
//                 timestamp=timestamps
//                 silenceStartTime=noVoiceStarts
//                 silenceEndTime=noVoiceEnds
//             }
//             startTime=startTime
//             endTime=endTime
//         }
        return new AudioRegionsSpan( {
//                 timestamp=timestamps
                timestamp:timestamps,
//                 silenceStartTime=noVoiceStarts
                silenceStartTime:noVoiceStarts,
//                 silenceEndTime=noVoiceEnds
                silenceEndTime:noVoiceEnds,
            },
            this.startTime,
            this.endTime,
        );
    }

//     (* @computedKeepAlive *)
//     let transcriptSpan():TranscriptSpan =
    @computed( { keepAlive: true} )
    get transcriptSpan():TranscriptSpan {
//         let originalWords = contentRoots.transcriptWords // TODO this is actually not the raw transcript words need to save raw data
        const originalWords = this.contentRoots.transcriptWords; // TODO this is actually not the raw transcript words need to save raw data
//         let normalizedWords = strongNormalizeWordArray(originalWords)
        const normalizedWords = strongNormalizeWordArray(originalWords);
//         let rawTranscriptIntervals = contentRoots.transcriptWordTimeIntervals // TODO this is actually not raw need to save raw!!!
        const rawTranscriptIntervals = this.contentRoots.transcriptWordTimeIntervals; // TODO this is actually not raw need to save raw!!!
//         let rawTranscriptStarts = rawTranscriptIntervals.startPoints
        const rawTranscriptStarts = rawTranscriptIntervals.startPoints;
//         let rawTranscriptEnds = rawTranscriptIntervals.endPoints
        const rawTranscriptEnds = rawTranscriptIntervals.endPoints;

        // TODO feel this is wrong result format does not include 0 and endTime???
//         let transcriptInterleavedTimes = startsAndEndsToInterleaved(rawTranscriptStarts, rawTranscriptEnds, true, endTime)
        const transcriptInterleavedTimes = startsAndEndsToInterleaved(rawTranscriptStarts, rawTranscriptEnds, true, this.endTime);

        // TODO to duplicating existing logic applying reconcile for silence and regions in succesion, don't know if makes any difference
//         let regionIntervals = contentRoots.nonVoiceAudioRegionIntervals
        const regionIntervals = this.contentRoots.nonVoiceAudioRegionIntervals;
//         let regionStarts = regionIntervals.startPoints
        const regionStarts = regionIntervals.startPoints;
//         let regionEnds = regionIntervals.endPoints
        const regionEnds = regionIntervals.endPoints;
//         let notchIntervals = contentRoots.notchTimeIntervals
        const notchIntervals = this.contentRoots.notchTimeIntervals;
//         let notchStarts = notchIntervals.startPoints
        const notchStarts = notchIntervals.startPoints;
//         let notchEnds = notchIntervals.endPoints
        const notchEnds = notchIntervals.endPoints;

//         let conflict(idx:int) = not (idx % 2 = 0)
        const conflict = (idx:number) => !!(idx % 2);
//         reconcileAudioIntervals(transcriptInterleavedTimes, notchStarts, notchEnds, conflict)
        reconcileAudioIntervals(transcriptInterleavedTimes, notchStarts, notchEnds, conflict);
//         reconcileAudioIntervals(transcriptInterleavedTimes, regionStarts, regionEnds, conflict)
        reconcileAudioIntervals(transcriptInterleavedTimes, regionStarts, regionEnds, conflict);
//         let adjustedStarts, adjustedEnds = interleavedToStartsEnds(transcriptInterleavedTimes, true)
        const [adjustedStarts, adjustedEnds] = interleavedToStartsEnds(transcriptInterleavedTimes, true);

//         {
//             data={
//                 word=normalizedWords
//                 originalWord=originalWords
//                 startTime=adjustedStarts
//                 endTime=adjustedEnds
//             }
//             startTime=startTime
//             endTime=endTime
//         }
        return new TranscriptSpan( {
//                 word=normalizedWords
                word:normalizedWords,
//                 originalWord=originalWords
                originalWord:originalWords,
//                 startTime=adjustedStarts
                startTime:adjustedStarts,
//                 endTime=adjustedEnds
                endTime:adjustedEnds,
           },
           this.startTime,
           this.endTime,
        );
    }


//     do autoBindInterface()
//     do
//         let notchEnds = contentRoots.notchTimeIntervals.endPoints
//         endTime <- notchEnds.[notchEnds.lastIndex]
}

// let TimestamperRoots(contentRoots:IContentRootsBase):ITimestamperRoots = !< TimestamperRoots0(contentRoots)
