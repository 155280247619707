import React from "react";
import styled, { createGlobalStyle} from "styled-components";
import {ChaatTool} from "../chaat-tool";
import {Sidebar} from "../sidebar.js";
import { TracksComponent } from "../tracks.js";

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Source+Serif+Pro');
  body {
    margin:0;
    padding:0;
  }
`;

const PanesContainer = styled.div`
  padding-left: 200px;
`;

const SideBarPane = styled.div`
  left: 0;
  top: 0;
  width: 200px;
  height: 100vh;
  position: fixed;
  overflow: auto;
`;

const ScriptPane = styled.div``;

const TracksContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 200;
`;

const Layout = ({}) => {
  return (
    <>
      <GlobalStyle />
      <PanesContainer>
        <SideBarPane>
          <Sidebar />
        </SideBarPane>
        <ScriptPane>
          {/* REMOVE THIS LAYOUT HACK */}
          <div style={{ height: 200, vw: 100 }}>&nbsp;</div>
          <ChaatTool />
        </ScriptPane>
        <TracksContainer>
          <TracksComponent />
        </TracksContainer>
      </PanesContainer>
    </>
  );
};

export default Layout;
