import {makeObservable, observable} from 'mobx';
import {ChaatMutationActions} from './mutation-actions.js';
import {createAudioSource} from './chaat-audio-sources.js';
import {Auth} from './masala-lib/editorial/db/auth';
import { AlertMessages } from './masala-lib/alert-messages';
import { Navigation } from './masala-lib/navigation/timeline-navigator';
import { AudioTransport, TransportState } from './masala-lib/player/audio-transport';
import { Tracking } from './masala-lib/tracking/tracking';
import { PlayerState, StructuredPlayer } from './masala-lib/player/structured-player';
import { ChaatCueActions } from './models/cue-actions';
import { ChaatAudioMarkerActions } from './models/audio-marker-actions';
import { ChaatToolModel } from './chaat-tool-model';
import { createBus } from './masala-lib/bus';
import { ChaatAudioRegionActions } from './models/audio-region-actions';

export const auth = new Auth();
export const alertMessages = new AlertMessages();
export const mutationActions = new ChaatMutationActions();
export const navigation = new Navigation();
export const transportState = new TransportState();

export const tracking = new Tracking(() => transportState.audioPosition);
export const audioSource = createAudioSource();
export const audioTransport = new AudioTransport(transportState);

// old TODO put in param constructor instead to be consistent?
audioTransport.setAudioSource(audioSource);

// TODO hacky, when add mobx annotation support for F# records goes away
export const playerState = new PlayerState();

export const player = new StructuredPlayer(playerState, audioTransport, transportState, navigation);

export const cueActions = new ChaatCueActions();
export const audioRegionActions = new ChaatAudioRegionActions();
export const audioMarkerActions = new ChaatAudioMarkerActions();

export const chaatToolModel = new ChaatToolModel();
export const appBus = createBus({});
