// open Fable.Core.JsInterop
// open Fable.Core
// open Sorted

import { NO_INDEX } from "../basic-types";
import { Sorted } from "../sorted/sorted";

// type TrackingState = {
export class TrackingState  {
//     mutable sorted: ISorted
    sorted: Sorted = null;
//     mutable vIdx: int
    vIdx = 0;
//     mutable position: int
    position = 0;
//     mutable furthestPosition: int
    furthestPosition = 0;
//     mutable currentIsUnderIndex: int
    currentIsUnderIndex = NO_INDEX;
//     mutable lastBeforeIndex: int
    lastBeforeIndex = 0;
//     mutable lastFurthestIndex: int
    lastFurthestIndex = 0;

//     mutable isUnderOldChangeIndex: int
    isUnderOldChangeIndex = NO_INDEX;
//     mutable isUnderNewChangeIndex: int
    isUnderNewChangeIndex = NO_INDEX;
//     mutable isBeforeChangeRangeStart: int
    isBeforeChangeRangeStart = NO_INDEX;
//     mutable isBeforeChangeRangeEnd: int
    isBeforeChangeRangeEnd = NO_INDEX;
//     mutable isVisitedChangeRangeStart: int
    isVisitedChangeRangeStart = NO_INDEX;
//     mutable isVisitedChangeRangeEnd: int
    isVisitedChangeRangeEnd = NO_INDEX;
//     mutable anyChangeRecord: bool
    anyChangeRecord = false;
}

// // TODO constructor etc
// type TrackingStateFactory =
//     static member Make(?sorted0) =
//         {
//             sorted = EmptySorted
//             vIdx = 0
//             position = 0
//             furthestPosition = 0
//             currentIsUnderIndex = NO_INDEX
//             lastBeforeIndex = 0
//             lastFurthestIndex = 0
//             isUnderOldChangeIndex = NO_INDEX
//             isUnderNewChangeIndex = NO_INDEX
//             isBeforeChangeRangeStart = NO_INDEX
//             isBeforeChangeRangeEnd = NO_INDEX
//             isVisitedChangeRangeStart = NO_INDEX
//             isVisitedChangeRangeEnd = NO_INDEX
//             anyChangeRecord = false
//         }

// let refreshIntervals(state, sorted) =
export function refreshIntervals(state:TrackingState, sorted:Sorted)  {
//     state.sorted <- sorted
    state.sorted = sorted;
}

// let advanceVIdxBecauseStateChange(state) =
export function advanceVIdxBecauseStateChange(state:TrackingState) {
//     state.vIdx <- state.vIdx + 1
    state.vIdx = state.vIdx + 1;
}

// let clearChangeRecords(state) =
export function clearChangeRecords(state:TrackingState) {
//     state.isUnderOldChangeIndex <- NO_INDEX;
    state.isUnderOldChangeIndex = NO_INDEX;
//     state.isUnderNewChangeIndex <- NO_INDEX;
    state.isUnderNewChangeIndex = NO_INDEX;
//     state.isBeforeChangeRangeStart <- NO_INDEX;
    state.isBeforeChangeRangeStart = NO_INDEX;
//     state.isBeforeChangeRangeEnd <- NO_INDEX;
    state.isBeforeChangeRangeEnd = NO_INDEX;
//     state.isVisitedChangeRangeStart <- NO_INDEX;
    state.isVisitedChangeRangeStart = NO_INDEX;
//     state.isVisitedChangeRangeEnd <- NO_INDEX;
    state.isVisitedChangeRangeEnd = NO_INDEX;
//     state.anyChangeRecord <- false
    state.anyChangeRecord = false;
}

// let recordChanged(state) =
export function recordChanged(state:TrackingState) {
//     state.anyChangeRecord <- true
    state.anyChangeRecord = true;
}

// let recordIsUnderChanges(state, oldIndex, newIndex) =
export function recordIsUnderChanges(state:TrackingState, oldIndex:number, newIndex:number) {
//     state.isUnderOldChangeIndex <- oldIndex
    state.isUnderOldChangeIndex = oldIndex;
//     state.isUnderNewChangeIndex <- newIndex
    state.isUnderNewChangeIndex = newIndex;
}

// let recordIsBeforeChanges(state, starts, ends) =
export function recordIsBeforeChanges(state:TrackingState, starts:number, ends:number) {
//     state.isBeforeChangeRangeStart <- starts
    state.isBeforeChangeRangeStart = starts;
//     state.isBeforeChangeRangeEnd <- ends
    state.isBeforeChangeRangeEnd = ends;
}

// let recordIsVisitedChanges(state, starts, ends) =
export function recordIsVisitedChanges(state:TrackingState, starts:number, ends:number) {
//     state.isVisitedChangeRangeStart <- starts
    state.isVisitedChangeRangeStart = starts;
//     state.isVisitedChangeRangeEnd <- ends
    state.isVisitedChangeRangeEnd = ends;
}

// let recordChangesForNewPosition(state, position) =
export function recordChangesForNewPosition(state, position) {
//     state.position <- position
    state.position = position;
//     let sorted = state.sorted
    const sorted = state.sorted

//     if position > state.furthestPosition then
    if (position > state.furthestPosition) {
//         state.furthestPosition <- position
        state.furthestPosition = position;
    }

//     if state.currentIsUnderIndex <> NO_INDEX && sorted.doesContain(state.currentIsUnderIndex, position) then
    if (state.currentIsUnderIndex !== NO_INDEX && sorted.doesContain(state.currentIsUnderIndex, position)) {
//         DONE
        return;
    }

//     let currentIsUnderIndex = sorted.containing(position)
    const currentIsUnderIndex = sorted.containing(position);
//     let currentBeforeIndex = sorted.lastStartsBeforeOrAt(position)
    const currentBeforeIndex = sorted.lastStartsBeforeOrAt(position);

//     if currentIsUnderIndex = state.currentIsUnderIndex && currentBeforeIndex = state.lastBeforeIndex then
    if (currentIsUnderIndex === state.currentIsUnderIndex && currentBeforeIndex === state.lastBeforeIndex) {
//         DONE
        return;
    }

//     advanceVIdxBecauseStateChange(state)
    advanceVIdxBecauseStateChange(state);
//     recordChanged(state)
    recordChanged(state);

//     if currentIsUnderIndex <> state.currentIsUnderIndex then
    if (currentIsUnderIndex !== state.currentIsUnderIndex) {
//         recordIsUnderChanges(state, state.currentIsUnderIndex, currentIsUnderIndex)
        recordIsUnderChanges(state, state.currentIsUnderIndex, currentIsUnderIndex);
//         state.currentIsUnderIndex <- currentIsUnderIndex
        state.currentIsUnderIndex = currentIsUnderIndex;
    }

//     if currentBeforeIndex = state.lastBeforeIndex then
    if (currentBeforeIndex === state.lastBeforeIndex) {
//         DONE
        return;
    }

//     let startChangeIndex = (min currentBeforeIndex state.lastBeforeIndex) + 1
    let startChangeIndex = Math.min(currentBeforeIndex, state.lastBeforeIndex) + 1;
//     let endChangeIndex = max currentBeforeIndex state.lastBeforeIndex
    let endChangeIndex = Math.max(currentBeforeIndex, state.lastBeforeIndex);
//     recordIsBeforeChanges(state, startChangeIndex, endChangeIndex)
    recordIsBeforeChanges(state, startChangeIndex, endChangeIndex);

//     state.lastBeforeIndex <- currentBeforeIndex
    state.lastBeforeIndex = currentBeforeIndex;

//     if state.lastFurthestIndex > currentBeforeIndex then
    if (state.lastFurthestIndex > currentBeforeIndex) {
//         DONE
        return;
    }

//     let startChangeIndex = (min currentBeforeIndex state.lastFurthestIndex) + 1
    startChangeIndex = Math.min(currentBeforeIndex, state.lastFurthestIndex) + 1;
//     let endChangeIndex = max currentBeforeIndex state.lastFurthestIndex
    endChangeIndex = Math.max(currentBeforeIndex,state.lastFurthestIndex);
//     recordIsVisitedChanges(state, startChangeIndex, endChangeIndex)
    recordIsVisitedChanges(state, startChangeIndex, endChangeIndex);
//     state.lastFurthestIndex <- currentBeforeIndex
    state.lastFurthestIndex = currentBeforeIndex;
}

// let intervalAt(state, idx) =
export function intervalAt(state:TrackingState, idx:number) {
//     state.sorted.intervalAt(idx)
    return state.sorted.intervalAt(idx);
}

// let currentIsUnder(state) =
export function currentIsUnder(state:TrackingState) {
//     state.currentIsUnderIndex
    return state.currentIsUnderIndex;
}

// let isUnder(state, idx) =
export function isUnder(state:TrackingState, idx:number) {
//     state.sorted.doesContain(idx, state.position)
    return state.sorted.doesContain(idx, state.position);
}

// let isBefore(state, idx) =
export function isBefore(state:TrackingState, idx:number) {
//     state.sorted.doesStartBeforeOrAt(idx, state.position)
    return state.sorted.doesStartBeforeOrAt(idx, state.position);
}

// let isVisited(state, idx) =
export function isVisited(state:TrackingState, idx:number) {
//     let position = state.position
    const position = state.position;
//     if position <= state.furthestPosition then
    if (position <= state.furthestPosition) {
//         idx <= state.lastFurthestIndex
        return (idx <= state.lastFurthestIndex);
//     else
    } else {
//         let interval = intervalAt(state, idx)
        const interval = intervalAt(state, idx);
//         interval.starts <= position
        return (interval.starts <= position);
    }
}
