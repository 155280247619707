// open Fable.Core
// open JsInterop
// open TimestamperRoots
// open Timestamper
// open BasicTypes
// open ElementList
// open ChaatLoader
// open ContentRoots
// open Mobx
// open MutationActions

import { runInAction } from "mobx";
import { ContentRootsBase } from "../../content-roots/content-roots";
import { ChaatLoader } from "../../db/chaat-loader";
import { updateChaatOutputs } from "../../db/mutation-actions";
import { Timestamper } from "./core/timestamper.fs.js";
import { TimestamperRoots } from "./timestamper-roots";


// let runTimestampingWithContentRoots(roots:IContentRootsBase):JS.Promise<bool> =
export async function runTimestampingWithContentRoots(roots:ContentRootsBase) {
//     promise {

//         let timestamperRoots = TimestamperRoots(roots)
    const timestamperRoots = new TimestamperRoots(roots);
//         let timestamper = Timestamper()
    const timestamper = Timestamper();
//         timestamper.contentSpan <- timestamperRoots.contentSpan
    timestamper.contentSpan = timestamperRoots.contentSpan;
//         timestamper.transcriptSpan <- timestamperRoots.transcriptSpan
    timestamper.transcriptSpan = timestamperRoots.transcriptSpan;
//         timestamper.cuesSpan <- timestamperRoots.cuesSpan
    timestamper.cuesSpan = timestamperRoots.cuesSpan;
//         timestamper.audioRegionsSpan <- timestamperRoots.audioRegionsSpan
    timestamper.audioRegionsSpan = timestamperRoots.audioRegionsSpan;
//         timestamper.startTime <- timestamperRoots.startTime
    timestamper.startTime = timestamperRoots.startTime;
//         timestamper.endTime <- timestamperRoots.endTime
    timestamper.endTime = timestamperRoots.endTime;
//         timestamper.language <- roots.audioLanguage
    timestamper.language = roots.audioLanguage;

//         timestamper.run()
    timestamper.run();
//         let result = timestamper.getResult()
    const result = timestamper.getResult();
    // TODO expose these as typed values on ContentRoots?
//         let scriptTimestamp:obj = roots.verbatimDoc?timestamp
    const scriptTimestamp:any = roots.verbatimDoc.timestamp;
//         let cuesTimestamp:obj = roots.cuesDoc?timestamp
    const cuesTimestamp:any = roots.cuesDoc.timestamp;
//         let regionsTimestamp:obj = roots.audioRegionsDoc?timestamp
    const regionsTimestamp:any = roots.audioRegionsDoc.timestamp;

    // TODO update transcript time intervals if changed
//         return! updateChaatOutputs(roots.episodeKey, result, scriptTimestamp, cuesTimestamp, regionsTimestamp, Null, Null)
    await updateChaatOutputs(roots.episodeKey, result, scriptTimestamp, cuesTimestamp, regionsTimestamp, null, null)
//     }
}

// let runTimestamping(episodeKey: string):JS.Promise<bool> =
export async function runTimestamping(episodeKey: string) {
//     let loader = ChaatLoader()
    const loader = new ChaatLoader();
//     loader.loadEpisode(episodeKey, false)
    loader.loadEpisode(episodeKey, false);
//     let loaded = loader.promiseWhenComplete()
    const loaded = loader.promiseWhenComplete();

//     promise {
//         let! doneLoading = loaded
    const doneLoading = await loaded;
//         let roots = ContentRootsBase()
    const roots = new ContentRootsBase();
//         runInAction( fun () ->
    runInAction( () => {
//             roots.episodeKey <- episodeKey
        roots.episodeKey = episodeKey;
//             // roots.sourceLanguageCode <- "es-US" // TODO for now this is hardwired in the roots base
//             loader.docSet.copyTo(roots)
        loader.docSet.copyTo(roots);
//         )
    });
//         let! result = runTimestampingWithContentRoots(roots)
    const result = await runTimestampingWithContentRoots(roots);
//         return result
    return result;
//     }
}
