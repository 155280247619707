// open Fable.Core
// open Fable.Core.JsInterop
// open BasicTypes
// open ChaatLoader
// open Mobx
// open Bus
// open Singletons
// open ContentRoots
// open ChaatContentRoots
// open JSBoilerplate
// open MutationActions
// open StructuredPlayer
// open AudioTransport
// open MultiAudioSource
// open Tracking
// open TimelineNavigator
// open CueActions
// open AudioRegionActions
// open AudioMarkerActions
// open ChaatTimestamper
// open Auth
// open AlertMessages

import { reaction, runInAction } from "mobx";
import { ChaatToolModel } from "./chaat-tool-model";
import { ChaatContentRoots } from "./content-roots";
import { AlertMessages } from "./masala-lib/alert-messages";
import { Bus } from "./masala-lib/bus";
import { ContentRootsBase } from "./masala-lib/editorial/content-roots/content-roots";
import { ChaatLoader } from "./masala-lib/editorial/db/chaat-loader";
import { MutationActions } from "./masala-lib/editorial/db/mutation-actions";
import { AudioMarkerActions } from "./masala-lib/editorial/models/actions/audio-marker-actions";
import { AudioRegionActions } from "./masala-lib/editorial/models/actions/audio-region-actions";
import { CueActions } from "./masala-lib/editorial/models/actions/cue-actions";
import { Navigation } from "./masala-lib/navigation/timeline-navigator";
import { AudioTransport, TransportState } from "./masala-lib/player/audio-transport";
import { MultiAudioSource } from "./masala-lib/player/multi-audio-source";
import { PlayerState, StructuredPlayer } from "./masala-lib/player/structured-player";
import { Tracking } from "./masala-lib/tracking/tracking";

import { Auth } from "./masala-lib/editorial/db/auth";
import * as singletons from "./singletons";
import { runTimestampingWithContentRoots } from "./masala-lib/editorial/chaat/timestamper/chaat-timestamper";

// [<ImportAll("./singletons.js")>]
// let singletons:obj = jsNative

// // TODO move to some module in masala lib??
// let notie:obj = import "default" "notie"
import notie from "notie";

// let chaatToolModel:IChaatToolModel = singletons?chaatToolModel
export const chaatToolModel:ChaatToolModel = singletons.chaatToolModel;

// // TODO F# interface for MultiAudioSource
// let audioSource:IMultiAudioSource = singletons?audioSource
export const audioSource:MultiAudioSource = singletons.audioSource; // TODO


// let transportState:TransportState = singletons?transportState
export const transportState:TransportState = singletons.transportState; // TODO
// let audioTransport:IAudioTransport = singletons?audioTransport
export const audioTransport:AudioTransport = singletons.audioTransport;  // TODO
// let playerState:PlayerState = singletons?playerState
export const playerState:PlayerState = singletons.playerState; // TODO
// let navigation:INavigation = singletons?navigation
export const navigation:Navigation = singletons.navigation; // TODO
// let player:IStructuredPlayer = singletons?player
export const player:StructuredPlayer = singletons.player; // TODO
// let tracking:ITracking = singletons?tracking
export const tracking:Tracking = singletons.tracking; // TODO
// let appBus:IBus = singletons?appBus // TODO
export const appBus:Bus = singletons.appBus; // TODO
// let mutationActions:IMutationActions =  singletons?mutationActions
export const mutationActions:MutationActions = singletons.mutationActions; //TODO
// let cueActions:ICueActions = singletons?cueActions
export const cueActions:CueActions = singletons.cueActions; // TODO
// let audioRegionActions:IAudioRegionActions = singletons?audioRegionActions
export const audioRegionActions:AudioRegionActions = singletons.audioRegionActions; // TODO
// let audioMarkerActions:IAudioMarkerActions = singletons?audioMarkerActions
export const audioMarkerActions:AudioMarkerActions = singletons.audioMarkerActions; // TODO

// let alertMessages:IAlertMessages = !< singletons?alertMessages
export const alertMessages:AlertMessages = singletons.alertMessages; // TODO
// let auth:IAuth = !< singletons?auth // TODO
export const auth:Auth = singletons.auth; // TODO

// let contentRoots = ChaatContentRoots()
export const contentRoots = new ChaatContentRoots();

chaatToolModel.init();

// rename AppRoot/appRoot to episodeLoader, reflects actual purpose?
// type AppRoot0() =
export class AppRoot {
//     let mutable episodeKey = ""
    episodeKey = "";
//     let loader = ChaatLoader()
    loader = new ChaatLoader();
//     let disposers: (unit -> unit) [] = [||]
    disposers: (() => void) [] = [];

    constructor() {
//     do
//         disposers.append(reaction((fun () -> !< loader.getStateVersion()), (fun () -> contentUpdated()), {||}))
        this.disposers.push(reaction(() => this.loader.getStateVersion(), () => this.contentUpdated()));
    }

//     let loadEpisode(episodeKey) =
    loadEpisode(episodeKey) {
//         loader.loadEpisode(episodeKey, true)
        this.loader.loadEpisode(episodeKey, true);
    }

//     let runTimestamping() =
    runTimestamping() {
//         let tmpContentRoots = ContentRootsBase()
        const tmpContentRoots = new ContentRootsBase();
//         tmpContentRoots.episodeKey <- loader.key
        tmpContentRoots.episodeKey = this.loader.key;
//         loader.docSet.copyTo(tmpContentRoots)
        this.loader.docSet.copyTo(tmpContentRoots);
        runTimestampingWithContentRoots(tmpContentRoots) // TODO
    }

//     let contentUpdated() =
    contentUpdated() {
//         let status = loader.getStatus()
        const status = this.loader.getStatus();
//         if status = "COMPLETE" then
        if (status === "COMPLETE") {
//             JS.console.log("LOADED")
            console.log("LOADED");
//             let newEpisode = episodeKey <> loader.key
            const newEpisode = this.episodeKey !== this.loader.key;
//             episodeKey <- loader.key
            this.episodeKey = this.loader.key;
//             runInAction( fun () ->
            runInAction( () => {
//                 contentRoots.episodeKey <- episodeKey
                contentRoots.episodeKey = this.episodeKey;
//                 // contentRoots.translationLanguage <- "english" // TODO also is this even used in Chaat?
//                 loader.docSet.copyTo(contentRoots)
                this.loader.docSet.copyTo(contentRoots);
//                 navigation.addNavigator(contentRoots.segmentNavigator)
                navigation.addNavigator(contentRoots.segmentNavigator);
//                 navigation.addNavigator(contentRoots.notchNavigator)
                navigation.addNavigator(contentRoots.notchNavigator);
//                 tracking.setElements(contentRoots.playableElements)
                tracking.setElements(contentRoots.playableElements);
//                 player.setElements(contentRoots.playableElements)
                player.setElements(contentRoots.playableElements);
//                 chaatToolModel.updateFromContentRoots(contentRoots)
                chaatToolModel.updateFromContentRoots(contentRoots);
            // configure audioSource if episodeKey changed? TODO do another way?
//                 if newEpisode then
                if (newEpisode) {
//                     audioSource.setAudioSourceDefinitions(episodeKey, contentRoots.audioUrls)
                    audioSource.setAudioSourceDefinitions(this.episodeKey, contentRoots.audioUrls);
                }
//             )
            });
//             mutationActions.setEpisodeKey(episodeKey)
            mutationActions.setEpisodeKey(this.episodeKey);
//             ()
        }
//         elif status = "OBSOLETE_TIMESTAMP_DATA" then
        else if (status === "OBSOLETE_TIMESTAMP_DATA") {
//             if mutationActions.chaatInputRecentLocalModification then
            if (mutationActions.chaatInputRecentLocalModification) {
//                 !< runTimestamping()
                this.runTimestamping();
            }
        }
//         elif status = "VERY_OBSOLETE_TIMESTAMP_DATA" then
        else if (status === "VERY_OBSOLETE_TIMESTAMP_DATA") {
//             !< runTimestamping()
            this.runTimestamping();
        }
//         elif status = "TRANSCRIPTION_NOT_FINISHED" then
        else if (status === "TRANSCRIPTION_NOT_FINISHED") {
//             // TODO somehow show notification again in a minute??
            notie.alert({text:"Transcription not finished"});
        }
    }


//     do
//         disposers.append(reaction((fun () -> !< loader.getStateVersion()), (fun () -> contentUpdated()), {||}))

//     do autoBindInterface()
}

// let AppRoot():IAppRoot = !< AppRoot0()

// let appRoot = AppRoot() // TODO could put appRoot creation in singletons too??
export const appRoot = new AppRoot(); // TODO could put appRoot creation in singletons too??
