import {Element, ElementId, ElementKind, getKindFromId, IdRange, IndexMapping, IndexRange, isNull, isNumber, notNil, NO_INDEX, xrange} from '../basic-types';
import { Interval, Sorted } from '../sorted/sorted';
import { EKinds } from './element-kinds';

// open BasicTypes
// open Sorted
// open Fable.Core
// open Fable.Core.JsInterop
// open Fable.Core.DynamicExtensions
// open JSBoilerplate
// open IndexMapping
// open ElementTypes

// type IElementList =
//     abstract elements: IElement []
//     abstract episodeKey: string
//     abstract domain: IElementList
//     abstract words: IElementList
//     abstract wordsIndexMapping: IndexMapping
//     abstract getIndex: ElementId -> int
//     abstract getId: int -> ElementId
//     abstract idToIndexMap: IdToIndex
//     abstract getElement: ElementId -> IElement
//     abstract hasElement: ElementId -> bool
//     abstract wordIntervals: ISorted // TODO with set?
//     abstract timeIntervals: ISorted // TODO with set?
//     abstract idRangeToIndexRange: IdRange -> IndexRange
//     abstract indexRangeToIdRange: IndexRange -> IdRange
//     abstract stepId: ElementId * ?useWordAddresses:bool * int -> ElementId
//     abstract nextId: ElementId * ?useWordAddreses:bool -> ElementId
//     abstract prevId: ElementId * ?useWordAddresses:bool -> ElementId
//     abstract rangeAsSeq: IndexRange -> seq<int>
//     abstract idRangeAsIdSeq: IdRange -> seq<ElementId>
//     abstract idRangeAsElementSeq: IdRange -> seq<IElement>
//     abstract findStep:ElementId * (IElement -> bool) * int -> IElement
//     abstract findNext: ElementId * (IElement -> bool) -> IElement
//     abstract findPrevious: ElementId * (IElement -> bool) -> IElement
//     abstract filter: (IElement -> bool) -> IElementList
//     abstract fromIds: ElementId [] -> IElementList
//     abstract fromIndexes: int [] -> IElementList
//     abstract map: (IElement -> IElement) -> IElementList
//     abstract getKindSubList: ElementKind -> IElementList
//     abstract getKindsSubListsAsArray: ElementKind [] -> IElementList []
//     abstract getKindsSubLists: ElementKind [] -> obj
//     abstract wordAddress: ElementId -> int
//     abstract endWordAddress: ElementId -> int
//     abstract getWordInterval: ElementId -> Interval
//     abstract getElementsIntersectWordIdRange: IdRange -> IElement []
//     abstract hasElementsIntersectWordIdRange: IdRange -> bool
//     abstract time: ElementId -> int
//     abstract endTime: ElementId -> int
//     abstract getTimeInterval: ElementId -> Interval
//     abstract getElementContainingWordAddress: int -> IElement
//     abstract getElementContainingWordId: ElementId -> IElement
//     abstract getElementContainingTime: int -> IElement // TODO consistency with above on return type
//     abstract getDomainIndex: ElementId -> int
//     abstract addKindsSubLists: obj -> unit
//     abstract joinWithIdMap: string * obj * obj -> IElementList
//     abstract difference: IElementList -> IElementList
//     abstract remapContentDimensionedArray: obj [] * IElementList -> obj []
//     // TODO search for nearest next/prev within domain
//     // get contained Kinds restriction, known shape flags = logical AND of shapes per contained Kinds


// type ElementList0(elements0:IElement [], episodeKey0:string, domain0: IElementList,
export class ElementList {
    episodeKey: string;
    elements: Element[];
    domain: ElementList;
    words: ElementList;
    wordsIndexMapping: IndexMapping;
    kindsSublists: Map<string, ElementList>;
    idToIndexF: ((id:ElementId) => number) | null;
    idToIndexMap0: any;
    wordIntervals0: Sorted | null;
    timeIntervals0: Sorted | null;

    constructor(elements0:Element [], episodeKey0:string, domain0: ElementList, words0:ElementList,
            wordsIndexMapping0:IndexMapping, idToIndex0:any, idToIndexF0:((id:ElementId) => number)) {
        this.episodeKey = episodeKey0;
        this.elements = elements0;
        this.domain = domain0;
        this.words = words0;
        this.wordsIndexMapping = wordsIndexMapping0;
        this.kindsSublists = new Map();
        this.idToIndexF = idToIndexF0;
        this.idToIndexMap0 = idToIndex0;
        this.wordIntervals0 = null;
        this.timeIntervals0 = null;
    }

    get idToIndexMap() {
        if (this.idToIndexMap0) {
            return this.idToIndexMap0;
        } else {
            const result = {};
            for (const [i, element] of this.elements.entries()) {
                result[element.id] = i;
            }
            this.idToIndexMap0 = result;
            return this.idToIndexMap0;
        }
    }

    getIndex(id:ElementId) {
        if (this.idToIndexF) {
            return this.idToIndexF(id);
        } else {
            const result = this.idToIndexMap[id];
            if (isNumber(result)) {
                return <number>result;
            } else {
                return NO_INDEX;
            }
        }
    }

    getId(index:number) {
        return this.elements[index].id;
    }

    getElement(id:ElementId) {
        const index = this.getIndex(id);
        if (index !== NO_INDEX && isNumber(index)) {
            return this.elements[this.getIndex(id)];
        } else {
            return null;
        }
    }

    hasElement(id:ElementId) {
        return !!this.getElement(id);
    }

    get wordIntervals() {
        if (!this.wordIntervals0) {
            const startPoints = [];
            const endPoints = [];
            let hasEndPoints = false;
            if (this.elements.length > 0) {
                if (notNil(this.elements[0].endWordAddress)) {
                    hasEndPoints = true;
                }
            }
            for (const elem of this.elements) {
                // TODO handle case of only startWord
                startPoints.push(elem.wordAddress);
                if (hasEndPoints) {
                    endPoints.push(elem.endWordAddress);
                }
            }
            if (hasEndPoints) {
                this.wordIntervals0 = new Sorted(startPoints, endPoints);
            } else {
                if (startPoints.length > 0) {
                    this.wordIntervals0 = new Sorted(startPoints, null);
                } else {
                    this.wordIntervals0 = new Sorted(startPoints, []);
                }
            }
        }
        return this.wordIntervals0;
    }

    get timeIntervals() {
        if (isNull(this.timeIntervals0)) {
            const startPoints = [];
            const endPoints = [];
            for (const elem of this.elements) {
                startPoints.push(elem.time);
                endPoints.push(elem.endTime);
            }
            this.timeIntervals0 = new Sorted(startPoints, endPoints);
        }
        return this.timeIntervals0;
    }

    idRangeToIndexRange(range:IdRange): IndexRange {
        return { starts: this.getIndex(range.starts), ends: this.getIndex(range.ends)};
    }

    indexRangeToIdRange(range:IndexRange):IdRange {
        return {starts: this.getId(range.starts), ends: this.getId(range.ends)};
    }

//     let stepId(id:ElementId, useWordAddresses:bool, direction:int):ElementId =
    stepId(id:ElementId, useWordAddresses = false, direction:number):ElementId {

        const isIdRange = (el) => false // TODO really implement, hmm is actually needed here?

        // TODO needs to consider a multiple current positional states? - focused line, focused element, selected word
        // TODO given that is the cursoring algorithm specific to the state modeling of specific app, belong on app layer?
        // or could consolidate input output state model to single dimension (one element)?
//         let index: int = getIndex(id)
        const index = this.getIndex(id);
//         if index <> NO_INDEX then
        if (index !== NO_INDEX) {
//             let testIndex = index + direction
            const testIndex = index + direction;
//             if testIndex >= 0 && testIndex <= elements.lastIndex then
            if (testIndex >= 0 && testIndex < this.elements.length) {
//                 elements.[index + direction].id
                return this.elements[index + direction].id;
//             else
            } else {
//                 Null
                return null;
            }
        }
//         elif domain.hasElement(id) then
        else if (this.domain.hasElement(id)) {
//             let nextElement = domain.findStep(id, (fun (el:IElement) -> hasElement(el.id)), direction)
            const nextElement = this.domain.findStep(id, (el:Element) => this.hasElement(el.id), direction);
//             if !!nextElement then nextElement.id else Null
            return (nextElement)?nextElement.id:null;
        }
//         elif useWordAddresses then
        else if (useWordAddresses) {
//             if getKindFromId(id) = WORD then // TODO or word id test here????
            if (getKindFromId(id) === EKinds.WORD) { // TODO or word id test here????
//                 let index = words.getIndex(id)
                const index = this.words.getIndex(id);
//                 let wordIntervals = self.wordIntervals
                const wordIntervals = this.wordIntervals;
//                 let elementIndex = if direction = 1 then wordIntervals.firstStartsAfter(index) else wordIntervals.lastEndsBeforeOrAt(index)
                const elementIndex = (direction = 1) ?this.wordIntervals.firstStartsAfter(index) : this.wordIntervals.lastEndsBeforeOrAt(index);
                // TODO check NO_INDEX
//                 if elementIndex <> NO_INDEX then
                if (elementIndex !== NO_INDEX) {
//                     elements.[elementIndex].id
                    return this.elements[elementIndex].id;
//                 else
                } else {
//                     Null
                    return null;
                }
//             else
            } else {
//                 Null
                return null;
            }
//         else
        } else {
//             Null
            return null;
        }
    }

//     let nextId(id, useWordAddresses):ElementId =
    nextId(id:ElementId, useWordAddresses = false):ElementId {
//         stepId(id, useWordAddresses, 1)
        return this.stepId(id, useWordAddresses, 1);
    }

//     let prevId(id, useWordAddresses) =
    prevId(id, useWordAddresses = false) {
//         stepId(id, useWordAddresses, -1)
        return this.stepId(id, useWordAddresses, -1)
    }

//     let rangeAsSeq(range: IndexRange) =
    // TODO move this somewhere else because does not use local state?
    rangeAsSeq(range: IndexRange) {
//         {range.starts .. range.ends}
        return xrange(range.starts, range.ends);
    }

//     let idRangeAsIdSeq(range) =
    idRangeAsIds(range:IdRange) {
//         let indexSeq = rangeAsSeq(idRangeToIndexRange(range))
        const indexSeq = this.rangeAsSeq(this.idRangeToIndexRange(range))
//         seq { for index in indexSeq -> elements.[index].id }
        return Array.from(indexSeq, i => this.elements[i].id);
    }

    rangeAsElements(range:IndexRange) {
        // TODO optimize?
        const indexSeq = this.rangeAsSeq(range);
        return Array.from(indexSeq, i => this.elements[i]);
    }

//     let idRangeAsElementSeq(range) =
    idRangeAsElements(range:IdRange) {
//         let indexSeq = rangeAsSeq(idRangeToIndexRange(range))
        const indexSeq = this.rangeAsSeq(this.idRangeToIndexRange(range))
//         seq { for index in indexSeq -> elements.[index] }
        return Array.from(indexSeq, i => this.elements[i]);
    }

    findNext(id:ElementId, f: (el:Element) => boolean): Element { // TODO if id is null start at end?
        const start = this.getIndex(id);
        const len = this.elements.length;
        for (let i = start; i < len; i++ ) {
            const element = this.elements[i];
            if (f(element)) {
                return element;
            }
        }
        return null;
    }

    findPrevious(id:ElementId, f: (el:Element) => boolean): Element { // TODO if id is null start at end?
        const start = this.getIndex(id);
        for (let i = start; i >= 0; i--) {
            const element = this.elements[i];
            if (f(element)) {
                return element;
            }
        }
        return null;
    }

    findStep(id:ElementId, f:(el:Element) => boolean, direction:number) {
        // TODO rethink this
        if (direction === -1) {
            return this.findPrevious(id, f);
        } else {
            return this.findNext(id, f);
        }
    }

//     let filter(f:IElement -> bool):IElementList =
    filter(f:(el:Element) => boolean):ElementList {
//         let filtered:IElement [] = [||]
        const filtered:Element [] = [];
//         let filterDomain = if !!domain then domain else self
        const filterDomain = this.domain ?? this;
//         for element in elements do
        for (const element of this.elements) {
//             if f element then
            if (f(element)) {
                filtered.push(element);
            }
        }
//         !< ElementList0(filtered, episodeKey, filterDomain, words, wordsIndexMapping, Null, Null)
        return new ElementList(filtered, this.episodeKey, filterDomain, this.words, this.wordsIndexMapping, null, null )
    }

//     let fromIds(ids:ElementId []):IElementList =
    fromIds(ids:ElementId []):ElementList {
//         let filtered:IElement [] = [||]
        const filtered:Element [] = [];
//         for id in ids do
        for( const id of ids) {
//             let element = getElement(id)
            const element = this.getElement(id);
//             if !!element then
            if (element) {
//                 filtered.append(element)
                filtered.push(element);
            }
        }
//         !< ElementList0(filtered, episodeKey, domain, words, wordsIndexMapping, Null, Null)
        return new ElementList(filtered, this.episodeKey, this.domain, this.words, this.wordsIndexMapping, null, null )
    }


//     let fromIndexes(indexes:int []):IElementList =
    fromIndexes(indexes:number []):ElementList {
//         let filtered:IElement [] = [||]
        const filtered:Element [] = [];
//         for index in indexes do
        for(const index of indexes) {
//             let element = elements.[index]
            const element = this.elements[index];
//             if !!element then
            if (element) {
//                 filtered.append(element)
                filtered.push(element);
            }
        }
//         !< ElementList0(filtered, episodeKey, domain, words, wordsIndexMapping, Null, Null)
        return new ElementList(filtered, this.episodeKey, this.domain, this.words, this.wordsIndexMapping, null, null )
    }

//     let getKindSubList(kind):IElementList =
    getKindSubList(kind:ElementKind):ElementList {
        // TODO should ElementList have known kind restrictions then if
        // only one kind inside and kind param is same return self?
//         let result = kindsSublists.get(kind)
        const result = this.kindsSublists.get(kind);
//         if !!result then
        if (result) {
//             result
            return result;
//         else
        } else {
//             let filtered = [||]
            const filtered = [];
            // TODO decide if should initially implement as list comprehension and switch to imperative is there are performance issues?
//             for element in elements do
            for (const element of this.elements) {
//                 if element.kind = kind then
                if (element.kind === kind) {
//                     filtered.append(element)
                    filtered.push(element);
                }
            }
//             let list:IElementList = !< ElementList0(filtered, episodeKey, domain, words, wordsIndexMapping, Null, Null)
            const list:ElementList = new ElementList(filtered, this.episodeKey, this.domain || this, this.words, this.wordsIndexMapping, null, null);
//             kindsSublists.set(kind, list)
            this.kindsSublists.set(kind, list);
//             list
            return list;
        }
    }

//     let getKindsSubListsAsArray(kinds:string []) =
    getKindsSubListsAsArray(kinds:string []) {
//         [| for kind in kinds -> getKindSubList(kind)|]
        return kinds.map(kind => this.getKindSubList(kind))
    }

//     let getKindsSubLists(kinds:string []) =
    getKindsSubLists(kinds:string []) {
//         let result = obj()
        const result = {};
//         for kind in kinds do
        for(const kind of kinds) {
//             result.[kind] <- getKindSubList(kind)
            result[kind] = this.getKindSubList(kind);
        }
//         result
        return result;
    }

//     let wordAddress(id:ElementId):int =
    wordAddress(id:ElementId):number {
//         getElement(id).wordAddress
        return this.getElement(id).wordAddress;
    }

//     let endWordAddress(id:ElementId):int =
    endWordAddress(id:ElementId):number {
//         getElement(id).endWordAddress
        return this.getElement(id).endWordAddress;
    }

//     let getWordInterval(id):Interval =
    getWordInterval(id):Interval {
//         let element = getElement(id)
        const element = this.getElement(id);
//         {starts = element.wordAddress; ends = element.endWordAddress}
        return {starts:element.wordAddress, ends:element.endWordAddress};
    }

//     let getElementsIntersectWordIdRange(wordRange:IdRange) =
    getElementsIntersectWordIdRange(wordRange:IdRange) {
//         let wordIntervals = self.wordIntervals
        const wordIntervals = this.wordIntervals;
//         let wordIndexRange = words.idRangeToIndexRange(wordRange)
        const wordIndexRange = this.words.idRangeToIndexRange(wordRange);
//         let elementRange = wordIntervals.rangeIntersecting(wordIndexRange.starts, wordIndexRange.ends)
        const elementRange = wordIntervals.rangeIntersecting(wordIndexRange.starts, wordIndexRange.ends);
//         if !!elementRange then
        if (elementRange) {
//             [|for i in elementRange.starts..elementRange.ends -> elements.[i]|]
            return this.rangeAsElements(elementRange);
//         else
        }  else {
//             Null // TODO or [||]?
            return null; // TODO or []?
        }
    }

//     let hasElementsIntersectWordIdRange(wordRange:IdRange) =
    hasElementsIntersectWordIdRange(wordRange:IdRange) {
//         let wordIntervals = self.wordIntervals
        const wordIntervals = this.wordIntervals;
//         let wordIndexRange = words.idRangeToIndexRange(wordRange)
        const wordIndexRange = this.words.idRangeToIndexRange(wordRange);
//         notNil(wordIntervals.rangeIntersecting(wordIndexRange.starts, wordIndexRange.ends))
        return notNil(wordIntervals.rangeIntersecting(wordIndexRange.starts, wordIndexRange.ends));
    }

//     let time(id:ElementId) =
    time(id:ElementId) {
//         getElement(id).time
        return this.getElement(id).time
    }

//     let endTime(id:ElementId) =
    endTime(id:ElementId) {
//         getElement(id).endTime
        return this.getElement(id).endTime
    }

//     let getTimeInterval(id:ElementId): Interval =
    getTimeInterval(id:ElementId): Interval {
//         let element = getElement(id)
        const element = this.getElement(id);
//         {starts = element.time; ends = element.endTime}
        return {starts:element.time, ends:element.endTime};
    }

//     let getElementContainingWordAddress(address:int) =
    getElementContainingWordAddress(address:number) {
//         let wordIntervals = self.wordIntervals
        const wordIntervals = this.wordIntervals;
//         let elementIndex = wordIntervals.containing(address)
        const elementIndex = wordIntervals.containing(address);
//         if (elementIndex <> NO_INDEX) then
        if (elementIndex !== NO_INDEX) {
//             elements.[elementIndex]
            return this.elements[elementIndex];
//         else
        } else {
//             Null
            return null;
        }
    }

//     let getElementContainingWordId(id:ElementId):IElement =
    getElementContainingWordId(id:ElementId):Element {
//         let wordIndex = words.getIndex(id)
        const wordIndex = this.words.getIndex(id);
//         getElementContainingWordAddress(wordIndex)
        return this.getElementContainingWordAddress(wordIndex);
    }

//     let getElementContainingTime(time:int):IElement =
    getElementContainingTime(time:number):Element {
//         let timeIntervals = self.timeIntervals
        const timeIntervals = this.timeIntervals;
//         let elementIndex = timeIntervals.containing(time)
        const elementIndex = timeIntervals.containing(time);
//         if (elementIndex <> NO_INDEX) then
        if (elementIndex !== NO_INDEX) {
//             elements.[elementIndex]
            return this.elements[elementIndex];
//         else
        } else {
//             Null
            return null;
        }
    }

//     let getDomainIndex(id) =
    getDomainIndex(id) {
//         domain.getIndex(id)
        return this.domain.getIndex(id);
    }

//     let addKindsSubLists(map) =
//         // TODO
//         ()

//     let joinWithIdMap(key:string, map:obj, defaults:obj): IElementList =
    joinWithIdMap(key:string, map:any, defaults:any): ElementList {
//         let mergedElements: IElement [] = [||]
        const mergedElements: Element [] = [];
//         let mutable attach = defaults
        let attach = defaults;
//         for element0 in elements do
        for (const element0 of this.elements) {
//             let element:IElement = !< JS.Object.assign(obj(), element0)
            const element:Element = {...element0};
//             attach <- defaults
            attach = defaults;
//             let toJoin = map.[element.id]
            const toJoin = map[element.id];
            // TODO incorporate defaults
//             if !!toJoin then
            if (toJoin) {
                attach = {...defaults, ...toJoin};
            }
//             element.[key] <- attach
            element[key] = attach;
//             mergedElements.append(element)
            mergedElements.push(element)
        }
//         !< ElementList0(mergedElements, episodeKey, Null, words, wordsIndexMapping, Null, Null)
        return new ElementList(mergedElements, this.episodeKey, null, this.words, this.wordsIndexMapping, null, null);
    }

//     let difference(elementList:IElementList): IElementList =
    difference(elementList:ElementList): ElementList {
//         filter((fun (element:IElement) -> not (elementList.hasElement(element.id))))
        return this.filter((element:Element) => !(elementList.hasElement(element.id)));
    }

//     let remapContentDimensionedArray(remap: obj [], newList: IElementList): obj [] =
    remapContentDimensionedArray(remap: any[], newList: ElementList): any[] {
//         if isNull(remap) then
        if (isNull(remap)) {
//             Null
            return null;
//         else
        } else {
            // const len = newList.elements.length;
//             let result = Array.replicate (len + 1) Null
            const result = new Array(newList.elements.length);
//             for i, value in (Array.indexed remap) do
            for (const [i, value] of remap.entries()) {
//                 let id = getId(i)
                const id = this.getId(i);
//                 let newIndex = newList.getIndex(id)
                const newIndex = newList.getIndex(id);
//                 result.[newIndex] <- value
                result[newIndex] = value;
            }
//             result
            return result;
        }
    }

//     // TODO search for nearest next/prev within domain
//     // get contained Kinds restriction, known shape flags = logical AND of shapes per contained Kinds
}


// let ElementList(elements0, episodeKey0, domain0, words0, wordsIndexMapping0, idToIndex0, idToIndexF0):IElementList =
//     !< ElementList0(elements0, episodeKey0, domain0, words0, wordsIndexMapping0, idToIndex0, idToIndexF0)

// let SimpleElementList0(elements0): IElementList =
function SimpleElementList0(elements0:Element[]): ElementList {
//     !< ElementList0(elements0, "", Null, Null, Null, Null, Null)
    return new ElementList(elements0, "", null, null, null, null, null);
}

// let SimpleElementList(elements0): IElementList =
export function SimpleElementList(elements0:Element []): ElementList {
//     !< ElementList0(elements0, "", Null, SimpleElementList0([||]), Null, Null, Null)
    return new ElementList(elements0, "", null, SimpleElementList0([]), null, null, null)
}

export function CreateElementList(elements0:Element [], episodeKey0:string, domain0:ElementList, words0:ElementList, wordsIndexMapping0:IndexMapping, idToIndex0:any, idToIndexF0:((id:ElementId) => number)):ElementList {
    return new ElementList(elements0, episodeKey0, domain0, words0, wordsIndexMapping0, idToIndex0, idToIndexF0);
}

export const EmptyElementList = SimpleElementList([])
