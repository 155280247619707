// open Fable.Core.JsInterop
// open Browser
// open BasicTypes
// // open EntityFuncs0

import { elementIdToDomId, isNull, notNull } from "../../basic-types";

// // TODO blurred wording between return screen placement and set placement on scroll
// type Location = NotExist = 0 |  BelowScreen = 1 | OnScreen = 2 | AboveScreen = 3
export enum Location { NotExist = 0 ,  BelowScreen = 1 , OnScreen = 2 , AboveScreen = 3};

// let getVisibilityDomId(domId, containerDomId) =
export function getVisibilityDomId(domId, containerDomId) {
//     let node = document.getElementById(domId)
    const node = document.getElementById(domId);
//     if isNull node then
    if (isNull(node)) {
//         Location.NotExist
        return Location.NotExist;
//     else
    } else {
//         let mutable top = 0.0
        let top = 0.0;
//         let mutable bottom = window.innerHeight
        let bottom = window.innerHeight;
//         if notNull containerDomId then
        if (notNull(containerDomId)) {
//             let container = document.getElementById(containerDomId)
            const container = document.getElementById(containerDomId);
//             let containerRect = container.getBoundingClientRect()
            const containerRect = container.getBoundingClientRect();
//             top <- containerRect.top
            top = containerRect.top;
//             bottom <- containerRect.bottom
            bottom = containerRect.bottom;
        }
//         let rect = node.getBoundingClientRect()
        const rect = node.getBoundingClientRect();
//         if rect.top < top then
        if (rect.top < top) {
//             Location.AboveScreen
            return Location.AboveScreen;
        }
//         elif rect.bottom > bottom then
        else if (rect.bottom > bottom) {
//             Location.BelowScreen
            return Location.BelowScreen;
//         else
        } else {
//             Location.OnScreen
            return Location.OnScreen;
        }
    }
}

// let scrollIfNotVisibleDomId(domId, placement, containerDomId) =
export function scrollIfNotVisibleDomId(domId, placement, containerDomId) {
//     let currentLocation = getVisibilityDomId(domId, containerDomId)
    const currentLocation = getVisibilityDomId(domId, containerDomId);
//     if currentLocation <> Location.OnScreen && currentLocation <> Location.NotExist then
    if (currentLocation !== Location.OnScreen && currentLocation !== Location.NotExist) {
//         let node = document.getElementById(domId)
        const node = document.getElementById(domId);
//         let options = obj()
        let options = {block:placement};
//         node?scrollIntoView(options)
        node.scrollIntoView(options);
    }
}

// let getVisibility(elementId, containerDomId) =
export function getVisibility(elementId, containerDomId) {
//     let domId = elementIdToDomId  Null elementId
    const domId = elementIdToDomId(null, elementId);
//     getVisibilityDomId(domId, containerDomId)
    return getVisibilityDomId(domId, containerDomId);
}

// let scrollIfNotVisible(elementId, placement, containerDomId) =
export function scrollIfNotVisible(elementId, placement, containerDomId) {
//     if !!elementId then
    if (elementId) {
//         let domId = elementIdToDomId Null elementId
        const domId = elementIdToDomId(null, elementId);
//         scrollIfNotVisibleDomId(domId, placement, containerDomId)
        scrollIfNotVisibleDomId(domId, placement, containerDomId);
    }
}
