// let SHIFTED_BY_DELETE_FLAG = 1 <<< 25
export const SHIFTED_BY_DELETE_FLAG = 1 << 25;
// let MASK_OUT_DELETE_FLAG = ~~~SHIFTED_BY_DELETE_FLAG
export const MASK_OUT_DELETE_FLAG = ~SHIFTED_BY_DELETE_FLAG;

// type IndexMapping = {
export type IndexMapping = {
//     mutable indexLookup: int []
    indexLookup: number [];
//     mutable contentLength: int
    contentLength: number;
// }
}

// TODO consider requiring IndexIds module prefix on calls and shortening function names

// let getIndex mapping contentId =
export function getIndex(mapping, contentId) {
//     mapping.indexLookup.[contentId] &&& MASK_OUT_DELETE_FLAG
    return mapping.indexLookup[contentId] & MASK_OUT_DELETE_FLAG;
}

// let recordInsert mapping index =
export function recordInsert(mapping, index) {
//     let indexLookup = mapping.indexLookup
    const indexLookup = mapping.indexLookup;
//     for contentId, i in (Array.indexed indexLookup) do
    for (const [contentId, i] of indexLookup.entries()) {
//         if (i &&& MASK_OUT_DELETE_FLAG) >= index then
        if ((i &MASK_OUT_DELETE_FLAG) >= index) {
//             indexLookup.[contentId] <- i + 1
            indexLookup[contentId] = i + 1;
        }
    }
//     mapping.indexLookup <- Array.append mapping.indexLookup [|index|]
    mapping.indexLookup = [...mapping.indexLookup, index];
//     mapping.contentLength <- mapping.contentLength + 1
    mapping.contentLength++;
}

// let recordInsertAtId mapping contentId =
export function recordInsertAtId(mapping, contentId) {
//     recordInsert mapping (getIndex mapping contentId)
    recordInsert(mapping, getIndex(mapping, contentId));
}

// let recordRemove mapping index =
export function recordRemove(mapping, index) {
//     let indexLookup = mapping.indexLookup
    const indexLookup = mapping.indexLookup;
//     for contentId, i in (Array.indexed indexLookup) do
    for (const [contentId, i] of indexLookup.entries()) {
//         if (i &&& MASK_OUT_DELETE_FLAG) > index then
        if ((i & MASK_OUT_DELETE_FLAG) > index) {
//             indexLookup.[contentId] <- i - 1
            indexLookup[contentId] = i - 1;
//         elif i = index then
        } else if (i === index) {
//             indexLookup.[contentId] <- index ||| SHIFTED_BY_DELETE_FLAG
            indexLookup[contentId] = index | SHIFTED_BY_DELETE_FLAG;
        }
    }
//     mapping.contentLength <- mapping.contentLength - 1
    mapping.contentLength--;
}

// let recordRemoveAtId mapping contentId =
export function recordRemoveAtId(mapping, contentId) {
//     recordRemove mapping (getIndex mapping contentId)
    recordRemove(mapping, getIndex(mapping, contentId));
}

// let recordSwapsert  mapping  index =
export function recordSwapsert(mapping, index) {
//     let indexLookup = mapping.indexLookup
    const indexLookup = mapping.indexLookup;
//     for contentId, i in (Array.indexed indexLookup) do
    for ( const [contentId, i] of indexLookup.entries()) {
//         if (i &&& MASK_OUT_DELETE_FLAG) > index then
        if ((i & MASK_OUT_DELETE_FLAG) > index) {
//             indexLookup.[contentId] <- i + 1
            indexLookup[contentId] = i + 1
        }
    }
//     mapping.indexLookup <- Array.append mapping.indexLookup [|index + 1|]
    mapping.indexLookup = [...mapping.indexLookup, index + 1];
//     mapping.contentLength <- mapping.contentLength + 1
    mapping.contentLength++;
}

// let isDeletedId mapping contentId =
export function isDeletedId(mapping, contentId) {
//     (mapping.indexLookup.[contentId] &&& SHIFTED_BY_DELETE_FLAG) <> 0
    return ((mapping.indexLookup[contentId] & SHIFTED_BY_DELETE_FLAG) !== 0);
}

// let createMapping length =
export function createMapping(length):IndexMapping {
//     let indexLookup = Array.zeroCreate (length + 1)
    const indexLookup = new Array(length + 1);
//     for contentId in 0 .. length do
    for( let contentId=0; contentId <= length; contentId++) {
//         indexLookup.[contentId] <- contentId
        indexLookup[contentId] = contentId;
    }
//     { indexLookup = indexLookup; contentLength = length }
    return { indexLookup, contentLength:length };
}

// let makeIndexToIdMapping mapping =
export function makeIndexToIdMapping(mapping) {
//     let length = mapping.contentLength
    const length = mapping.contentLength;
//     let result = Array.zeroCreate (length + 1)
    const result = new Array(length + 1);
//     for contentId, i in (Array.indexed mapping.indexLookup) do
    for (const [contentId, i] of mapping.indexLookup.entries()) {
//         if (contentId &&& SHIFTED_BY_DELETE_FLAG) = 0 then
        if ((contentId & SHIFTED_BY_DELETE_FLAG) === 0) {
//             result.[i] <- contentId
            result[i] = contentId;
        }
    }
//     result
    return result;
}

// let adaptContentDimensionedArray (oldArray: obj []) oldMapping newMapping (fillValue: obj) =
export function adaptContentDimensionedArray(oldArray: any [], oldMapping:IndexMapping, newMapping:IndexMapping, fillValue) {
//     let result = Array.replicate (newMapping.contentLength + 1) fillValue
    const result = new Array(newMapping.contentLength + 1).fill(fillValue);
//     let oldMaxId = oldMapping.indexLookup.Length - 1
    const oldMaxId = oldMapping.indexLookup.length - 1;
//     for contentId in 0 ..oldMaxId do
    for (let contentId = 0; contentId <= oldMaxId; contentId++) {
//         let oldIndex = getIndex oldMapping contentId
        const oldIndex = getIndex(oldMapping, contentId);
//         let newIndex = getIndex newMapping contentId
        const newIndex = getIndex(newMapping, contentId);
//         result.[newIndex] <- oldArray.[oldIndex]
        result[newIndex] = oldArray[oldIndex];
    }
//     result
    return result;
}

// let copyMapping mapping =
export function copyMapping(mapping) {
//     { indexLookup = Array.copy mapping.indexLookup; contentLength = mapping.contentLength }
    return { indexLookup:[...mapping.indexLookup], contentLength:mapping.contentLength };
}
