// open BasicTypes
// open CueActions
// open AppRoot

import { alertMessages, chaatToolModel, mutationActions, transportState } from "../app-root";
import { Cue, ElementId } from "../masala-lib/basic-types";
import { CueActions } from "../masala-lib/editorial/models/actions/cue-actions";

// type CueActions1() =
export class ChaatCueActions extends CueActions {
//     inherit CueActions0()

//     override __.mutationActions with get() = mutationActions
    get mutationActions() {
        return mutationActions;
    }
//     override __.alertMessages with get() = alertMessages
    get alertMessages() {
        return alertMessages;
    }
//     override __.cueInsertWordId with get() = chaatToolModel.currentCuePointWordId
    get cueInsertWordId() {
        return chaatToolModel.currentCuePointWordId;
    }
//     override __.cueInsertWordId with set(id:ElementId) = chaatToolModel.currentCuePointWordId <- id
    set cueInsertWordId(id:ElementId) {
        chaatToolModel.currentCuePointWordId = id;
    }
//     override __.currentAudioPosition with get() = transportState.audioPosition
    get currentAudioPosition() {
        return transportState.audioPosition;
    }
//     override __.existingCues with get() = !< chaatToolModel.cuesData
    get existingCues():Cue [] {
        // TODO work on typing
        return <Cue []>chaatToolModel.cuesData;
    }
//     override __.words with get() = chaatToolModel.words
    get words() {
        return chaatToolModel.words;
    }
}

// let CueActions():ICueActions= !< CueActions1()
