import _ from 'lodash';

export type ElementKind = string
export type ElementId = string | number
export type WordId = number

export function isNumber(o:any) {
    return typeof o === 'number';
}

export const NO_INDEX = -1;

export function* xrange(starts:number, ends:number) {
    for (let i = starts; i <= ends; i++ ) yield i;
}

export function isEmpty(o:any) {
    return _.isEmpty(o);
}

export function notEmpty(o:any) {
    return !_.isEmpty(o);
}
export function isNil(o:any) {
    return _.isNil(o);
}

export function notNil(o:any) {
    return !_.isNil(o);
}

export function isNull(o:any) {
    return _.isNull(o);
}

export function notNull(o:any) {
    return !_.isNull(o);
}

export type StructuralAnchor = WordId

export type IndexMapping = {
    indexLookup: number []
    contentLength: number
}

export type SpanAnchors = {
    startWordId: WordId
    endWordId: WordId
}

export type SpanExclusiveAnchors = {
    startWordId: WordId
    endWordIdExclusive: WordId
}

export type Element = {
    kind: ElementKind
    subKind: ElementKind
    id: ElementId
    content: any
    anchors: StructuralAnchor | SpanAnchors | SpanExclusiveAnchors
    author: string // editorial user who modified this version of the element
    timestamp: number // timestamp of last editorial operation on this element (JS.Date.now())
    wordAddress: number | null // TODO allow or prefer undefined for these ???
    endWordAddress: number | null
    time: number | null // element start time in millis, relative to start of master audio file
    endTime: number | null // element end time in millis
};

/* TODO
    getStructuralContent(el):string
    getWordGroupContent(el): WordGroupContent
    getSentenceAnchors(el): SpanExclusiveAnchors
    getWordGroupAnchors(el): SpanAnchors
    getStructuralAnchor(el): WordId
    getStructuralContent(el): string
*/



// TODO decide if create parametized types for JS Obj based KV


export type IndexRange = {
    starts: number
    ends: number
}

export type IdRange = {
    starts: ElementId
    ends: ElementId
}

export type ChaatInputCue = {
    wordId: ElementId
    timestamp: number
}

export type Cue = {
    wordId: ElementId
    timestamp: number
    input: boolean
    navStop: boolean
}

export type WordGroupContent = {
    note: string
    head: string
}

export type SentenceDTO = {
    id: ElementId
    anchors: SpanExclusiveAnchors
}

export type WordGroupDTO = {
    id: ElementId
    subKind: ElementKind
    anchors: SpanAnchors
    content: WordGroupContent
}

export type StructuralDTO = {
    kind: ElementKind
    id: ElementId
    anchors: WordId
    content: string
}

export type MetadataBlockDTO = {
    subKind: ElementKind
    id: ElementId
    content: string
}

export type AudioRegionDTO = {
    id: ElementId
    startTime: number
    endTime: number
}

export type AudioMarkerDTO = {
    id: ElementId
    time: number
}

export function isWordId(id: string | number) {
  return typeof id === 'number'
  // return !isNaN(id as number); // attempt to coerce back to a number and check the result
}

export function getKindFromId(id:ElementId)  {
    if (isWordId(id)) {
        return "WORD";

    } else {
        let stringId = id as string;
        return stringId.slice(0, stringId.indexOf(":"));
    }
}

export function elementIdToDomId(domScope, id: string | number): string {
    // TODO use domScope etc
    if (isNumber(id)) {
        return "WORD:" + id
    } else {
        return String(id);
    }
}

// let domIdToElementId domScope (domId:string): ElementId =
export function domIdToElementId(domScope, domId:string): ElementId {
    // TODO use domScope
    if (domId.startsWith("WORD")) {
        const wordIdStr:string = domId.slice(domId.indexOf(":") + 1);
        return Number(wordIdStr);
    } else {
        return domId;
    }
}

export interface IDocSet {
    copyTo(any)
}

export function numberProjectionSort(arr:any [], projection: (o:any) => number ) {
    arr.sort((a, b) => projection(a) - projection(b) );
}
