
export const lineViewerConfigurations = {
    CHAPTER_TITLE: {
      // prefix: '# ',
      prefix: '',
      style: 'chapter-title',
    },
    CHAPTER_SUMMARY: {
      prefix: '%% ',
      style: 'chapter-summary',
    },
    CULTURAL_NOTE: {
      prefix: '% ',
      style: 'cultural-note',
    },
    PASSAGE_HINT: {
      // prefix: '## ',
      prefix: '',
      style: 'passage-hint',
    },
    SPEAKER_LABEL: {
      prefix: '@',
      style: 'speaker-label',
    },
    SENTENCE: {
      prefix: null,
      style: 'sentence',
    },
    PARAGRAPH_BREAK: {
      prefix: '###',
      style: 'paragraph-break',
    },
    CHAPTER_COMPLETE: {
      prefix: '//! CHAPTER-COMPLETE',
      containerClassName: 'chapter-complete',
    }
  }
