// open Fable.Core.JsInterop
// open Fable.Core.DynamicExtensions
// open BasicTypes
// open System
// open Sorted
// open JSBoilerplate

import { Sorted } from "../sorted/sorted";

// type NavigationPoint = {
export class NavigationPoint {
//     navigatorKey: string
    navigatorKey: string;
//     index: int
    index: number;
//     position: int
    position: number;
// }
    constructor(navigatorKey: string, index: number, position: number) {
        this.navigatorKey = navigatorKey;
        this.index = index;
        this.position = position;
    }
}

// type EdgeEnum = Leading = 1 | Trailing = 2 | Midpoint = 3
export enum EdgeEnum {
    Leading = 1,
    Trailing = 2,
    Midpoint = 3,
}

// type ITimelineNavigator =
//     abstract getKey: unit -> string
//     abstract setPositions: ISorted -> unit
//     abstract setIntervals: ISorted * EdgeEnum option -> unit
//     abstract getPositionForIndex: int -> int
//     abstract navigationPoint: int -> NavigationPoint
//     abstract next: NavigationPoint -> NavigationPoint
//     abstract prev: NavigationPoint -> NavigationPoint
//     abstract nextClosest: int -> NavigationPoint
//     abstract prevClosest: int -> NavigationPoint


// type TimelineNavigator0(key: string) =
export class TimelineNavigator {

//     let navigatorKey = key
//     let mutable positions: ISorted = !!None
//     let setPositions sorted =
//         positions <- sorted

    navigatorKey:string;
    positions:Sorted;

    constructor(key: string) {
        this.navigatorKey = key;
//     let mutable positions: ISorted = !!None
        this.positions = null;
    }

//     let setPositions sorted =
    setPositions(sorted) {
//         positions <- sorted
        this.positions = sorted;
    }

//     let getKey() = navigatorKey
    getKey() {
        return this.navigatorKey;
    }

//     let setIntervals(sorted:ISorted, edge0:EdgeEnum option) =
    setIntervals(sorted:Sorted, edge0:EdgeEnum) {
//         let edge = defaultArg edge0 EdgeEnum.Leading
        const edge = edge0 || EdgeEnum.Leading;
 //         match edge with
//         | EdgeEnum.Leading ->
//             setPositions (sorted.fromStartPoints())
//         | EdgeEnum.Trailing ->
//             setPositions (sorted.fromEndPoints())
//         | EdgeEnum.Midpoint ->
//             setPositions (sorted.fromMidPoints())
        if (edge === EdgeEnum.Leading) {
//             setPositions (sorted.fromStartPoints())
            this.setPositions(sorted.fromStartPoints());
        }
        else if (edge === EdgeEnum.Trailing) {
//             setPositions (sorted.fromEndPoints())
            this.setPositions(sorted.fromEndPoints());
        }
        else if (edge === EdgeEnum.Midpoint) {
//             setPositions (sorted.fromMidPoints())
            this.setPositions(sorted.fromMidPoints());
        }
  }

//     let getPositionForIndex index =
    getPositionForIndex(index:number) {
//         if !!positions then
        if (this.positions) {
//             positions.pointAt(index).starts
            return this.positions.pointAt(index).starts;
//         else
        } else {
//             raise (Exception("no positions"))
            throw Error("no positions");
        }
    }

//     let navigationPoint(index) =
    navigationPoint(index:number) {
//         if !!positions then{}
        if (this.positions) {
//             if positions.checkValidIndex(index) then
            if (this.positions.checkValidIndex(index)) {
//                 let position = getPositionForIndex(index)
                const position = this.getPositionForIndex(index);
                return new NavigationPoint(this.navigatorKey, index, position);
//                 {
//                     navigatorKey = navigatorKey
//                     index = index
//                     position = position
//                 }
//             else
            } else {
//                 Null
                return null;
            }
//         else
        } else {
//             Null
            return null;
        }
    }

//     let move(point0, direction) =
    move(point0:NavigationPoint, direction:number) {
//         if point0.navigatorKey <> navigatorKey then
        if (point0.navigatorKey !== this.navigatorKey) {
//             raise (Exception("navigator keys don't match"))
            throw Error("navigator keys don't match");
        }
//         let nextNavigationPoint = navigationPoint(point0.index + direction)
        const nextNavigationPoint = this.navigationPoint(point0.index + direction);
//         if !!nextNavigationPoint then
        if (nextNavigationPoint) {
//             nextNavigationPoint
            return nextNavigationPoint;
//         else
        } else {
//             point0
            return point0;
        }
    }

//     let next(point) =
    next(point:NavigationPoint) {
//         move(point, 1)
        return this.move(point, 1);
    }

//     let prev(point) =
    prev(point:NavigationPoint) {
//         move(point, -1)
        return this.move(point, -1);
    }

//     let nextClosest(position) =
    nextClosest(position:number) {
//         if !!positions then
        if (this.positions) {
//             navigationPoint(positions.firstAfter(position))
            return this.navigationPoint(this.positions.firstAfter(position));
//         else
        } else {
//             Null
            return null;
        }
    }

//     let prevClosest(position) =
    prevClosest(position:number) {
//         if !!positions then
        if (this.positions) {
//             navigationPoint(positions.lastBeforeOrAt(position))
            return this.navigationPoint(this.positions.lastBeforeOrAt(position));
//         else
        } else {
//             Null
            return null;
        }
    }

//     do autoBindInterface()

}

// let TimelineNavigator(key: string):ITimelineNavigator = !< TimelineNavigator0(key)

//     // TODO deal with defaultArg in function setIntervals and switch to autoBindInterface
//     // interface ITimelineNavigator with
//     //     member this.getKey() = navigatorKey
//     //     member this.setPositions(sorted) = setPositions(sorted)
//     //     member this.setIntervals(sorted, ?edge0) =
//     //         let edge = defaultArg edge0 EdgeEnum.Leading
//     //         setIntervals(sorted, edge)
//     //     member this.getPositionForIndex(index) = getPositionForIndex(index)
//     //     member this.navigationPoint(index) = navigationPoint(index)
//     //     member this.next(point) = next(point)
//     //     member this.prev(point) = prev(point)
//     //     member this.nextClosest(position) = nextClosest(position)
//     //     member this.prevClosest(position) = prevClosest(position)


// type INavigation =
//     abstract addNavigator: ITimelineNavigator -> unit
//     abstract getNavigatorForKey: string -> ITimelineNavigator
//     abstract next: NavigationPoint -> NavigationPoint
//     abstract prev: NavigationPoint -> NavigationPoint
//     abstract nextClosest: NavigationPoint * int -> NavigationPoint
//     abstract prevClosest: NavigationPoint * int -> NavigationPoint

// type Navigation0() =
export class Navigation {
    navigators:any = {};
//     let navigators = obj()

//     let addNavigator (navigator: ITimelineNavigator) =
    addNavigator(navigator: TimelineNavigator) {
//         navigators.[navigator.getKey()] <- navigator
        this.navigators[navigator.getKey()] = navigator;
    }

//     let getNavigatorForKey(key): ITimelineNavigator =
    getNavigatorForKey(key:string): TimelineNavigator {
//         !!navigators.[key]
        return this.navigators[key];
    }

//     let next(point): NavigationPoint =
    next(point:NavigationPoint): NavigationPoint {
//         if !!point then
        if (point) {
//             let navigator = getNavigatorForKey(point.navigatorKey)
            const navigator = this.getNavigatorForKey(point.navigatorKey);
//             navigator.next(point)
            return navigator.next(point);
//         else
        } else {
//             Null
            return null;
        }
    }

//     let prev(point): NavigationPoint =
    prev(point:NavigationPoint): NavigationPoint {
//         if !!point then
        if (point) {
//             let navigator = getNavigatorForKey(point.navigatorKey)
            const navigator = this.getNavigatorForKey(point.navigatorKey);
//             navigator.prev(point)
            return navigator.prev(point);
//         else
        } else {
//             Null
        }
    }

//     let nextClosest(point, position): NavigationPoint=
    nextClosest(point:NavigationPoint, position:number): NavigationPoint {
//         if !!point then
        if (point) {
//             let navigator = getNavigatorForKey(point.navigatorKey)
            const navigator = this.getNavigatorForKey(point.navigatorKey);
//             navigator.nextClosest(position)
            return navigator.nextClosest(position);
//         else
        } else {
//             Null
            return null;
        }
    }

//     let prevClosest(point, position): NavigationPoint=
    prevClosest(point:NavigationPoint, position:number): NavigationPoint {
//         if !!point then
        if (point) {
//             let navigator = getNavigatorForKey(point.navigatorKey)
            const navigator = this.getNavigatorForKey(point.navigatorKey);
//             navigator.prevClosest(position)
            return navigator.prevClosest(position);
//         else
        } else {
//             Null
            return null;
        }
    }
//     do autoBindInterface()

}

// let Navigation():INavigation = !< Navigation0()
