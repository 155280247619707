import { getRandomValues } from './platform/crypto.js';

const forwardSlashRegex = /\//g;

declare const Buffer;

function btoa2(str:string):string {
  let buffer;
  if (<any>str instanceof Buffer) {
    buffer = str;
  } else {
    buffer = Buffer.from(str.toString(), 'binary');
  }
  return buffer.toString('base64');
}

const nodeProcess = (typeof window === 'undefined');

const _btoa: (str:string) => string  = (nodeProcess) ? btoa2: btoa;

export function randomString(length:number):string {
  const a = new Uint8Array(length);
  getRandomValues(a);

  const str = _btoa(String.fromCharCode.apply(null, a)).replace(forwardSlashRegex, "_");
  return str.slice(0, length);
}

export function epochSecondsFloat() {
  return Date.now() / 1000.0;
}
