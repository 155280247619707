// open BasicTypes
// open ElementList
// open ElementTypes
// open ElementSort
// open ContentRoots
// open TimelineNavigator
// open JSBoilerplate

import { computed } from "mobx";
import { ContentRootsBase } from "./masala-lib/editorial/content-roots/content-roots";
import { Element } from "./masala-lib/basic-types";
import { EKinds } from "./masala-lib/elements/element-kinds";
import { sortElements } from "./masala-lib/elements/element-sort";
import { ElementList } from "./masala-lib/elements/element-list";
import { EdgeEnum, TimelineNavigator } from "./masala-lib/navigation/timeline-navigator";


// type IChaatContentRoots =
//     inherit IContentRootsBase
//     abstract content: IElementList
//     abstract words: IElementList
//     abstract warningSentences: IElementList
//     abstract segmentNavigator: ITimelineNavigator
//     abstract notchNavigator: ITimelineNavigator
//     abstract playableElements: IElementList
//     abstract stateVersion: int


// type ChaatContentRoots0() as s0 =
export class ChaatContentRoots extends ContentRootsBase {

//     inherit ContentRootsBase0()
//     let self:IChaatContentRoots = !< s0

//     let mutable stateVersion0 = 1
    stateVersion0 = 1;

//     (* @computedKeepAlive *)
//     let words() =
    @computed( { keepAlive: true } )
    get words() {
//         self.words1
        return this.words1;
    }

//     (* @computedKeepAlive *)
//     let content() =
    @computed( { keepAlive: true } )
    get content() {
//         let result = [||]
        const result = [];
//         result.extend(self.sentences1)
        result.push(...this.sentences1)
//         let chaatDisplayStructuralElement(e:IElement) =
//             Array.contains e.kind [|CHAPTER_TITLE; PASSAGE_HINT; SPEAKER_LABEL|]
        const displayStructuralKinds:string [] = [EKinds.CHAPTER_TITLE, EKinds.PASSAGE_HINT, EKinds.SPEAKER_LABEL];
        const chaatDisplayStructuralElement = (e:Element) => displayStructuralKinds.includes(e.kind);
//         result.extend(self.structural0 |> Array.filter chaatDisplayStructuralElement)
        result.push(...this.structural0.filter(chaatDisplayStructuralElement));
//         sortElements(result)
        sortElements(result);
//         ElementList(result, self.episodeKey, Null, self.words1, Null, Null, Null)
        return new ElementList(result, this.episodeKey, null, this.words1, null, null, null);
    }

//     (* @computedKeepAlive *)
//     let warningSentences() =
    @computed( { keepAlive: true } )
    get warningSentences() {
//         self.content.fromIds(self.warningSentenceIds)
        return this.content.fromIds(this.warningSentenceIds);
    }

//     (* @computedKeepAlive *)
//     let segmentNavigator():ITimelineNavigator =
    @computed( { keepAlive: true } )
    get segmentNavigator():TimelineNavigator {
//         let navigator = TimelineNavigator("SEGMENT")
        const navigator = new TimelineNavigator("SEGMENT");
        // TODO consider better way besides option param??
//         navigator.setIntervals(self.segmentTimeIntervals, Some EdgeEnum.Midpoint)
        navigator.setIntervals(this.segmentTimeIntervals, EdgeEnum.Midpoint);
//         navigator
        return navigator;
    }

//     (* @computedKeepAlive *)
//     let notchNavigator():ITimelineNavigator =
    @computed( { keepAlive: true } )
    get notchNavigator():TimelineNavigator {
//         let navigator = TimelineNavigator("NOTCH")
        const navigator = new TimelineNavigator("NOTCH");
//         navigator.setIntervals(self.notchTimeIntervals, Some EdgeEnum.Midpoint)
        navigator.setIntervals(this.notchTimeIntervals, EdgeEnum.Midpoint);
//         navigator
        return navigator;
    }

//     (* @computedKeepAlive *)
//     let playableElements() =
    @computed( { keepAlive: true } )
    get playableElements() {
//         let result = [||]
        const result = [];
//         result.extend(self.content.getKindSubList(SENTENCE).elements)
        result.push(...this.content.getKindSubList(EKinds.SENTENCE).elements);
//         result.extend(self.words.elements)
        result.push(...this.words.elements);
//         sortElements(result)
        sortElements(result);
//         ElementList(result, self.episodeKey, Null, self.words1, Null, Null, Null)
        return new ElementList(result, this.episodeKey, null, this.words1, null, null, null);
    }

//     let stateVersion() =
    get stateVersion() {
//         let touch = (self.words, self.content)
        const touch = [this.words, this.content];
//         stateVersion0 <- stateVersion0 + 1
        this.stateVersion0 = this.stateVersion0 + 1
//         stateVersion0
        return this.stateVersion0;
    }

//     do autoBindInterface()
}

// let ChaatContentRoots():IChaatContentRoots = !< ChaatContentRoots0()
