import { observable, makeObservable } from 'mobx';
import firebase from "firebase";
import { firebaseAuth } from '../../platform/firebase-auth';
import { UserManager, AppUser } from '../models/user-manager';

export class Auth {
  authUser: firebase.User | null;
  userManager: UserManager;

  constructor() {
    this.authUser = null; // the firebase auth provided user data
    this.userManager = new UserManager({listenMode: false});

    makeObservable(this, {
      authUser:observable.ref,
    });

    firebaseAuth.onAuthStateChanged((firebaseUser: firebase.User) => {
      this.authUser = firebaseUser;
      if (firebaseUser) {
        this.userManager.loadUserByEmail(firebaseUser.email, false); // listenMode: false
      }
    });
  }

  get appUser(): AppUser | undefined {
    return this.userManager.single;
  }

  get appUserId(): string | undefined {
    return this.appUser?.id;
  }

  get isAdmin(): boolean {
    return !!this.appUser?.isAdmin;
  }
}
