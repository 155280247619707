import React from 'react';
import { observer } from 'mobx-react';
import { Routes } from './routes.js';
import { auth } from "./app-root";
import { LoginScreen } from './screens/login-screen';
import './App.css';

export const App = () => {
  return <AppView auth={auth} />;
};

export const AppView = observer(({ auth }) => {
  return (
    <div id={'main-container'}>
      {auth.authUser ? (
        <Routes />
      ) : (
        <LoginScreen />
      )}
    </div>
  );
});
