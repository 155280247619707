
export function msToMinSecString(ms) {
  let seconds = Math.floor(ms / 1000);
  const minutes = Math.floor(seconds / 60);
  seconds = seconds % 60;
  return `${minutes}:${seconds.toString().padStart(2, "0")}`;
}

export function intervalToMinSecMsDurationString(interval) {
  let ms = interval.start;
  const baseStr = msToMinSecString(ms);
  ms = ms % 1000;
  const duration = interval.end - interval.start;
  return `${baseStr}.${ms.toString().padStart(3, "0")} (${duration} ms)`;
}
