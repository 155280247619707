import {DbPaths} from "./db.js";
import {firebase, db} from '../../platform/firebase-init';
import { randomString, epochSecondsFloat } from '../../utils';

const types = {
  SENTENCE: 'SENTENCE',
  WORD_GROUP: 'WORD_GROUP',
  CHAPTER_TITLE: 'CHAPTER_TITLE',
  PASSAGE_HINT: 'PASSAGE_HINT',
  METADATA_BLOCK: 'METADATA_BLOCK',
  // TODO use import from F# defs? ...
};

/******* WHOLE EPISODE DB CREATE/UPDATE *********/
export async function createUpdateEpisodeDB(episodeKey, data, metadata) {
  const dbPaths = new DbPaths(db, episodeKey);
  // debugger;
  const metadataDocRef = dbPaths.metadataDocRef;
  const verbatimDocRef = dbPaths.verbatimDocRef;
  const structuralDocRef = dbPaths.structuralDocRef;
  const wordGroupsDocRef = dbPaths.wordGroupsDocRef;
  const metadataBlocksDocRef = dbPaths.metadataBlocksDocRef;
  // const threadsDocRef = dbPaths.threadsDocRef;
  const translationsDocRef = dbPaths.translationsDocRef;
  const warningSuppressionsDocRef = dbPaths.warningSuppressionsDocRef;

  const sentenceVersionsDocRef = dbPaths.sentenceVersionsDocRef;
  const structuralVersionsDocRef = dbPaths.structuralVersionsDocRef;
  const wordGroupVersionsDocRef = dbPaths.wordGroupVersionsDocRef;
  const translationVersionsDocRef = dbPaths.translationVersionsDocRef;

  const promises = []
  const learnersLanguage = (metadata)? metadata.learnersLanguage: 'en'; // TODO

  promises.push(metadataDocRef.set({
    editEnabled: true,
    learnersLanguage,
    schemaVersion: "0.5",
  }));

  // TODO add timestamp?
  promises.push(verbatimDocRef.set(data.verbatim));

  promises.push(structuralDocRef.set({structural:data.structural}));
  promises.push(wordGroupsDocRef.set({wordGroups:data.wordGroups}));
  // TODO
  promises.push(metadataBlocksDocRef.set({metadata:data.metadataBlocks}));
  // promises.push(threadsDocRef.set({threads:{}}));
  promises.push(translationsDocRef.set({translations:{[learnersLanguage]: data.translations}}));
  promises.push(warningSuppressionsDocRef.set({suppressions:[]}));

  // TODO merge or not?
  promises.push(sentenceVersionsDocRef.set({versions: {}}));
  promises.push(wordGroupVersionsDocRef.set({versions: {}}));
  promises.push(structuralVersionsDocRef.set({versions: {}}));
  promises.push(translationVersionsDocRef.set({versions: {}}));

  return Promise.all(promises);
}

export async function createAllFirestoreCollections() {
  const createDoc = async (docRef) => {
    docRef.set({});
  }
  const dummyDocName = "dummyDoc";
  const dbPaths = new DbPaths(db, dummyDocName);

  await createDoc(dbPaths.metadataDocRef);
  await createDoc(dbPaths.chaatMetadataDocRef);
  await createDoc(dbPaths.verbatimDocRef);
  await createDoc(dbPaths.structuralDocRef);
  await createDoc(dbPaths.wordGroupsDocRef);
  await createDoc(dbPaths.translationsDocRef);
  await createDoc(dbPaths.metadataBlocksDocRef);
  await createDoc(dbPaths.chaatCuesDocRef);
  await createDoc(dbPaths.chaatTimestampsDocRef);
  await createDoc(dbPaths.chaatAudioAnalysisDocRef);
  await createDoc(dbPaths.chaatAudioRegionsDocRef);
  await createDoc(dbPaths.chaatAudioMarkersDocRef);
  await createDoc(dbPaths.chaatSpeechTranscriptsDocRef);
  await createDoc(dbPaths.sentenceVersionsDocRef);
  await createDoc(dbPaths.wordGroupVersionsDocRef);
  await createDoc(dbPaths.structuralVersionsDocRef);
  await createDoc(dbPaths.translationVersionsDocRef);
  await createDoc(dbPaths.warningSuppressionsDocRef);
  await createDoc(dbPaths.chaatAudioProcessingJobDocRef);
  await createDoc(dbPaths.chaatTranscriptionJobDocRef);
  await createDoc(dbPaths.chaatSignoffsDocRef);
}

export async function assertExistVersionDocs(episodeKey) {
  // TODO
}

export async function assertExistChaatDocs(episodeKey) {
  const dbPaths = new DbPaths(db, episodeKey);
  // debugger;
  const chaatMetadataDocRef = dbPaths.chaatMetadataDocRef;
  const chaatCuesDocRef = dbPaths.chaatCuesDocRef;
  const chaatTimestampsDocRef = dbPaths.chaatTimestampsDocRef;
  const chaatAudioAnalysisDocRef = dbPaths.chaatAudioAnalysisDocRef;
  const chaatAudioRegionsDocRef = dbPaths.chaatAudioRegionsDocRef;
  const chaatAudioMarkersDocRef = dbPaths.chaatAudioMarkersDocRef;
  const chaatSpeechTranscriptsDocRef = dbPaths.chaatSpeechTranscriptsDocRef;
  const chaatAudioProcessingJobDocRef = dbPaths.chaatAudioProcessingJobDocRef;
  const chaatTranscriptionJobDocRef = dbPaths.chaatTranscriptionJobDocRef;
  const chaatSignoffsDocRef = dbPaths.chaatSignoffsDocRef;

  const promises = []
  // TODO metadata
  promises.push(chaatMetadataDocRef.set({}));
  // TODO add timestamps
  promises.push(chaatCuesDocRef.set({cues:{}, timestamp:1.0})); // TODO how to merge array?
  promises.push(chaatSpeechTranscriptsDocRef.set({timestamp:1.0}, {merge:true}));
  promises.push(chaatAudioRegionsDocRef.set({regions:{}, timestamp:1.0}, {merge:true}));

  promises.push(chaatTimestampsDocRef.set({timestamp:0.0}, {merge:true}));
  promises.push(chaatAudioAnalysisDocRef.set({}, {merge:true}));
  promises.push(chaatAudioMarkersDocRef.set({markers:{}}, {merge:true}));
  promises.push(chaatAudioProcessingJobDocRef.set({}, {merge:true}));
  promises.push(chaatTranscriptionJobDocRef.set({}, {merge:true}));
  promises.push(chaatSignoffsDocRef.set({signoffs:[]}, {merge:true}));
  return Promise.all(promises);
}

export async function initChaatFromMetadata(episodeKey, metadata0) {
  const dbPaths = new DbPaths(db, episodeKey);
  const chaatMetadataDocRef = dbPaths.chaatMetadataDocRef;
  const chaatAudioProcessingJobDocRef = dbPaths.chaatAudioProcessingJobDocRef;
  let metadata = {
    ...metadata0,
    creationTime: epochSecondsFloat(),
  }

  let result = await chaatMetadataDocRef.set(metadata);
  result = await chaatAudioProcessingJobDocRef.set({
    inputAudioUpdateTimestamp: epochSecondsFloat(),
  }, {merge:true});
  return result;
}

export async function deleteAllEpisodeDocs(episodeKey) {
  const dbPaths = new DbPaths(db, episodeKey);
  const promises = [];

  promises.push(dbPaths.metadataDocRef.delete());
  promises.push(dbPaths.metadataBlocksDocRef.delete());
  promises.push(dbPaths.verbatimDocRef.delete());
  promises.push(dbPaths.wordGroupsDocRef.delete());
  promises.push(dbPaths.translationsDocRef.delete());
  // promises.push(dbPaths.threadsDocRef.delete());

  promises.push(dbPaths.sentenceVersionsDocRef.delete());
  promises.push(dbPaths.wordGroupVersionsDocRef.delete());
  promises.push(dbPaths.structuralVersionsDocRef.delete());
  promises.push(dbPaths.translationVersionsDocRef.delete());

  // TODO split to deleteAllChaatDocuments??
  promises.push(dbPaths.chaatAudioAnalysisDocRef.delete());
  promises.push(dbPaths.chaatAudioRegionsDocRef.delete());
  promises.push(dbPaths.chaatCuesDocRef.delete());
  promises.push(dbPaths.chaatTimestampsDocRef.delete());
  promises.push(dbPaths.chaatAudioProcessingJobDocRef.delete());
  promises.push(dbPaths.chaatTranscriptionJobDocRef.delete());
  promises.push(dbPaths.chaatMetadataDocRef.delete());
  promises.push(dbPaths.chaatSpeechTranscriptsDocRef.delete());
  promises.push(dbPaths.chaatSignoffsDocRef.delete());
  return Promise.all(promises);
}

export async function setEditorFlags(episodeKey, flags) {
  const dbPaths = new DbPaths(db, episodeKey);
  const metadataDocRef = dbPaths.metadataDocRef;
  const editEnabled = !!flags.editEnabled;
  const result = await metadataDocRef.update({editEnabled});
  return result;
}

export async function testLoadEpisodeDB(episodeKey) {
  const dbPaths = new DbPaths(db, episodeKey);
  // random syntax tests
  // debugger;
  const metadataDocRef = dbPaths.metadataDocRef;
  const verbatimDocRef = dbPaths.verbatimDocRef;
  const structuralDocRef = dbPaths.structuralDocRef;
  const wordGroupsDocRef = dbPaths.wordGroupsDocRef;
  const metadataBlocksDocRef = dbPaths.metadataBlocksDocRef;
  // const threadsDocRef = dbPaths.threadsDocRef;
  const translationsDocRef = dbPaths.translationsDocRef;


  const verbatimDoc = (await verbatimDocRef.get()).data();
  const structuralDoc = (await structuralDocRef.get()).data();
  const wordGroupsDoc = (await wordGroupsDocRef.get()).data();
  const metadataDoc = (await metadataDocRef.get()).data();

  return {
    verbatimDoc,
    structuralDoc,
    wordGroupsDoc,
    metadataDoc,
  }
}

export async function updateChaatOutputs(episodeKey, chaatResults, scriptTimestamp, cuesTimestamp, regionsTimestamp, transcriptStartTimes, transcriptEndTimes) {
  const dbPaths = new DbPaths(db, episodeKey);
  const chaatResultsDocRef = dbPaths.chaatTimestampsDocRef;
  scriptTimestamp = scriptTimestamp || 0.0;
  cuesTimestamp = cuesTimestamp || 0.0;
  regionsTimestamp = regionsTimestamp || 0.0;
  // TODO better naming consistency for firestore data structure and chaat results object?
  // chaatResults = instanceToObject(chaatResults); // TODO would not be needed if could turn off typed arrays
  chaatResults = {
    wordTimeIntervals: {startTimes:chaatResults.wordStartTimes, endTimes:chaatResults.wordEndTimes},
    warningTimeIntervals: {startTimes:chaatResults.warnStartTimes, endTimes:chaatResults.warnEndTimes},
    warningData: chaatResults.warnData,
    interpolationTimeIntervals: {startTimes:chaatResults.interpolationStartTimes, endTimes:chaatResults.interpolationEndTimes},
    interpolationData: chaatResults.interpolationData,
    scriptTimestamp,
    cuesTimestamp,
    regionsTimestamp,
    timestamp: epochSecondsFloat(),
  }
  let result = await chaatResultsDocRef.set(chaatResults);
  // TODO think about this
  if (transcriptStartTimes) {
    const transcriptDocRef = dbPaths.chaatSpeechTranscriptDocRef;
    result = transcriptDocRef.update({transcriptWordTimeIntervals:{startTimes:transcriptStartTimes, endTimes:transcriptEndTimes}})
  }
}

export async function updateTranslations(episodeKey, translations, language, merge) {
  const dbPaths = new DbPaths(db, episodeKey);
  const translationsDocRef = dbPaths.translationsDocRef;

  const result = await translationsDocRef.set({translations:{[language]:translations}}, {merge});
  return result;
}

// const instanceToObject = instance => {
//   const instance = instance || {}
//   const keys = Object.getOwnPropertyNames(Object.getPrototypeOf(instance))
//   if (!keys) {
//     return instance;
//   }
//   return keys.reduce((asObj, key) => {
//     asObj[key] = instance[key]
//     return asObj;
//   }, {})
// }

const instanceToObject = instance => {
  return JSON.parse(JSON.stringify(instance))
}

export class MutationActions {
  // TODO consider converting some update() to set()

  constructor() {
    this.dbPaths = null; // create using episodeKey in constructor?
    this.lastLocalChaatInputMutationTimestamp = 0;
    // initialized by app specific subclasses
  }

  recordLocalChaatMutation() {
    this.lastLocalChaatInputMutationTimestamp = Date.now();
  }

  get chaatInputRecentLocalModification() {
    return this.lastLocalChaatInputMutationTimestamp > (Date.now() - 5000);
  }

  setEpisodeKey(episodeKey) {
    this.dbPaths = new DbPaths(db, episodeKey);
  }

  checkPermissions(action) {
    // TODO
    if (!this.auth.appUser) {
      console.log("ALERT: action requires login");
      this.alerts.add({text:"action requires login", forceAcknowlege:true, level:3})
      throw Error('checkPermission: action requires login');
    }
  }

  addTimestamp(element) {
    element.timestamp = epochSecondsFloat();
  }

  addAttribution(element) {
    element.author = this.auth.appUser?.id;
    this.addTimestamp(element);
    return element;
  }

  addNewIdIfMissing(element, kind) {
    if (!element.id) {
      element.id = element.kind + ":" + randomString(12);
    }
    return element;
  }

  wrapMapToId(element, map) {
    map = map || {};
    map[element.id] = element;
    return map;
  }

  getElementURI(element) {
    // TODO translations case, think again
    return element.elementId;
  }

  /******* VERBATIM *********/

  async updateVerbatim(words, wordIdMapping, sentenceDTOs = null) {
    this.checkPermissions(/* TODO update verbatim action*/);
    wordIdMapping = {
      indexLookup:[...(wordIdMapping.indexLookup)],
      contentLength: wordIdMapping.contentLength,
    };
    const update = {
      words,
      wordIdMapping,
    };
    this.addTimestamp(update);
    if (sentenceDTOs) {
      for (const sentenceDTO of sentenceDTOs) {
        const elementData =  instanceToObject(sentenceDTO);
        elementData.kind = types.SENTENCE;
        this.addAttribution(elementData);
        this.addNewIdIfMissing(elementData);
        update['sentences.' + elementData.id] = elementData;
      }
    }
    const verbatimDocRef = this.dbPaths.verbatimDocRef;
    this.recordLocalChaatMutation();
    const result = await verbatimDocRef.update(update);
  }

  async splitSentence(sentenceId, wordId, above, sentences) {
    const sentence = sentences.getElement(sentenceId);
    const update = {};
    let newSentenceAnchors;
    const sentencePath = 'sentences.' + sentence.id;
    if (above) { // change name to above everywhere is bool?
      update[sentencePath + '.anchors.startWordId'] = wordId;
      newSentenceAnchors = {startWordId:sentence.anchors.startWordId, endWordIdExclusive: wordId};
    } else {
      update[sentencePath + '.anchors.endWordIdExclusive'] = wordId;
      newSentenceAnchors = {startWordId:wordId, endWordIdExclusive: sentence.anchors.endWordIdExclusive};
    }
    let newSentence = {
      kind:'SENTENCE',
      anchors: newSentenceAnchors,
    };
    this.addAttribution(newSentence);
    this.addNewIdIfMissing(newSentence);
    const newSentencePath = 'sentences.' + newSentence.id;
    update[newSentencePath] = newSentence;

    update[sentencePath + '.author'] = newSentence.author;
    update[sentencePath + '.timestamp'] = newSentence.timestamp;
    // TODO error when either sentence is zero length startWordId === endWordIdExclusive
    const verbatimDocRef = this.dbPaths.verbatimDocRef;
    this.recordLocalChaatMutation();
    const result = await verbatimDocRef.update(update);
  }

  async removeSentence(sentenceId) {
    this.checkPermissions(/* TODO update verbatim action*/);
    /* TODO sentence must have no words to delete, this check goes at higher level
    if (sentence.transcriptWordCount > 0) {
      warning();
      return;
    }
    */
    // const update = {
      // sentences:{[sentenceId + '.deleted']: true}
      // sentences:{[sentenceId]: firebase.firestore.FieldValue.delete()}
    // };
    const update = {sentences: {[sentenceId] : this.addAttribution({deleted: true})}}

    this.recordLocalChaatMutation();
    const verbatimDocRef = this.dbPaths.verbatimDocRef;
    const result = await verbatimDocRef.set(update, {merge:true});
  }

  /******* STRUCTURAL *********/

  async addUpdateStructural(elementDTO) {
    this.checkPermissions(/* TODO update structural action*/);
    /* TODO check if needed new id set type*/
    // const elementData = {...elementDTO}
    const elementData = instanceToObject(elementDTO);
    this.addNewIdIfMissing(elementData);
    this.addAttribution(elementData);
    const structuralDocRef = this.dbPaths.structuralDocRef;
    const result = await structuralDocRef.update({['structural.' + elementData.id]:elementData});
  }

  async updateContentStructural(id, content) {
    this.checkPermissions(/* TODO update structural action*/);
    const elementData = {id, content};
    this.addAttribution(elementData);
    const structuralDocRef = this.dbPaths.structuralDocRef;
    console.log(`updating ${id} with ${content}`);
    const result = await structuralDocRef.set({structural:this.wrapMapToId(elementData)}, {merge:true});
  }

  async removeStructural(elementId) {
    this.checkPermissions(/* TODO update structural action*/);
    const structuralDocRef = this.dbPaths.structuralDocRef;
    // TODO instead mark deleted tombstone?? if don't tombstone then no place to put attribution for remove action?
    const update = {structural: {[elementId] : this.addAttribution({deleted: true})}}
    const result = await structuralDocRef.set(update, {merge:true});
    // const result = await structuralDocRef.update({['structural.' + elementId]: firebase.firestore.FieldValue.delete()});
  }

  async revertStructural(version) {
    this.checkPermissions(/* TODO update structural action*/);
    const structuralDocRef = this.dbPaths.structuralDocRef;
    const elementId = version.id;
    const update = {structural: {[elementId] : version}};
    const result = await structuralDocRef.set(update, {merge:true});
  }

  /******* METADATA BLOCKS *********/

  async addUpdateMetadataBlock(elementDTO) {
    this.checkPermissions(/* TODO update metadata action*/);
    /* TODO check if needed new id set type*/
    // const elementData = {...elementDTO}
    const elementData = instanceToObject(elementDTO);
    elementData.kind = types.METADATA_BLOCK;
    this.addNewIdIfMissing(elementData);
    this.addAttribution(elementData);
    const metadataBlocksDocRef = this.dbPaths.metadataBlocksDocRef;
    const result = await metadataBlocksDocRef.update({['metadata.' + elementData.id]:elementData});
  }

  async updateContentMetadataBlock(id, content) {
    this.checkPermissions(/* TODO update metadata action*/);
    const elementData = {id, content};
    this.addAttribution(elementData);
    const metadataBlocksDocRef = this.dbPaths.metadataBlocksDocRef;
    console.log(`updating ${id} with ${content}`);
    const result = await metadataBlocksDocRef.set({metadata:this.wrapMapToId(elementData)}, {merge:true});
  }

  async removeMetadataBlock(elementId) {
    this.checkPermissions(/* TODO update metadata action*/);
    const metadataBlocksDocRef = this.dbPaths.metadataBlocksDocRef;
    // TODO instead mark deleted tombstone?? if don't tombstone then no place to put attribution for remove action?
    // const update = {metadata: {[elementId] : this.addAttribution({deleted: true})}}
    const update = {metadata: {[elementId] :firebase.firestore.FieldValue.delete()}}
    const result = await metadataBlocksDocRef.set(update, {merge:true});
    // const result = await structuralDocRef.update({['structural.' + elementId]: firebase.firestore.FieldValue.delete()});
  }


  /******* WORD GROUPS *********/

  async addUpdateWordGroup(elementDTO) {
    // TODO factor code with addUpdateStructural???
    this.checkPermissions(/* TODO update word groups action*/);
    // const elementData = {...elementDTO}
    const elementData = instanceToObject(elementDTO);
    elementData.kind = types.WORD_GROUP;
    this.addNewIdIfMissing(elementData);
    this.addAttribution(elementData);
    const wordGroupsDocRef = this.dbPaths.wordGroupsDocRef;
    console.log(`updating ${elementData.id} with:`)
    console.log(elementData)
    const result = await wordGroupsDocRef.update({['wordGroups.' + elementData.id]:elementData});
  }

  async removeWordGroup(elementId) {
    this.checkPermissions(/* TODO update word groups action*/);
    const wordGroupsDocRef = this.dbPaths.wordGroupsDocRef;
    // TODO instead mark deleted tombstone?? if don't tombstone then no place to put attribution for remove action?
    // TODO FACTOR the building of element delete update data out, can change there to different handlings like tombstone
    const update = {wordGroups: {[elementId] : this.addAttribution({deleted: true})}}
    const result = await wordGroupsDocRef.set(update, {merge:true});
    // const result = await wordGroupsDocRef.update( { ['wordGroups.' + elementId]: firebase.firestore.FieldValue.delete() });
  }

  async revertWordGroup(version) {
    this.checkPermissions(/* TODO update word groups action*/);
    const wordGroupsDocRef = this.dbPaths.wordGroupsDocRef;
    const id = version.id;
    const update = {wordGroups: {[id] : version}};
    const result = await wordGroupsDocRef.set(update, {merge:true});
  }

  /******* TRANSLATIONS *********/

  async addUpdateTranslation(id, translationLanguage , translation) {
    this.checkPermissions(/* TODO update translations action*/);
    const data = {
      id: `TRANSLATION:${id}/${translationLanguage}`,
      kind: 'TRANSLATION',
      elementId: id,
      translationLanguage,
      content: translation,
    }
    this.addAttribution(data);
    const update = {[translationLanguage]:{[id]: data}};
    const translationsDocRef = this.dbPaths.translationsDocRef;
    const result = await translationsDocRef.set({translations: update}, {merge:true});
  }

  async revertTranslation(version) {
    this.checkPermissions(/* TODO update translations action*/);
    const translationsDocRef = this.dbPaths.translationsDocRef;
    const elementId = version.elementId;
    const translationLanguage = version.translationLanguage;
    const update = {translations: {[translationLanguage]:{[elementId] : version}}};
    const result = await translationsDocRef.set(update, {merge:true});
  }

  /******* THREADS *********/

  // async addUpdateMessage(messageData0) {
  //   this.checkPermissions(/* TODO update threads action*/);
  //   const messageData = {...messageData0};
  //   this.addNewIdIfMissing(messageData);
  //   this.addAttribution(messageData);
  //   const elementURI = this.getElementURI(messageData);
  //   const update = {[elementURI]:{id:elementURI, messages:this.wrapMapToId(messageData)}}
  //   const threadsDocRef= this.dbPaths.threadsDocRef;
  //   console.log(`updating ${elementURI} to:`);
  //   console.log(update);
  //   const result = await threadsDocRef.set({threads:update}, {merge:true});
  // }

  /******* VALIDATIONS *********/

  async setElementWarningSuppression(elementId, suppress) {
    const warningSuppressionsDocRef = this.dbPaths.warningSuppressionsDocRef;
    const update = suppress
                    ?  firebase.firestore.FieldValue.arrayUnion(elementId)
                    : firebase.firestore.FieldValue.arrayRemove(elementId);
    const result = await warningSuppressionsDocRef.update({suppressions: update});
  }

  /******* CHAAT SIGNOFFS *********/

  async setChaatSignoff(key, signoff) {
    const signoffsDocRef = this.dbPaths.chaatSignoffsDocRef;
    const update = signoff
                    ?  firebase.firestore.FieldValue.arrayUnion(key)
                    : firebase.firestore.FieldValue.arrayRemove(key);
    const result = await signoffsDocRef.update({signoffs: update});
  }

  /******* CUES *********/

  async addUpdateCue(data) {
    this.checkPermissions(/* TODO update cues action*/);
    const cuesDocRef = this.dbPaths.chaatCuesDocRef;
    data = instanceToObject(data);
    if (data.input) {
      this.recordLocalChaatMutation();
    }
    const result = await cuesDocRef.set({cues:{[data.wordId]:data}, timestamp:epochSecondsFloat()}, {merge:true});
  }

  async removeCue(wordId, input) {
    this.checkPermissions(/* TODO update cues action*/);
    if (input) {
      this.recordLocalChaatMutation();
    }
    const cuesDocRef = this.dbPaths.chaatCuesDocRef;
    const result = await cuesDocRef.update({ ['cues.' + wordId]: firebase.firestore.FieldValue.delete(), timestamp:epochSecondsFloat()});
    return result;
  }

  /******* AUDIO REGIONS *********/

  async addUpdateChaatAudioRegion(audioRegionData0) {
    this.checkPermissions(/* TODO update audio regions action*/);
    const regionData = instanceToObject(audioRegionData0);
    if (!regionData.id) {
      regionData.id = `AUDIO_REGION:${randomString(12)}`;
    }
    const regionsDocRef = this.dbPaths.chaatAudioRegionsDocRef;
    this.recordLocalChaatMutation();
    const result = await regionsDocRef.update(
      {['regions.' + regionData.id]: regionData,
      timestamp: epochSecondsFloat(),
    });
  }

  async removeChaatAudioRegion(audioRegionId) {
    this.checkPermissions(/* TODO update audio regions action*/);
    const regionsDocRef = this.dbPaths.chaatAudioRegionsDocRef;
    this.recordLocalChaatMutation();
    const result = await regionsDocRef.update( {
      ['regions.' + audioRegionId]: firebase.firestore.FieldValue.delete(),
      timestamp: epochSecondsFloat(),
    });
  }

  /******* TODO AUDIO MARKERS *********/

  async addUpdateChaatAudioMarker(audioMarkerData0) {
    this.checkPermissions(/* TODO update audio marker action*/);
    const markerData = instanceToObject(audioMarkerData0);
    if (!markerData.id) {
      markerData.id = `AUDIO_MARKER:${randomString(12)}`;
    }
    const markersDocRef = this.dbPaths.chaatAudioMarkersDocRef;
    this.recordLocalChaatMutation();
    const result = await markersDocRef.update({['markers.' + markerData.id]: markerData});
  }

  async removeChaatAudioMarker(audioMarkerId) {
    this.checkPermissions(/* TODO update audio markers action*/);
    const markersDocRef = this.dbPaths.chaatAudioMarkersDocRef;
    this.recordLocalChaatMutation();
    const result = await markersDocRef.update( {
      ['markers.' + audioMarkerId]: firebase.firestore.FieldValue.delete()
    });
  }

}
