import {IObservableValue, observable} from 'mobx';
import { isNull } from '../basic-types';

// type Signal0() =
export class Signal {
//     let mutable value = 0
    value = 0;
//     let mutable boxed: IMobxBoxed = Null
    boxed:IObservableValue<number> = null;
//     let fastReactions: ResizeArray<(unit -> unit)> = ResizeArray([])
    fastReactions: (() => void) [] = [];

//     let doFastReactions() =
    doFastReactions() {
//         for callback in fastReactions do
        for (const callback of this.fastReactions) {
//             callback ()
            callback();
        }
    }

//     let set(aValue) =
    set(aValue:number) {
//         value <- aValue
        this.value = aValue;
//         doFastReactions ()
        this.doFastReactions();
//         if !!boxed then
        if (this.boxed) {
//             boxed.set(aValue)
            this.boxed.set(aValue);
        }
    }

//     let watch():int =
    watch():number {
//         if isNull(boxed) then
        if (isNull(this.boxed)) {
//             boxed <- observable.box(value)
            this.boxed = observable.box(this.value);
        }
//         !< boxed.get()
        return this.boxed.get();
    }

//     let addFastReaction(f) =
    addFastReaction(f:(() => void))  {
//         fastReactions.Add(f)
        this.fastReactions.push(f);
    }

//     do autoBindInterface()

}
