// open Fable.Core
// open Fable.Core.JsInterop
// open Fable.Core.DynamicExtensions
// open Precedence
// open BasicTypes
// open IndexMapping
// open ElementList
// open ElementSort
// open Sorted
// open AdhocWordRange
// open ContentFuncs
// open JSBoilerplate

import { observable, computed, makeObservable } from "mobx";
import { ChaatInputCue, Element, ElementId, IdRange, IndexMapping, isNull, numberProjectionSort, SpanAnchors, SpanExclusiveAnchors, StructuralAnchor } from "../../basic-types";
import { getSentenceWordIdRange } from "../../content-funcs";
import { makeAdhocWordRange } from "../../elements/ad-hoc-word-range";
import { ElementList } from "../../elements/element-list";
import { sortElements } from "../../elements/element-sort";
import { Precedence } from "../../elements/precedence";
import { fromIntervals, size, Sorted } from "../../sorted/sorted";
import { getIndex, isDeletedId } from "../ids/positional-ids";

// // TODO move and create more string utilities
// [<Emit("$0.indexOf($1)")>]
// let substringIndexOf(str:string, substr:string): int = jsNative

// let mapSentenceAnchorsToWordAddresses(sentences: IElement [], words:IElementList) =
function mapSentenceAnchorsToWordAddresses(sentences: Element [], words:ElementList) {
//     for sentence in sentences do
    for (const sentence of sentences) {
//         let sentenceAnchors:SpanExclusiveAnchors = !< sentence.anchors
        const sentenceAnchors = <SpanExclusiveAnchors> sentence.anchors;
//         sentence.wordAddress <- words.getIndex(!< sentenceAnchors.startWordId)
        sentence.wordAddress = words.getIndex(sentenceAnchors.startWordId);
//         sentence.endWordAddress <- words.getIndex(!< sentenceAnchors.endWordIdExclusive) - 1
        sentence.endWordAddress = words.getIndex(sentenceAnchors.endWordIdExclusive) - 1;
    }
}

// let mapStructuralAnchorsToWordAddresses(structural: IElement [], words: IElementList, sentenceIntervals) =
function mapStructuralAnchorsToWordAddresses(structural: Element [], words: ElementList, sentenceIntervals:Sorted) {
    // TODO needs to use sentence intervals
//     for element in structural do
    for (const element of structural) {
//         let wordId:StructuralAnchor = !< element.anchors
        const wordId= <StructuralAnchor>element.anchors;
//         element.wordAddress <- words.getIndex(!< wordId)
        element.wordAddress = words.getIndex(wordId);
    }
}

// let mapWordGroupAnchorsToWordAddresses(wordGroups: IElement [], wordIdMapping:IndexMapping) =
function mapWordGroupAnchorsToWordAddresses(wordGroups: Element [], wordIdMapping:IndexMapping) {
//     for wordGroup in wordGroups do
    for (const wordGroup of wordGroups) {
//         let wordGroupAnchors:SpanAnchors = !< wordGroup.anchors
        const wordGroupAnchors = <SpanAnchors>wordGroup.anchors;
//         wordGroup.wordAddress <- getIndex wordIdMapping wordGroupAnchors.startWordId
        wordGroup.wordAddress = getIndex(wordIdMapping, wordGroupAnchors.startWordId);
//         let endWordId = wordGroupAnchors.endWordId
        const endWordId = wordGroupAnchors.endWordId;
//         let mutable index0 = getIndex wordIdMapping endWordId
        let index0 = getIndex(wordIdMapping, endWordId);
//         if isDeletedId wordIdMapping endWordId && index0 > 0 then
        if (isDeletedId(wordIdMapping, endWordId) && index0 > 0 ) {
//             index0 <- index0 - 1
            index0--;
        }
//         wordGroup.endWordAddress <- index0
        wordGroup.endWordAddress = index0;
    }
}

// let partitionOrphanedWordGroups(wordGroups: IElement []) =
function partitionOrphanedWordGroups(wordGroups: Element []) {
//     let nonOrphaned = [||]
    const nonOrphaned = [];
//     let orphaned = [||]
    const orphaned = [];
//     for g in wordGroups do
    for (const g of wordGroups) {
//         if g.wordAddress > g.endWordAddress then
        if (g.wordAddress > g.endWordAddress) {
//             orphaned.append(g)
            orphaned.push(g);
//         else
        } else {
//             nonOrphaned.append(g)
            nonOrphaned.push(g);
        }
    }
//     {| orphaned=orphaned; nonOrphaned=nonOrphaned|}
    return {orphaned, nonOrphaned};
}

// let mapElementsWordAddressesToTimes(elements: IElement [], words: IElement []) =
function mapElementsWordAddressesToTimes(elements: Element [], words: Element []) {
//     for elem in elements do
    for (const elem of elements) {
//         elem.time <- words.[elem.wordAddress].time
        elem.time = words[elem.wordAddress].time;
//         elem.endTime <- words.[elem.endWordAddress].endTime
        elem.endTime = words[elem.endWordAddress].endTime;
    }
//     elements
    return elements;
}


// let getElementsTimestampingSignature(elements:IElement []) =
function getElementsTimestampingSignature(elements:Element []) {
//     let mutable result = ""
    let result = "";
//     for element in elements do
    for (const element of elements) {
//         result <- result + element.id + "_" + !< element.time
        result += element.id + "_" + element.time;
    }
//     result
    return result;
}

// let getSentenceTimestampingSignature(sentence:IElement, words:IElementList) =
export function getSentenceTimestampingSignature(sentence:Element, words:ElementList) {
//     let wordRange = getSentenceWordIdRange(sentence, words)
    const wordRange = getSentenceWordIdRange(sentence, words);
    // TODO optimize, put some more efficient method on element list to get element array from IdRange?/
//     let sentenceWords = [| for word in words.idRangeAsElementSeq(wordRange) -> word|]
    const sentenceWords = words.idRangeAsElements(wordRange);
//     getElementsTimestampingSignature(sentenceWords)
    getElementsTimestampingSignature(sentenceWords);
}

// let translationId(id:ElementId, translationLanguage:string): ElementId =
export function translationId(id:ElementId, translationLanguage:string): ElementId {
//     let stringId = !< id
    // TODO don't use forward slash
    return (id + "/" + translationLanguage);
}

// TODO direction Enum
// let interleave(elements:IElement [], lookup:ElementId -> IElement, direction) =
export function interleave(elements:Element [], lookup:(id:ElementId) => Element, direction:number) {
//     let result = [||]
    const result = [];
//     for el in elements do
    for (const el of elements) {
//         let found = lookup(el.id)
        const found = lookup(el.id);
//         if !!found then
        if (found) {
//             if direction = -1 then
            if (direction === -1) {
//                 result.append(found)
                result.push(found);
//                 result.append(el)
                result.push(el);
//             else
            } else {
//                 result.append(el)
                result.push(el);
//                 result.append(found)
                result.push(found);
            }
//         else
        } else {
//             result.append(el)
            result.push(el);
        }
    }
//     result
    return result;
}

// let metadataOrder = Precedence([|
// TODO move?
export const metadataOrder = new Precedence([
    "METADATA-URL",
    "NOTES",
    "ASSET-LINKS",
    "CAST",
]);

// TODO needed?
// let languageCodeToLanguage:obj = !< {|
const languageCodeToLanguage = {
//     ``en-US`` = "english"
    ["en-US"]:"english",
//     ``es-US`` = "spanish"
    ["es_US"]:"spanish",
//     ``de-DE`` = "german"
    ["de-DE"]:"german",
//     ``pt-BR`` = "portuguese"
    ["pt-BR"]:"portuguese",
// |}
}

// let cuedWordsFromCues(cues: ChaatInputCue [], words:IElementList):IElementList =
function cuedWordsFromCues(cues: ChaatInputCue [], words:ElementList):ElementList {
//     let wordIds = [|for cue in cues -> cue.wordId|]
    const wordIds = cues.map((cue) => cue.wordId);
//     words.fromIds(wordIds)
    return words.fromIds(wordIds);
}

// let segmentStopWordsFromWords(words:IElementList):IElementList =
function segmentStopWordsFromWords(words:ElementList):ElementList {
//     let allGaps = words.timeIntervals.fromGapIntervals(-1).asIntervalSeq()
    const allGaps = words.timeIntervals.fromGapIntervals(-1).asIntervals();
//     let mutable lastUsedGapEnd = 0
    let lastUsedGapEnd = 0;
//     let wordIndexes = [||]
    const wordIndexes = [];
//     for index, gap in Seq.indexed allGaps do
    for (const [index, gap] of allGaps.entries()) {
//         let gapSize = gap.ends - gap.starts
        const gapSize = gap.ends - gap.starts;
//         if gapSize >= 30 then
        if (gapSize >= 30) {
//             if (gap.starts - lastUsedGapEnd < 1500) && (gapSize < 225) then
            if ((gap.starts - lastUsedGapEnd < 1500) && (gapSize < 225)) {
//                 ()
//             else
            } else {
//                 wordIndexes.append(index)
                wordIndexes.push(index);
//                 lastUsedGapEnd <- gap.ends
                lastUsedGapEnd = gap.ends;
            }
        }
    }
//     words.fromIndexes(wordIndexes)
    return words.fromIndexes(wordIndexes);
}

// let wordIdRangesFromTimeIntervals(intervals:ISorted, words:IElementList) =
function wordIdRangesFromTimeIntervals(intervals:Sorted, words:ElementList) {
//     let wordTimeIntervals = words.timeIntervals
    const wordTimeIntervals = words.timeIntervals;
//     let indexRanges = wordTimeIntervals.mapRangesContained(intervals.asIntervalSeq())
    const indexRanges = wordTimeIntervals.mapRangesContained(intervals.asIntervals());
//     let resultElements:IElement [] = [||]
    const resultElements:Element [] = [];
//     for range in indexRanges do
    for (const range of indexRanges) {
//         if !!range then
        if (range) {
//             let idRange = words.indexRangeToIdRange(!< range)
            const idRange = words.indexRangeToIdRange(range);
//             resultElements.append(makeAdhocWordRange(idRange, words))
            resultElements.push(makeAdhocWordRange(idRange, words));
//         else
        } else {
//             resultElements.append(Null)
            resultElements.push(null);
        }
    }
//     resultElements
    return resultElements;
}

// let filterDeleted(elements:IElement []):IElement [] =
function filterDeleted(elements:Element []):Element [] {
//     let results = [||]
    const results = [];
//     for element in elements do
    for (const element of elements) {
//         if not !!element?deleted then
        if (!element["deleted"]) {
//             results.append(element)
            results.push(element);
        }
    }
//     results
    return results;
}

// let splitWarningElementsByWarningType(idRanges:IdRange [],warningData:obj [], words:IElementList) =
function splitWarningElementsByWarningType(idRanges:IdRange [],warningData:any [], words:ElementList) {
//     let minorWarningRanges = [||]
    const minorWarningRanges = [];
//     let majorWarningRanges = [||]
    const majorWarningRanges = [];
//     for index, range in Array.indexed idRanges do
    for (const [index, range] of idRanges.entries()) {
//         if !!range then
        if (range) {
//             if warningData.[index] = !< "silence" then
            if (warningData[index] === "silence") {
//                 majorWarningRanges.append(range)
                majorWarningRanges.push(range);
//             else
            } else {
//                 minorWarningRanges.append(range)
                minorWarningRanges.push(range);
            }
        }
    }
//     {|
    return {
//         minorWarnings = ElementList(minorWarningRanges, Null, Null, words, Null, Null, Null)
        minorWarnings: new ElementList(minorWarningRanges, null, null, words, null, null, null),
//         majorWarnings = ElementList(majorWarningRanges, Null, Null, words, Null, Null, Null)
        majorWarnings: new ElementList(majorWarningRanges, null, null, words, null, null, null),
//     |}
    };
}

// type IContentRootsBase =
//     abstract episodeMetadataDoc: obj with get, set
//     abstract verbatimDoc: obj with get, set
//     abstract structuralDoc: obj with get, set
//     abstract wordGroupsDoc: obj with get, set
//     abstract translationsDoc: obj with get, set
//     abstract metadataBlocksDoc: obj with get, set
//     abstract episodeKey: string with get, set
//     abstract editEnabled: bool
//     abstract audioLanguageCode: string
//     // abstract translationLanguage: string with get, set // TOOD name learners or translation language or LX?
//     abstract translationLanguage: string
//     abstract chaatMetadataDoc: obj with get, set
//     abstract timestampsDoc: obj with get, set
//     abstract cuesDoc: obj with get, set
//     abstract speechTranscriptDoc: obj with get, set
//     abstract audioAnalysisDoc: obj with get, set
//     abstract audioRegionsDoc: obj with get, set
//     abstract audioMarkersDoc: obj with get, set
//     abstract audioProcessingJobDoc: obj with get, set
//     abstract transcriptionJobDoc: obj with get, set
//     abstract words0: IElementList
//     abstract words1: IElementList
//     abstract audioLanguage: string // TODO use L1 and L2?
//     abstract sentences0: IElement []
//     abstract sentences1: IElement []
//     abstract sentencesList0: IElementList
//     abstract structural0: IElement []
//     abstract wordGroups0: IElement []
//     abstract orphanedWordGroups: IElement []
//     abstract wordGroups1: IElement []
//     abstract translations0: obj
//     abstract metadataBlocks0: IElement []
//     abstract chaatInputCues: ChaatInputCue []
//     abstract cueTimestamps: int []
//     abstract cuedWords: IElementList
//     abstract cueDisplayTimeIntervals: ISorted
//     abstract nonVoiceAudioRegions: obj [] // TODO typing
//     abstract nonVoiceAudioRegionIntervals: ISorted
//     abstract audioMarkers: obj []
//     abstract audioMarkerHitIntervals: ISorted
//     abstract segmentTimeIntervals: ISorted
//     abstract segmentStopWords: IElementList
//     abstract notchTimeIntervals: ISorted
//     abstract silenceTimeIntervals: ISorted
//     abstract interpolatedTimeIntervals: ISorted
//     abstract warningTimeIntervals: ISorted // TODO preface all these with chaat - chaatWarning etc??
//     abstract warningData: string []
//     abstract warnings: IElementList
//     abstract majorWarnings: IElementList
//     abstract minorWarnings: IElementList
//     abstract warningSentenceIds: ElementId []
//     abstract transcriptWords: string []
//     abstract transcriptWordTimeIntervals: ISorted
//     abstract sentenceTimestampingSignatures: obj
//     abstract audioUrls: obj
//     abstract warningSuppressions: JS.Set<ElementId>
//     abstract chaatSignoffs: JS.Set<string>
//     abstract chaatUnsignedoffSentences: IElementList

//     // TODO split the below to internal props interface? or develop @computedFunc approach for interval @computed?


// type ContentRootsBase0() as s0 =
export class ContentRootsBase {

//     let self:IContentRootsBase = !< s0

//     (* @observable *)
//     let episodeMetadataDoc = Null
    @observable.ref episodeMetadataDoc = null;

//     (* @observable *)
//     let verbatimDoc = Null
    @observable.ref verbatimDoc = null;

//     (* @observable *)
//     let structuralDoc = Null
    @observable.ref structuralDoc = null;

//     (* @observable *)
//     let wordGroupsDoc = Null
    @observable.ref wordGroupsDoc = null;

//     (* @observable *)
//     let translationsDoc = Null
    @observable.ref translationsDoc = null;

//     (* @observable *)
//     let metadataBlocksDoc = Null
    @observable.ref metadataBlocksDoc = null;

//     (* @observable *)
//     let warningSuppressionsDoc = Null
    @observable.ref warningSuppressionsDoc = null;

//     (* @observable *)
//     let episodeKey = ""
    @observable.ref episodeKey = "";

//     let audioLanguageCode = "es-US" // TODO get from the chaat metadata doc
    audioLanguageCode = "es-US" // TODO get from the chaat metadata doc

//     // (* @observable *)
//     // let translationLanguage = "english" // TODO in first version is constant per episode and comes from episode metadata doc

//     (* @observable *)
//     let timestampsDoc = Null
    @observable.ref timestampsDoc = null;

//     (* @observable *)
//     let cuesDoc = Null
    @observable.ref cuesDoc = null;

//     (* @observable *)
//     let speechTranscriptDoc = Null
    @observable.ref speechTranscriptDoc = null;

//     (* @observable *)
//     let audioAnalysisDoc = Null
    @observable.ref audioAnalysisDoc = null;

//     (* @observable *)
//     let audioRegionsDoc = Null
    @observable.ref audioRegionsDoc = null;

//     (* @observable *)
//     let audioMarkersDoc = Null
    @observable.ref audioMarkersDoc = null;

//     (* @observable *)
//     let chaatMetadataDoc = Null
    @observable.ref chaatMetadataDoc = null;

//     (* @observable *)
//     let audioProcessingJobDoc = Null
    @observable.ref audioProcessingJobDoc = null;

//     (* @observable *)
//     let transcriptionJobDoc = Null
    @observable.ref transcriptionJobDoc = null;

//     (* @observable *)
//     let chaatSignoffsDoc = Null
    @observable.ref chaatSignoffsDoc = null;

    constructor() {
        makeObservable(this);
    }

//     let wordIdMapping():IndexMapping =
    get wordIdMapping():IndexMapping {
//         verbatimDoc?wordIdMapping
        return this.verbatimDoc.wordIdMapping;
    }

//     let editEnabled() =
    get editEnabled() {
//         episodeMetadataDoc?editEnabled
        return this.episodeMetadataDoc.editEnabled;
    }

    // TODO as these are not keep alive make sure words0/words1 are only accessed in workable contexts
//     (* @computed *)
//     let words0():IElementList =
    @computed
    get words0():ElementList {
        // TODO change wordIdMapping to wordIndexMapping everywhere
//         let mapping = wordIdMapping()
        const mapping = this.wordIdMapping;
//         ElementList(verbatimDoc?words, episodeKey, Null, Null, mapping, Null, !< (getIndex mapping))
        return new ElementList(
            this.verbatimDoc.words,
            this.episodeKey,
            null,
            null,
            mapping,
            null,
            (id) => getIndex(mapping,id),
      );
    // TODO seems like wordAddress is not mapped on word objects, need it?
    }


//     (* @computed *)
//     let words1():IElementList =
    @computed
    get words1():ElementList {
//         let wordIdMapping: IndexMapping = verbatimDoc?wordIdMapping
        const wordIdMapping: IndexMapping = this.verbatimDoc.wordIdMapping;
//         let words:IElement [] = verbatimDoc?words
        const words:Element [] = this.verbatimDoc.words;
//         let startTimes:int [] = timestampsDoc?wordTimeIntervals?startTimes
        const startTimes:number [] = this.timestampsDoc.wordTimeIntervals.startTimes;
//         let endTimes:int [] = timestampsDoc?wordTimeIntervals?endTimes
        const endTimes:number [] = this.timestampsDoc.wordTimeIntervals.endTimes;
//         for i in 0 .. words.lastIndex do
        for (let i=0; i<= words.length -1; i++) {
//             let word = words.[i]
            const word = words[i];
//             word.time <- startTimes.[i]
            word.time = startTimes[i];
//             word.endTime <- endTimes.[i]
            word.endTime = endTimes[i];
        }
//         ElementList(words, episodeKey, Null, Null, wordIdMapping, Null, !< (getIndex wordIdMapping))
        return new ElementList(
            words,
            this.episodeKey,
            null,
            null,
            wordIdMapping,
            null,
            (id) => (getIndex(wordIdMapping, id)),
        );
    }

//     let audioLanguage():string =
    get audioLanguage():string {
//         "spanish" // TODO lookup with audio language code
        return "spanish"; // TODO lookup with audio language code
    }

//     (* @computed *)
//     let sentences0() =
    @computed
    get sentences0() {
        const sentences:Element [] = Object.values(this.verbatimDoc.sentences);
//         let activeSentences = filterDeleted(sentences)
        const activeSentences = filterDeleted(sentences);
//         mapSentenceAnchorsToWordAddresses(activeSentences, self.words0)
        mapSentenceAnchorsToWordAddresses(activeSentences, this.words0);
//         activeSentences
        return activeSentences;
    }

//     (* @computed *)
//     let sentences1() =
    @computed
    get sentences1() {
//         mapElementsWordAddressesToTimes(self.sentences0, self.words1.elements)
        return mapElementsWordAddressesToTimes(this.sentences0, this.words1.elements);
    }

//     (* @computed *)
//     let sentencesList0(): IElementList =
    @computed
    get sentencesList0() {
//         let sentences: IElement [] = self.sentences0 |> Array.copy
        const sentences: Element [] = [...this.sentences0];
//         sortElements(sentences)
        sortElements(sentences);
//         ElementList(sentences, episodeKey, Null, Null, Null, Null, Null )
        return new ElementList(
            sentences,
            this.episodeKey,
            null,
            null,
            null,
            null,
            null
        );
    }

//     (* @computed *)
//     let structural0() =
    @computed
    get structural0() {
        // TODO but null test conditional in other x0 funcs?
//         if isNull(structuralDoc) then
        if (isNull(this.structuralDoc)) {
//             [||]
            return [];
//         else
        } else {
//             let structural:IElement []= !< JSObject.values(structuralDoc?structural)
            const structural:Element []= Object.values(this.structuralDoc.structural);
//             let activeStructural = filterDeleted(structural)
            const activeStructural = filterDeleted(structural);
//             mapStructuralAnchorsToWordAddresses(activeStructural, self.words0, self.sentencesList0)
            mapStructuralAnchorsToWordAddresses(activeStructural, this.words0, this.sentencesList0.wordIntervals);
//             activeStructural
            return activeStructural;
        }
    }

//     (* @computedFunc *)
//     let orphanedAndNonOrphanedWordGroups() =
    @computed
    get orphanedAndNonOrphanedWordGroups() {
//         let wordGroups: IElement [] = !< JSObject.values(wordGroupsDoc?wordGroups)
        const wordGroups: Element [] = Object.values(this.wordGroupsDoc.wordGroups);
//         let activeWordGroups = filterDeleted(wordGroups)
        const activeWordGroups = filterDeleted(wordGroups);
//         mapWordGroupAnchorsToWordAddresses(activeWordGroups, wordIdMapping())
        mapWordGroupAnchorsToWordAddresses(activeWordGroups, this.wordIdMapping)
//         partitionOrphanedWordGroups(activeWordGroups)
        return partitionOrphanedWordGroups(activeWordGroups);
    }

//     let orphanedWordGroups():IElement [] =
    get orphanedWordGroups():Element [] {
//         orphanedAndNonOrphanedWordGroups().orphaned
        return this.orphanedAndNonOrphanedWordGroups.orphaned
    }

//     let wordGroups0(): IElement [] =
    get wordGroups0(): Element [] {
//         orphanedAndNonOrphanedWordGroups().nonOrphaned
        return this.orphanedAndNonOrphanedWordGroups.nonOrphaned
    }

//     (* @computed *)
//     let wordGroups1(): IElement [] =
    @computed
    get wordGroups1(): Element [] {
//         self.wordGroups0
        // TODO put time data on word groups
        return this.wordGroups0;
    }

//     let translationLanguage():string =
    get translationLanguage():string {
//         episodeMetadataDoc?learnersLanguage
        return this.episodeMetadataDoc.learnersLanguage;
    }

//     // (* @computed *)
//     // let threads0() =
//     //     let result = obj()
//     //     let timeProjection o = o?timestamp
//     //     for thread in JSObject.values(threadsDoc?threads) do
//     //         let copied = JS.Object.assign(obj(), thread)
//     //         // TODO use strongly typed struct for messages?
//     //         let messages = JSObject.values(copied?messages)
//     //         Array.sortInPlaceBy timeProjection messages
//     //         copied?messages <- messages
//     //         result.[copied?id] <- copied
//     //         copied?resolved <- false
//     //         copied?withMessages <- !!messages && messages.Length > 0
//     //         if messages.Length > 0 then
//     //             let lastMessage:string = !< messages.[messages.lastIndex]?message
//     //             if substringIndexOf(lastMessage, "(resolved)") <> -1 then
//     //                 copied?resolved <- true

//     //     result

//     (* @computed *)
//     let translations0() =
    @computed
    get translations0() {
//         let result = translationsDoc?translations.[self.translationLanguage]
        const result = this.translationsDoc.translations[this.translationLanguage];
//         if !!result then result else obj()
        return result ?? {};
    }

//     (* @computed *)
//     let metadataBlocks0():IElement [] =
    @computed
    get metadataBlocks0():Element [] {
        // TODO presort?
//         !< JSObject.values(metadataBlocksDoc?metadata)
        return Object.values(this.metadataBlocksDoc.metadata);
    }

//     (* @computedKeepAlive *)
//     let warningSuppressions(): JS.Set<ElementId> =
    @computed({ keepAlive: true })
    get warningSuppressions(): Set<ElementId> {
//         if isNull(warningSuppressionsDoc) then
        if (isNull(this.warningSuppressionsDoc)) {
//             JS.Constructors.Set.Create([||])
            return new Set([]);
//         else
        } else {
//             JS.Constructors.Set.Create(warningSuppressionsDoc?suppressions)
            return new Set(this.warningSuppressionsDoc.suppressions);
        }
    }

//     (* @computedKeepAlive *)
//     let chaatInputCues():ChaatInputCue [] = // TODO includes all cues not just Chaat input type
    @computed( { keepAlive: true })
    get chaatInputCues():ChaatInputCue [] { // TODO includes all cues not just Chaat input type
//         let result:ChaatInputCue [] = !< JSObject.values(cuesDoc?cues)
        const result:ChaatInputCue [] = <ChaatInputCue []>[...Object.values(this.cuesDoc.cues)];
//         Array.sortBy (fun (cue) -> cue.timestamp) result
        numberProjectionSort(result, (cue:ChaatInputCue) => cue.timestamp);
        return result;
    }


//     (* @computedKeepAlive *)
//     let cuedWords():IElementList =
    @computed( { keepAlive: true })
    get cuedWords():ElementList {
//         cuedWordsFromCues(self.chaatInputCues, self.words1)
        return cuedWordsFromCues(this.chaatInputCues, this.words1);
    }

//     (* @computedKeepAlive *)
//     let cueTimestamps(): int [] =
    @computed( { keepAlive: true } )
    get cueTimestamps(): number [] {
//         [|for cue in self.chaatInputCues -> cue.timestamp|]
        return this.chaatInputCues.map((cue) => cue.timestamp);
    }


//     (* @computedKeepAlive *)
//     let cueDisplayTimeIntervals() =
    @computed( { keepAlive: true } )
    get cueDisplayTimeIntervals():Sorted {
//         let timestamps = cueTimestamps()
        const timestamps = this.cueTimestamps;
//         let startPoints = timestamps |> Array.map (fun (t) -> t - 2)
        const startPoints = timestamps.map( (t) => t - 2);
//         let endPoints = timestamps |> Array.map (fun (t) -> t + 10)
        const endPoints = timestamps.map((t) => t + 10);
//         Sorted({startPoints=startPoints; endPoints=endPoints; domainStart=0; domainEnd=0})
        return new Sorted(startPoints, endPoints);
    }

//     (* @computedKeepAlive *)
//     let segmentTimeIntervals(): ISorted =
    @computed( { keepAlive: true } )
    get segmentTimeIntervals(): Sorted {
//         self.words1.timeIntervals.fromGapIntervals(20)
        return this.words1.timeIntervals.fromGapIntervals(20);
    }

//     (* @computedKeepAlive *)
//     let segmentStopWords():IElementList =
    @computed( { keepAlive: true } )
    get segmentStopWords():ElementList {
//         segmentStopWordsFromWords(self.words1)
        return segmentStopWordsFromWords(this.words1);
    }

//     (* @computedKeepAlive *)
//     let notchTimeIntervals(): ISorted =
    @computed( { keepAlive: true } )
    get notchTimeIntervals(): Sorted {
//         let intervals = audioAnalysisDoc?notchTimeIntervals
        const intervals = this.audioAnalysisDoc.notchTimeIntervals;
//         Sorted({ startPoints=intervals?startTimes; endPoints=intervals?endTimes; domainStart=0; domainEnd=0 })
        return new Sorted(intervals.startTimes, intervals.endTimes);
    }

//     (* @computedKeepAlive *)
//     let silenceTimeIntervals():ISorted =
    @computed( { keepAlive: true } )
    get silenceTimeIntervals():Sorted {
//         let notches = self.notchTimeIntervals
        const notches = this.notchTimeIntervals;
//         let silences = [|for interval in notches.asIntervalSeq() do if size(interval) >=125 then interval|]
        const silences = [];
        for (const interval of notches.asIntervals()) {
            if (size(interval) >=125) {
                silences.push(interval);
            }
        }
//         fromIntervals(silences)
        return fromIntervals(silences);
    }

//     (* @computedKeepAlive *)
//     let nonVoiceAudioRegions(): obj [] =
    @computed( { keepAlive: true } )
    get nonVoiceAudioRegions(): any [] {
//         let audioRegions = JS.Object.values(audioRegionsDoc?regions)
        const audioRegions = [...Object.values(this.audioRegionsDoc.regions)];
//         Array.sortBy ( fun (r) -> int(r?startTime)) audioRegions
        numberProjectionSort(audioRegions, (r) => r.startTime);
        return audioRegions;
    }

//     (* @computedKeepAlive *)
//     let nonVoiceAudioRegionIntervals(): ISorted =
    @computed( { keepAlive: true } )
    get nonVoiceAudioRegionIntervals(): Sorted {
//         let intervals = [|for region in self.nonVoiceAudioRegions -> {Interval.starts=region?startTime; ends=region?endTime}|]
        const intervals = this.nonVoiceAudioRegions.map( (r) => {return {starts:r.startTime, ends:r.endTime}});
        // TODO figure out how to do access qualified by Sorted -> Sorted.fromIntervals(..)
//         fromIntervals(intervals)
        return fromIntervals(intervals);
//         // Sorted.fromIntervals(intervals)
    }

//     (* @computedKeepAlive *)
//     let audioMarkers():obj [] =
    @computed( { keepAlive: true } )
    get audioMarkers():any [] {
//         let markers = JS.Object.values(audioMarkersDoc?markers)
        const markers = [...Object.values(this.audioMarkersDoc.markers)];
//         Array.sortBy ( fun (r) -> int(r?time)) markers
        numberProjectionSort(markers, (m) => m.time);
        return markers;
    }

//     (* @computedKeepAlive *)
//     let audioMarkerHitIntervals(): ISorted =
    @computed( { keepAlive: true } )
    get audioMarkerHitIntervals(): Sorted {
//         let intervals = [|for marker in self.audioMarkers-> {Interval.starts=marker?time; ends=int(marker?time) + 35}|]
        const intervals = this.audioMarkers.map( (m) => { return {starts:m.time, ends:m.time + 35}});
//         fromIntervals(intervals)
        return fromIntervals(intervals);
    }

//     (* @computedKeepAlive *)
//     let interpolatedTimeIntervals(): ISorted =
    @computed( { keepAlive: true } )
    get interpolatedTimeIntervals(): Sorted {
        // TODO consistent naming
//         let interpolatedIntervals0 = timestampsDoc?interpolationTimeIntervals
        const interpolatedIntervals0 = this.timestampsDoc.interpolationTimeIntervals;
//         Sorted({
        return new Sorted(interpolatedIntervals0.startTimes,interpolatedIntervals0.endTimes);
//             domainStart=0; domainEnd=0
//         })
    }

//     (* @computedKeepAlive *)
//     let warningTimeIntervals(): ISorted =
    @computed( { keepAlive: true } )
    get warningTimeIntervals(): Sorted {
//         let warningIntervals0 = timestampsDoc?warningTimeIntervals
        const warningIntervals0 = this.timestampsDoc.warningTimeIntervals;
//         Sorted({
        return new Sorted(warningIntervals0.startTimes, warningIntervals0.endTimes);
//             domainStart=0; domainEnd=0
//         })
    }

//     (* @computedKeepAlive *)
//     let sentenceTimestampingSignatures() =
    @computed( { keepAlive: true } )
    get sentenceTimestampingSignatures() {
//         let words = self.words1
        const words = this.words1;
//         let sentences = self.sentences1
        const sentences = this.sentences1;
//         let result = obj()
        const result = {};
//         for sentence in sentences do
        for (const sentence of sentences) {
//             result.[sentence.id] <- getSentenceTimestampingSignature(sentence, words)
            result[sentence.id] = getSentenceTimestampingSignature(sentence, words);
        }
//         result
        return result;
    }

//     (* @computedKeepAlive *)
//     let chaatSignoffs(): JS.Set<ElementId> =
    @computed( { keepAlive: true } )
    get chaatSignoffs(): Set<ElementId> {
//         if isNull(chaatSignoffsDoc) then
        if (isNull(this.chaatSignoffsDoc)) {
            return new Set([]);
//             JS.Constructors.Set.Create([||])
//         else
        } else {
//             JS.Constructors.Set.Create(chaatSignoffsDoc?signoffs)
            return new Set(this.chaatSignoffsDoc.signoffs);
        }
    }

//     (* @computedKeepAlive *)
//     let chaatUnsignedoffSentences():IElementList =
    @computed( { keepAlive: true } )
    get chaatUnsignedoffSentences():ElementList {
//         let result = [||]
        const result = [];
//         let sentences = self.sentences1
        const sentences = this.sentences1;
//         let signatures = self.sentenceTimestampingSignatures
        const signatures = this.sentenceTimestampingSignatures;
//         let signoffs = self.chaatSignoffs
        const signoffs = this.chaatSignoffs;
//         for sentence in sentences do
        for (const sentence of sentences) {
//             let signature:string = !< signatures.[sentence.id]
            const signature:string = signatures[sentence.id];
//             if not (signoffs.has(signature)) then
            if (!signoffs.has(signature)) {
//                 result.append(sentence)
                result.push(sentence);
            }
        }
//         ElementList(result, episodeKey, Null, self.words1, Null, Null, Null)
        return new ElementList(
            result,
            this.episodeKey,
            null,
            this.words1,
            null,
            null,
            null,
        );
    }

//     let warningData(): string [] =
    get warningData(): string [] {
//         timestampsDoc?warningData
        return this.timestampsDoc.warningData;
    }

//     (* @computedKeepAlive *)
//     let warnings(): IElementList =
    @computed( { keepAlive: true } )
    get warnings(): ElementList {
//         let warningElements = wordIdRangesFromTimeIntervals(self.warningTimeIntervals, self.words1)
        const warningElements = wordIdRangesFromTimeIntervals(this.warningTimeIntervals, this.words1);
//         let warningData:string [] = timestampsDoc?warningData
        const warningData:string [] = this.timestampsDoc.warningData;
//         let matchedWarningElements = [||]
        const matchedWarningElements = [];
//         for i in 0 .. warningElements.lastIndex do
        for (let i=0; i < warningElements.length - 1; i++) {
//             let warningElement = warningElements.[i]
            const warningElement = warningElements[i];
//             if !!warningElement then
            if (warningElement) {
//                 let data = warningData.[i]
                const data = warningData[i];
//                 let subKind = if data = "silences" then "MAJOR" else "MINOR"
                const subKind = (data === "silences") ? "MAJOR":"MINOR";
//                 warningElement?subKind <- subKind
                warningElement.subKind = subKind
//                 matchedWarningElements.append(warningElement)
                matchedWarningElements.push(warningElement);
            }
        }

//         ElementList(matchedWarningElements, episodeKey, Null, self.words1, Null, Null, Null)
        return new ElementList(
            matchedWarningElements,
            this.episodeKey,
            null,
            this.words1,
            null,
            null,
            null
        );
    }


//     (* @computedKeepAlive *)
//     let majorWarnings(): IElementList =
    @computed( { keepAlive: true } )
    get majorWarnings(): ElementList {
        // TODO use subkind filter?
//         self.warnings.filter((fun (e) -> e.subKind = "MAJOR"))
        return this.warnings.filter((e) => e.subKind === "MAJOR")
    }

//     (* @computedKeepAlive *)
//     let minorWarnings(): IElementList =
    @computed( { keepAlive: true } )
    get minorWarnings(): ElementList {

//         self.warnings.filter((fun (e) -> e.subKind = "MINOR"))
        return this.warnings.filter((e) => e.subKind === "MINOR")
    }

//     (* @computedKeepAlive *)
//     let warningSentenceIds() =
    @computed( { keepAlive: true } )
    get warningSentenceIds(): ElementId [] {
//         let warnIntervals = self.warningTimeIntervals
        const warnIntervals = this.warningTimeIntervals;
        const result = [];
//         [| for s in self.sentences1 do if warnIntervals.hasIntersecting(s.time, s.endTime) then s.id |]
        for (const s of this.sentences1) {
            if (warnIntervals.hasIntersecting(s.time, s.endTime)) {
                result.push(s.id);
            }
        }
        return result;
    }

//     (* @computedKeepAlive *)
    @computed( { keepAlive: true } )
//     let transcriptWords():string [] =
    get transcriptWords():string [] {
//         speechTranscriptDoc?transcriptWords
        return this.speechTranscriptDoc.transcriptWords;
    }

//     (* @computedKeepAlive *)
//     let transcriptWordTimeIntervals():ISorted =
    @computed( { keepAlive: true } )
    get transcriptWordTimeIntervals():Sorted {
//         let intervals = speechTranscriptDoc?transcriptWordTimeIntervals
        const intervals = this.speechTranscriptDoc.transcriptWordTimeIntervals;
//         Sorted({ startPoints=intervals?startTimes; endPoints=intervals?endTimes; domainStart=0; domainEnd=0 })
        return new Sorted(intervals.startTimes, intervals.endTimes);
    }


//     (* @prop *)
//     let audioUrls() =
    get audioUrls() {
//         let audioStorageId = audioProcessingJobDoc?m16000AudioId
        const audioStorageId = this.audioProcessingJobDoc.m16000AudioId;
        // TODO put base url to cloud storage bucket somewhere else
//         let downsampledAudioURL = $"https://storage.googleapis.com/jw-timestamper/{audioStorageId}"
        const downsampledAudioURL = `https://storage.googleapis.com/jw-timestamper/${audioStorageId}`;

//         {|
        return {
//             audioUrl = chaatMetadataDoc?finalAudioUrl
            audioUrl:this.chaatMetadataDoc.finalAudioUrl,
//             transcribeAudioUrl = downsampledAudioURL
            transcribeAudioUrl:downsampledAudioURL,
//             noMusicAudioUrl = chaatMetadataDoc?audioNoMusicUrl
            noMusicAudioUrl:this.chaatMetadataDoc.audioNoMusicUrl,
//         |}
        };
    }


//     do autoBindInterface()
}

// let ContentRootsBase():IContentRootsBase = !< ContentRootsBase0()
