// open Fable.Core
// open Fable.Core.JsInterop
// open BasicTypes
// open ElementList
// open ElementTypes
// open Signal
// open Tracker
// open JSBoilerplate

import { ElementId, ElementKind, getKindFromId } from "../basic-types";
import { EKinds } from "../elements/element-kinds";
import { ElementList, EmptyElementList } from "../elements/element-list";
import { Tracker } from "./tracker";


// type ITracking =
//     abstract setElements: IElementList -> unit
//     abstract getTracker: ElementId -> ITracker
//     abstract getTrackerWithKind: ElementKind -> ITracker
//     abstract currentIsUnder: ElementKind -> ElementId
//     abstract isUnder: ElementId -> bool
//     abstract isBefore: ElementId -> bool
//     abstract isVisited: ElementId -> bool
//     abstract isUnderSignal: ElementId -> ISignal
//     abstract isBeforeSignal: ElementId -> ISignal
//     abstract isVisitedSignal: ElementId -> ISignal
//     abstract changedSignal: ElementId -> ISignal
//     abstract anyIsChangedSignal: ElementKind -> ISignal

// type Tracking0(positionFunction0:unit -> int) =
export class Tracking {
    positionFunction: () => number;
//     let trackers:JS.Map<ElementKind, ITracker> = JS.Constructors.Map.Create()
    trackers: Map<ElementKind, Tracker>;
//     let mutable elements:IElementList = EmptyElementList
    elements:ElementList;

    constructor(positionFunction0:() => number) {
//     let positionFunction = positionFunction0
        this.positionFunction = positionFunction0;
//     let trackers:JS.Map<ElementKind, ITracker> = JS.Constructors.Map.Create()
        this.trackers = new Map();
//     let mutable elements:IElementList = EmptyElementList
        this.elements = EmptyElementList;
    }

//     let setElements(newElements:IElementList) =
    setElements(newElements:ElementList) {
//         if !!elements && !!newElements && elements.episodeKey <> newElements.episodeKey then
        if (this.elements && newElements && this.elements.episodeKey !== newElements.episodeKey) {
//             for tracker in trackers.values() do
            for(const tracker of this.trackers.values()) {
//                 tracker.dispose()
                tracker.dispose();
            }
//             trackers.clear()
            this.trackers.clear();
//         else
        } else {
//             for kind, tracker in trackers.entries() do
            for(const [kind, tracker] of this.trackers.entries()) {
//                 tracker.setElements(newElements.getKindSubList(kind))
                tracker.setElements(newElements.getKindSubList(kind));
            }
        }
//         elements <- newElements
        this.elements = newElements;
    }

//     let rec createTrackerWithKind(kind): ITracker =
    createTrackerWithKind(kind:ElementKind): Tracker {
//         let kindList = elements.getKindSubList(kind)
        const kindList = this.elements.getKindSubList(kind);
//         if kind = WORD then
        if (kind === EKinds.WORD) {
//             let tracker = Tracker(!< positionFunction, positionFunction)
            const tracker = new Tracker(this.positionFunction, this.positionFunction);
//             tracker.setElements(kindList)
            tracker.setElements(kindList);
//             tracker
            return tracker;
//         else
        } else {
//             let wordTracker = getTrackerWithKind0(WORD)
            const wordTracker = this.getTrackerWithKind0(EKinds.WORD);
//             let tracker = Tracker(!< (fun () -> wordTracker.anyIsChangedSignal().watch()), positionFunction)
            const tracker = new Tracker(() => wordTracker.anyIsChangedSignal.watch(), this.positionFunction);
//             tracker.setElements(kindList)
            tracker.setElements(kindList);
//             tracker
            return tracker;
        }

    }

//     and getTrackerWithKind0(kind):ITracker =
    getTrackerWithKind0(kind:ElementKind):Tracker {
//         let tracker: ITracker = trackers.get(kind)
        const tracker = this.trackers.get(kind)
//         if !!tracker then
        if (tracker) {
//             tracker
            return tracker;
//         else
        } else {
//             let tracker = createTrackerWithKind(kind)
            const tracker = this.createTrackerWithKind(kind);
//             trackers.set(kind, tracker)
            this.trackers.set(kind, tracker);
//             tracker
            return tracker;
        }
    }

//     let getTrackerWithKind(kind) =
    getTrackerWithKind(kind:ElementKind) {
//         getTrackerWithKind0(kind)
        return this.getTrackerWithKind0(kind);
    }

//     let getTracker(elementId) =
    getTracker(elementId:ElementId) {
//         let kind = getKindFromId(elementId)
        const kind = getKindFromId(elementId)
//         getTrackerWithKind(kind)
        return this.getTrackerWithKind(kind);
    }

//     let currentIsUnder(kind) =
    currentIsUnder(kind:ElementKind) {
//         let tracker = getTrackerWithKind(kind)
        const tracker = this.getTrackerWithKind(kind);
//         tracker.currentIsUnder()
        return tracker.currentIsUnder();
    }

//     let isUnder(elementId) =
    isUnder(elementId:ElementId) {
//         getTracker(elementId).isUnder(elementId)
        return this.getTracker(elementId).isUnder(elementId);
    }

//     let isBefore(elementId) =
    isBefore(elementId:ElementId) {
//         getTracker(elementId).isBefore(elementId)
        return this.getTracker(elementId).isBefore(elementId);
    }

//     let isVisited(elementId) =
    isVisited(elementId:ElementId) {
//         getTracker(elementId).isVisited(elementId)
        return this.getTracker(elementId).isVisited(elementId);
    }

//     let isUnderSignal(elementId) =
    isUnderSignal(elementId:ElementId) {
//         getTracker(elementId).isUnderSignal(elementId)
        return this.getTracker(elementId).isUnderSignal(elementId);
    }

//     let isBeforeSignal(elementId) =
    isBeforeSignal(elementId:ElementId) {
//         getTracker(elementId).isBeforeSignal(elementId)
        return this.getTracker(elementId).isBeforeSignal(elementId);
    }

//     let isVisitedSignal(elementId) =
    isVisitedSignal(elementId:ElementId) {
//         getTracker(elementId).isVisitedSignal(elementId)
        return this.getTracker(elementId).isVisitedSignal(elementId);
    }

//     let changedSignal(elementId) =
    changedSignal(elementId:ElementId) {
//         getTracker(elementId).changedSignal(elementId)
        return this.getTracker(elementId).changedSignal(elementId);
    }

//     let anyIsChangedSignal(kind) =
    anyIsChangedSignal(kind:ElementKind) {
//         let tracker = getTrackerWithKind(kind)
        const tracker = this.getTrackerWithKind(kind);
//         tracker.anyIsChangedSignal()
        return tracker.anyIsChangedSignal;
    }
}
//     do autoBindInterface()


// let Tracking(positionFunction):ITracking = !< Tracking0(positionFunction)
