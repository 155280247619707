
// open AudioMarkerActions
// open BasicTypes
// open AppRoot

import { chaatToolModel, mutationActions, transportState } from "../app-root";
import { AudioMarkerActions } from "../masala-lib/editorial/models/actions/audio-marker-actions";

// type AudioMarkerActions1() =
export class ChaatAudioMarkerActions extends AudioMarkerActions {
//     inherit AudioMarkerActions0()

//     override __.mutationActions with get() = mutationActions
    get mutationActions() {
        return mutationActions;
    }
//     override __.currentAudioPosition with get() = transportState.audioPosition
    get currentAudioPosition() {
        return transportState.audioPosition;
    }
//     override __.currentAudioMarkers with get() = chaatToolModel.audioMarkers
    get currentAudioMarkers() {
        return chaatToolModel.audioMarkers;
    }
//     override __.currentAudioMarkerHitIntervals with get() = chaatToolModel.audioMarkerHitIntervals
    get currentAudioMarkerHitIntervals() {
        return chaatToolModel.audioMarkerHitIntervals;
    }
}

// let AudioMarkerActions():IAudioMarkerActions = !< AudioMarkerActions1()
