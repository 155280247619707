// open Fable.Core
// open Fable.Core.JsInterop
// open BasicTypes
// open ElementList
// open Browser
// open JSBoilerplate
// open AdhocWordRange

import { Alert } from '../../../alert-messages';
import { Element, ElementId, elementIdToDomId, isNil } from '../../../basic-types';
import { AdhocWordRange, isWordRange } from '../../../elements/ad-hoc-word-range';
import { ElementList } from '../../../elements/element-list';

// let renderStylesForDomId(domId: string, styles: string [], remove: bool) =
export function renderStylesForDomId(domId: string, styles: string [], remove: boolean) {
//     let node = document.getElementById(domId)
    const node = document.getElementById(domId);
//     if !!node then
    if (node) {
//         if remove then
        if (remove) {
//             node.classList.remove(styles)
            node.classList.remove(...styles);
//         else
        } else {
//             node.classList.add(styles)
            node.classList.add(...styles);
        }
    }
}

// TODO Enum for add/remove??
// let renderStylesForElement(element:IElement, styles: string [], elementList:IElementList, domScope, remove) =
export function renderStylesForElement(element:Element, styles: string [], elementList:ElementList, domScope, remove:boolean) {
//     if isWordRange(element) then
    if (isWordRange(element)) {
//         let adhocWordRange = !< element
        const adhocWordRange = <AdhocWordRange><unknown>element;
//         let range = adhocWordRange.range
        const range = adhocWordRange.range;
//         let wordIds = elementList.words.idRangeAsIdSeq(range)
        const wordIds = elementList.words.idRangeAsIds(range);
//         for wordId in wordIds do
        for (const wordId of wordIds) {
//             let domId = elementIdToDomId domScope wordId
            const domId = elementIdToDomId(domScope, wordId);
//             renderStylesForDomId(domId, styles, remove)
            renderStylesForDomId(domId, styles, remove);
        }
//     else
    } else {
//         let domId = elementIdToDomId domScope element.id
        const domId = elementIdToDomId(domScope, element.id);
//         renderStylesForDomId(domId, styles, remove)
        renderStylesForDomId(domId, styles, remove);
    }
}

// let simpleRenderStylesForElementId(id:ElementId, styles: string [], remove) =
export function simpleRenderStylesForElementId(id:ElementId, styles: string [], remove:boolean) {
//     let domId = elementIdToDomId "" id
    const domId = elementIdToDomId("", id);
//     renderStylesForDomId(domId, styles, remove)
    renderStylesForDomId(domId, styles, remove);
}

// let renderStylesForElementList(elementList: IElementList, styles, domScope, remove) =
export function renderStylesForElementList(elementList: ElementList, styles:string [], domScope, remove:boolean) {
//     for element in elementList.elements do
    for (const element of elementList.elements) {
        renderStylesForElement(element, styles, elementList, domScope, remove);
    }
}

// type StyleLayer = {
export type StyleLayer = {
//     styles: string []
    styles: string [];
//     elements: IElementList
    elements: ElementList;
//     domScope: string // TODO use? OR MAYBE NEEDS TO BE FUNCTION id -> dom id to handle mapping to correct sentence element etc???
    domScope: string, // TODO use? OR MAYBE NEEDS TO BE FUNCTION id -> dom id to handle mapping to correct sentence element etc???
//     supportWordRanges: bool
    supportWordRanges: boolean,
//     mutable stylesString: string
    stylesString: string,
// }
}

// let computeStylesStringForLayer(layer:StyleLayer) =
export function computeStylesStringForLayer(layer:StyleLayer) {
//     if not(!!layer.stylesString) then
    if (!layer.stylesString) {
//         layer.stylesString <- layer.styles |> String.concat " "
        layer.stylesString = layer.styles.join(" ");
    }
//     layer.stylesString
    return layer.stylesString;
}

// type IStyleLayersRenderer =
//     abstract getStyleForWordAddress: int -> string
//     abstract getStyleForElement: ElementId -> string
//     abstract setStyleLayers: JS.Map<string, StyleLayer> -> unit
//     abstract renderStyleLayers: episodeKey:string * layers: JS.Map<string, StyleLayer> -> unit


// type StyleLayersRenderer0() =
export class StyleLayersRenderer {

//     let mutable episodeKey = ""
    episodeKey = "";
//     let domScope: string = "" // TODO
    domScope: string = ""; // TODO
//     let mutable layers:JS.Map<string, StyleLayer> = JS.Constructors.Map.Create()
    layers:Map<string, StyleLayer> = new Map();
//     let mutable layerStack: StyleLayer [] = [||]
    layerStack: StyleLayer [] = [];

//     let getStyleForWordAddress(index) =
    getStyleForWordAddress(index) {
//         let mutable style = ""
        let style = "";
//         for layer in layerStack do
        for (const layer of this.layerStack) {
//             if layer.supportWordRanges then
            if (layer.supportWordRanges) {
//                 let found = layer.elements.getElementContainingWordAddress(index)
                const found = layer.elements.getElementContainingWordAddress(index);
//                 if !!found && isWordRange(found) then
                if (found && isWordRange(found)) {
//                     style <- style + " " + computeStylesStringForLayer(layer)
                    style += " " + computeStylesStringForLayer(layer);
                }
            }
        }
//         style
        return style;
    }

//     let getStyleForElement(id:ElementId) =
    getStyleForElement(id:ElementId) {
//         let styles: string [] = [||]
        const styles: string [] = [];
//         for layer in layerStack do
        for (const layer of this.layerStack) {
//             if layer.elements.hasElement(id) then
            if (layer.elements.hasElement(id)) {
//                 styles.extend(layer.styles)
                styles.push(...layer.styles);
            }
        }
//         styles |> String.concat " "
        return styles.join(" ");
    }

//     let setStyleLayers(layers0:JS.Map<string, StyleLayer>) =
    setStyleLayers(layers0:Map<string, StyleLayer>) {
//         layers <- layers0
        this.layers = layers0;
        // compute layer stack
//         layerStack <- Array.ofSeq (layers0.values())
        this.layerStack = [...layers0.values()]
    }

//     let renderStyleLayers(episodeKey0, layers0:JS.Map<string, StyleLayer>) = // TODO change name renderStyleOverlays??
    renderStyleLayers(episodeKey0:string, layers0:Map<string, StyleLayer>) { // TODO change name renderStyleOverlays??
        // DOM manipulation is done imperatively in this function nothing is driven by observable
        // if episodeKey same current (otherwise style changes and expressed by html rendering not DOM manipulation)
//         if episodeKey = episodeKey0 then
        if (this.episodeKey === episodeKey0) {
            // for key in layers do
//             for key in layers0.keys() do
            for (const key of layers0.keys()) {
                // get the old and new layers for key
//                 let newOverlay = layers0.get(key)
                const newOverlay = layers0.get(key);
//                 let oldOverlay = layers.get(key)
                const oldOverlay = this.layers.get(key);
                // compute differences in both directions using elementlist difference
                // TODO difference is minus here, use .minus instead of .difference?
                // TODO figure out what the bool param is
//                 if isNil(oldOverlay) then
                if (isNil(oldOverlay)) {
//                     renderStylesForElementList(newOverlay.elements, newOverlay.styles, Null, false)
                    renderStylesForElementList(newOverlay.elements, newOverlay.styles, null, false);
//                 else
                } else {
//                     let addedElements = newOverlay.elements.difference(oldOverlay.elements)
                    const addedElements = newOverlay.elements.difference(oldOverlay.elements);
//                     let removedElements = oldOverlay.elements.difference(newOverlay.elements)
                    const removedElements = oldOverlay.elements.difference(newOverlay.elements);
//                     renderStylesForElementList(removedElements, newOverlay.styles, Null, true)
                    renderStylesForElementList(removedElements, newOverlay.styles, null, true);
//                     renderStylesForElementList(addedElements, newOverlay.styles, Null, false)
                    renderStylesForElementList(addedElements, newOverlay.styles, null, false);
                }
            }
        }

//         episodeKey <- episodeKey0
        this.episodeKey = episodeKey0;
//         layers <- layers0
        this.layers = layers0;
        // compute layer stack
//         layerStack <- Array.ofSeq (layers0.values())
        this.layerStack = [...layers0.values()];
    }
//     do autoBindInterface()
}

// let StyleLayersRenderer():IStyleLayersRenderer = !< StyleLayersRenderer0()
