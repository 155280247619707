import React from "react";
import { observer } from "mobx-react";
import { chaatToolModel } from "./app-root";
import { getContentStringFromWordIdMaxChars } from "./masala-lib/content-funcs";

// const CueInsertPointText = styled.div`
//   font-family: ${p => p.theme.sansSerif};
//   width: 1000px;
//   display: inline-block;
//   overflow: hidden;
//   white-space: nowrap;
//   text-overflow: ellipsis;
//   margin-top: 5px;
//   margin-left: 5px;
// `;

export const CueInsertTarget = observer(() => {
  const insertPointId = chaatToolModel.currentCuePointWordId;
  const targetClass = insertPointId ? "cue-insert-point": "";
  const targetText = getContentStringFromWordIdMaxChars(insertPointId, chaatToolModel.words, 150)
  return (
    <div>
      <div className="cue-insert-point-target-text">
        <span className={targetClass}> {targetText} </span>
      </div>
    </div>
  );
});
