import { NavigationTrackGadget } from "./navigation-gadget";
import { Sorted, EmptySorted} from "../../../../sorted/sorted";
import { makeObservable, observable, reaction } from "mobx";

export class AudioRegionSelectionTrackGadget extends NavigationTrackGadget {
  constructor() {
    super();
    this.notifyRegionSelect = 0;
    this.transportState = null;
    makeObservable(this, {
      notifyRegionSelect: observable,
    });
  }

  drawHandle(ctx, index) {
    super.drawHandle(ctx, index);
    this.drawHandleEdges(ctx, index, this.height);
  }

  setTransportState(transportState) {
    if (this.transportState === transportState) {
      return;
    }
    this.transportState = transportState;
  }

  get selectionIntervals() {
    return this.handleIntervals;
  }

  get selectionInterval() {
    if (this.selectionIntervals.length) {
      return this.selectionIntervals.intervalAt(0);
    }
    return null;
  }

  setSelectionInterval(interval) {
    // TODO make simple constructor not needing domain params
    const newSelectionIntervals = new Sorted([interval.starts], [interval.ends]);
    this.setHandleIntervals(newSelectionIntervals);
    this.notifyRegionSelect++;
  }

  clearSelection() {
    // TODO really
    this.setHandleIntervals(EmptySorted);
    this.notifyRegionSelect++;
  }

  onRegionSelect(f) {
    this.disposers.push(
      reaction(
        () => this.notifyRegionSelect,
        () => f(this, this.selectionInterval)
      )
    );
  }

  handleMouseClickAtTime(x, y, time, e) {
    if (!e.getModifierState("Shift")) {
      return;
    }
    const audioPosition = this.transportState.audioPosition;

    let selection = this.selectionInterval;
    if (selection) {
      selection =
        time > selection.starts
          ? { starts: selection.starts, ends: time }
          : { starts: time, ends: selection.ends };
    } else {
      selection =
        time < audioPosition
          ? { starts: time, ends: audioPosition }
          : { starts: audioPosition, ends: time };
    }
    this.setSelectionInterval(selection);
    return true;
  }
}
