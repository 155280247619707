import React from "react";
// import styled from "styled-components";
import { observer } from "mobx-react";
import { transportState, audioSource, audioTransport } from "./app-root";
import * as colors from "./colors.js";
import { expr } from "mobx-utils";
import { msToMinSecString } from "./timestamp-formats.js";
import { audioSourceKeyToUIText } from "./chaat-audio-sources.js";

// const StatusBarContainer = styled.div`
//   font-family: ${p => p.theme.sansSerif};
//   width: 1000px;
//   overflow: hidden;
//   white-space: nowrap;
//   padding: 4px;
//   border-bottom: 3px solid lightgrey;
// `;

const CHEAT_SHEET_URL = 'https://jiveworld.slite.com/api/s/note/MydHm3KRkaeGYHhvN6Pxrg/New-Chaat-Cheat-Sheet';

const StatusBarContainer = ({children}) => {
  return (<div className="status-bar-container">{children}</div>);
}

// const StatusBarElement = styled.div`
//   overflow: hidden;
//   display: inline-block;
//   padding: 0px 40px;
//   margin: 0px;
//   vertical-align: bottom;
// `;

const StatusBarElement = ({children}) => {
  return (<div className="status-bar-element">{children}</div>);
}

const AudioTrackSelect = observer(() => {
  const handleChange = event => {
    audioSource.setCurrentAudioSourceKey(event.target.value);
  };

  const options = audioSource.sourceKeys;

  return (
    <select
      value={audioSource.currentAudioSourceKey}
      onChange={handleChange}
      disabled={transportState.isPlaying}
    >
      {options.map(option => (
        <option key={option} value={option}>{audioSourceKeyToUIText[option]}</option>
      ))}
    </select>
  );
});

export const StatusBar = observer(() => {
  const speedState = transportState.playbackRate.toFixed(1);
  const kerningState = transportState.kerningEnabled ? "ON" : "OFF";
  const progressSeconds = expr(() => {
    // TODO this was to take out this computation for cpu testing
    // if (root.mainTrackWidget && !root.mainTrackWidget.enabled) {
    //   return 0;
    // }
    return Math.floor(transportState.audioPosition / 1000);
  });
  const progressString = msToMinSecString(progressSeconds * 1000);
  const durationString = msToMinSecString(audioTransport.audioDuration);
  return (
    <StatusBarContainer>
      <StatusBarElement> {` Speed: ${speedState} `}</StatusBarElement>
      <StatusBarElement> {` Kerning: ${kerningState} `} </StatusBarElement>
      {/*<StatusBarElement>*/}
      {/*  <a*/}
      {/*    href="#"*/}
      {/*    style={{ color: colors.darkerBlue, textDecoration: "underline" }}*/}
      {/*    onClick={() => openCheatSheetModal()}*/}
      {/*  >*/}
      {/*    {" "}*/}
      {/*    Open Cheat Sheet{" "}*/}
      {/*  </a>*/}
      {/*</StatusBarElement>*/}
      <StatusBarElement>
        <a
          href={CHEAT_SHEET_URL}
          target="_blank"
          style={{ color: colors.darkerBlue, textDecoration: "underline" }}
        >
          Open Cheat Sheet
        </a>
      </StatusBarElement>
      <StatusBarElement>
        {" "}
        {` Progress: ${progressString}/${durationString} `}{" "}
      </StatusBarElement>
      <StatusBarElement>
        <AudioTrackSelect />
      </StatusBarElement>
    </StatusBarContainer>
  );
});

// export default StatusBar;
