// open Fable.Core
// open Fable.Core.JsInterop
// open Fable.Core.DynamicExtensions
// open BasicTypes
// open JSBoilerplate

import { Element, ElementKind } from "../basic-types";


// type PrecedenceFunctions = {
//     precedenceAtLeastFilter: ElementKind -> (IElement -> bool)
//     precedenceProjection: string -> int
//     sortComparator: string -> string -> int
// }

// type IPrecedence =
//     abstract precedence: string []
//     abstract leastPrecedenceLevel: unit -> int
//     abstract keyToPrecedenceLevel: obj
//     abstract pickWith: obj -> obj []
//     abstract sortComparator: string -> string -> int
//     abstract precedenceProjection: string -> int
//     abstract precedenceAtLeastFilter: string -> (IElement -> bool)
//     abstract functions: PrecedenceFunctions


// type Precedence0(precedence0:string []) =
export class Precedence {
    precedence:string [];
    keyToPrecedenceLevel:any;
    constructor(precedence0:string []) {
        this.precedence = precedence0;
//     // TODO change this to strong typed JS.Map that can init from obj?
//     let keyToPrecedenceLevel = obj()
        this.keyToPrecedenceLevel = {};

//     do for index, kind in (Array.indexed precedence) do
        for (const [index, kind] of this.precedence.entries()) {
//         keyToPrecedenceLevel.[kind] <- index
            this.keyToPrecedenceLevel[kind] = index;
        }
    }

//     let pickWith(obj) =
//         [|for p in precedence -> obj.[p]|]

//     let leastPrecedenceLevel() = precedence.Length
    leastPrecedenceLevel() {
        return this.precedence.length;
    }

//     let precedenceProjection(kind):int =
    precedenceProjection(kind:string):number {
//         !< keyToPrecedenceLevel.[kind]
        return this.keyToPrecedenceLevel[kind];
    }

//     let sortComparator kind1 kind2 =
//         precedenceProjection(kind1) - precedenceProjection(kind2)

//     let precedenceAtLeastFilter(kind:ElementKind):IElement -> bool =
    precedenceAtLeastFilter(kind:ElementKind):(el:Element) => boolean {
//         let level:int = !< keyToPrecedenceLevel.[kind]
        const level:number = this.keyToPrecedenceLevel[kind];
//         (fun (el) -> !< keyToPrecedenceLevel.[el.kind] >= level)
        return (el:Element) => this.keyToPrecedenceLevel[el.kind] >= level;
    }

//     let functions() =
    get functions() {
//         {
        return {
//             precedenceProjection = precedenceProjection
            precedenceProjection:(kind) => this.precedenceProjection(kind),
//             sortComparator = sortComparator
//             precedenceAtLeastFilter = precedenceAtLeastFilter
//         }
        };
    }

//     do autoBindInterface()
}

// let Precedence(precedence:string []):IPrecedence = !< Precedence0(precedence)
