// open BasicTypes
// open ElementTypes
// open ElementList

import { Element, ElementId, IdRange } from "../basic-types";
import { EKinds } from "./element-kinds";
import { ElementList } from "./element-list";

// type AdhocWordRange = {
export type AdhocWordRange = {
//     kind: string
    kind: string,
//     id: ElementId
    id: ElementId,
//     range: IdRange
    range: IdRange,
//     wordAddress: int
    wordAddress: number,
//     endWordAddress: int
    endWordAddress: number,
// }
}

// let makeAdhocWordRange(range:IdRange, words:IElementList): IElement =
export function makeAdhocWordRange(range:IdRange, words:ElementList): Element {
//     let wordAddress = words.getIndex(range.starts)
    const wordAddress = words.getIndex(range.starts);
//     let endWordAddress = words.getIndex(range.ends)
    const endWordAddress = words.getIndex(range.ends);
//     !< {
    const result:AdhocWordRange = {
//         kind = ADHOC_WORD_RANGE
        kind:EKinds.ADHOC_WORD_RANGE,
//         id = ADHOC_WORD_RANGE + ":" + range.starts + ":"  + range.ends
        id:EKinds.ADHOC_WORD_RANGE + ":" + range.starts + ":"  + range.ends,
//         wordAddress = wordAddress
        range,
        wordAddress,
//         endWordAddress = endWordAddress
        endWordAddress,
//     }
    };
    // TODO work out the strong typing?
    return <Element><unknown>result;
}

// let isWordRange(element:IElement) =
export function isWordRange(element:Element) {
//     element.kind = ADHOC_WORD_RANGE
    return element.kind === EKinds.ADHOC_WORD_RANGE;
}
