import swal from 'sweetalert2';

// let swalPromptYN(options:obj):JS.Promise<bool> =
export async function swalPromptYN(options:any):Promise<boolean> {

//     promise {
//         let! confirm = swalFire(options)
    const confirm = await swal.fire(options);
//         let yes = !!confirm?value
        //  yes
    return confirm.value;

//     }
}
